import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { catchError } from 'rxjs/operators';
import { of } from 'zen-observable';
import notify from 'devextreme/ui/notify';
import { environment } from '../../../environments/environment';

@Injectable()
export class ParametricasService {
  
  private httpOptions: any;

  constructor(private httpClient: HttpClient) {
    this.httpOptions = {
      headers: new HttpHeaders({'Content-Type': 'application/json'})
    };
  }

  listarZonasGeograficas(pAddTodos: Boolean) : any{
    let url: string = environment.APIAdministracion + "/zonasgeograficas/listarZG?pAddTodos=" + pAddTodos;
    return this.httpClient.get(url)
                   .pipe(catchError(err => 
                                      { 
                                        console.log(err);
                                        notify({
                                          message: "Se presento un error al conectarse con el servidor! ERROR: " + err.message,
                                          position: {
                                              my: "center bottom",
                                              at: "center bottom"
                                          }, width: 500, 
                                        }, "error", 5000);                                        
                                        return of(null); 
                                      }
                                  )); 
  }
  listarTipoAsignacionTicket(pAddTodos: boolean): any {
    let url: string = environment.APIAdministracion + "/parametricas/listarTiposAsignacionTicket?pAddTodos=" + pAddTodos;
    return this.httpClient.get(url)
                   .pipe(catchError(err => 
                                      { 
                                        console.log(err);
                                        notify({
                                          message: "Se presento un error al conectarse con el servidor! ERROR: " + err.message,
                                          position: {
                                              my: "center bottom",
                                              at: "center bottom"
                                          }, width: 500, 
                                        }, "error", 5000);                                        
                                        return of(null); 
                                      }
                                  )); 
  } 
  listarMonedasHabiles() : any{
    let url: string = environment.APIAdministracion + "/monedas/listarMonedasHabiles";
    return this.httpClient.get(url)
                   .pipe(catchError(err => 
                                      { 
                                        console.log(err);
                                        notify({
                                          message: "Se presento un error al conectarse con el servidor! ERROR: " + err.message,
                                          position: {
                                              my: "center bottom",
                                              at: "center bottom"
                                          }, width: 500, 
                                        }, "error", 5000);                                        
                                        return of(null); 
                                      }
                                  )); 
  }   
  listarOrigenTicket(pAddTodos:boolean) : any{
    let url: string = environment.APIAdministracion + "/parametricas/listarOrigenTicket?pAddTodos=" + pAddTodos;
    return this.httpClient.get(url)
                   .pipe(catchError(err => 
                                      { 
                                        console.log(err);
                                        notify({
                                          message: "Se presento un error al conectarse con el servidor! ERROR: " + err.message,
                                          position: {
                                              my: "center bottom",
                                              at: "center bottom"
                                          }, width: 500, 
                                        }, "error", 5000);                                        
                                        return of(null); 
                                      }
                                  )); 
  }   
  listarTiposActivo(pAddTodos:boolean): any {
    let url: string = environment.APIAdministracion + "/parametricas/listarTiposActivo?pAddTodos=" + pAddTodos;
    return this.httpClient.get(url)
                   .pipe(catchError(err => 
                                      { 
                                        console.log(err);
                                        notify({
                                          message: "Se presento un error al conectarse con el servidor! ERROR: " + err.message,
                                          position: {
                                              my: "center bottom",
                                              at: "center bottom"
                                          }, width: 500, 
                                        }, "error", 5000);                                        
                                        return of(null); 
                                      }
                                  )); 
  }   
  listarPeriodicidades() : any{
    let url: string = environment.APIAdministracion + "/parametricas/listarPeriodicidad";
    return this.httpClient.get(url)
                   .pipe(catchError(err => 
                                      { 
                                        console.log(err);
                                        notify({
                                          message: "Se presento un error al conectarse con el servidor! ERROR: " + err.message,
                                          position: {
                                              my: "center bottom",
                                              at: "center bottom"
                                          }, width: 500, 
                                        }, "error", 5000);                                        
                                        return of(null); 
                                      }
                                  )); 
  }  
  listarTipoCliente(pAddTodos: boolean) : any{
    let url: string = environment.APIAdministracion + "/parametricas/listarTipoCliente?pAddTodos=" + pAddTodos;
    return this.httpClient.get(url)
                   .pipe(catchError(err => 
                                      { 
                                        console.log(err);
                                        notify({
                                          message: "Se presento un error al conectarse con el servidor! ERROR: " + err.message,
                                          position: {
                                              my: "center bottom",
                                              at: "center bottom"
                                          }, width: 500 
                                        }, "error", 5000);                                        
                                        return of(null); 
                                      }
                                  )); 
  }   
  listarTipoDocumentoCliente(pAddTodos: boolean) : any{
    let url: string = environment.APIAdministracion + "/parametricas/listarTipoDocumentoCliente?pAddTodos=" + pAddTodos;
    return this.httpClient.get(url)
                   .pipe(catchError(err => 
                                      { 
                                        console.log(err);
                                        notify({
                                          message: "Se presento un error al conectarse con el servidor! ERROR: " + err.message,
                                          position: {my: "center bottom",at: "center bottom"}, width: 500}, "error", 5000);                                        
                                        return of(null); 
                                      }
                                  )); 
  }  
  listarUbigeos() : any {
    let url: string = environment.APIAdministracion + "/parametricas/listarUbigeos";
    return this.httpClient.get(url)
               .pipe(catchError(err => 
                   { 
                     console.log(err);
                     notify({
                              message: "Se presento un error al conectarse con el servidor! ERROR: " + err.message,
                              position: {my: "center bottom",at: "center bottom"}, width: 500}, "error", 5000);                                        
                              return of(null); 
                            }
                     )); 
  } 
  leerLocalById(pIdLocal: string): any {
    let url: string = environment.APIAdministracion + "/locales/leerLocalById?pIdLocal=" + pIdLocal;
    return this.httpClient.get(url)
                   .pipe(catchError(err => 
                                      { 
                                        console.log(err);
                                        notify({
                                          message: "Se presento un error al conectarse con el servidor! ERROR: " + err.message,
                                          position: {my: "center bottom",at: "center bottom"}, width: 500}, "error", 5000);                                        
                                        return of(null); 
                                      }
                                  )); 
  }   
  leerZonaById(pIdZona: string): any {
    let url: string = environment.APIAdministracion + "/zonas/leerZonaById?pIdZona=" + pIdZona;
    return this.httpClient.get(url)
                   .pipe(catchError(err => 
                                      { 
                                        console.log(err);
                                        notify({
                                          message: "Se presento un error al conectarse con el servidor! ERROR: " + err.message,
                                          position: {my: "center bottom",at: "center bottom"}, width: 500}, "error", 5000);                                        
                                        return of(null); 
                                      }
                                  )); 
  }   
  leerSubZonaById(pIdSubZona: string): any {
    let url: string = environment.APIAdministracion + "/subZonas/leerSubZonaById?pIdSubZona=" + pIdSubZona;
    return this.httpClient.get(url)
                   .pipe(catchError(err => 
                                      { 
                                        console.log(err);
                                        notify({
                                          message: "Se presento un error al conectarse con el servidor! ERROR: " + err.message,
                                          position: {my: "center bottom",at: "center bottom"}, width: 500}, "error", 5000);                                        
                                        return of(null); 
                                      }
                                  )); 
  }    
  listarTiposSubZona(pAddTodos: boolean): any {
    let url: string = environment.APIAdministracion + "/parametricas/listarTiposSubZona?pAddTodos=" + pAddTodos;
    return this.httpClient.get(url)
                   .pipe(catchError(err => 
                                      { 
                                        console.log(err);
                                        notify({
                                          message: "Se presento un error al conectarse con el servidor! ERROR: " + err.message,
                                          position: {
                                              my: "center bottom",
                                              at: "center bottom"
                                          }, width: 500 
                                        }, "error", 5000);                                        
                                        return of(null); 
                                      }
                                  )); 
  } 
  listarTiposLocal(pAddTodos: boolean) : any{
    let url: string = environment.APIAdministracion + "/parametricas/listarTiposLocal?pAddTodos=" + pAddTodos;
    return this.httpClient.get(url)
                   .pipe(catchError(err => 
                                      { 
                                        console.log(err);
                                        notify({
                                          message: "Se presento un error al conectarse con el servidor! ERROR: " + err.message,
                                          position: {
                                              my: "center bottom",
                                              at: "center bottom"
                                          }, width: 500 
                                        }, "error", 5000);                                        
                                        return of(null); 
                                      }
                                  )); 
  }  
}