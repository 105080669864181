import { Component, ViewChild } from '@angular/core';
import { HttpClient, HttpParams } from "@angular/common/http";
import * as AspNetData from "devextreme-aspnet-data-nojquery";
import CustomStore from 'devextreme/data/custom_store';
import config from 'devextreme/core/config';
import notify from 'devextreme/ui/notify';
import { DxDataGridComponent, DxFormComponent, DxFileUploaderComponent } from 'devextreme-angular';
import { AuthService } from 'src/app/shared/services';
import { environment } from '../../../../environments/environment';
import { TicketService } from '../../../shared/services/ticket.service';
import { ImagenTicket } from '../../../models/ImagenTicket';
import { Atencion } from 'src/app/models/Atencion';
import { Ticket } from 'src/app/models/Ticket';
import { AtencionService } from 'src/app/shared/services/atencion.service';
import $ from "jquery";

config({
  floatingActionButtonConfig: {
      icon: 'rowfield',
      position: {
          of: '#gridAtenciones',
          my: 'right bottom',
          at: 'right bottom',
          offset: '-16 -16'
      }
  }
}); 

@Component({
  templateUrl: 'reg-eje-bandeja.component.html',
  styleUrls: ['reg-eje-bandeja.component.css']
})

export class RegistrarEjecucionBandejaComponent {
  @ViewChild('gridAtenciones', { static: false }) gridAtenciones: DxDataGridComponent;
  @ViewChild('formRegistrarEjecucion', { static: true }) formRegistrarEjecucion: DxFormComponent;
  @ViewChild('formAgregarActa', { static: true }) formAgregarActa: DxFormComponent;
  @ViewChild('fileUploader', { static: true }) fileUploader: DxFileUploaderComponent;
  @ViewChild('fuActa', { static: true }) fuActa: DxFileUploaderComponent;
 
  dsAtencion: any;
  dsImagenesTck: ImagenTicket[];
  imagenes: any;
  ticket = new Ticket();
  selectedRowIndex = -1;
  idTicketSelected = -1;
  idAtencionSelected = -1;
  popupREVisible = false;
  popupImagenesVisible = false;
  tituloRE = "";
  tituloVerImagenes = "";
  ticketsDataMap: object = {};
  urlDetalleTicket = "";
  dataTicketSelected: any;
  imagenInicial = 0;
  popupConfirmarVisible = false;
  fechaMin: Date = new Date();
  mostrarFileUploader = true;
  mostrarGridEvidencia = false;
  urlUpLoad = "";
  fileSeleccionados: any[] = [];
  popupAAVisible = false;
  tituloAA = "";
  atencion = new Atencion();
  fsActas: any[] = [];
  dsActas:any;
  popupActasVisible = false;
  tituloVerActas = "";
  urlSrvArchivos = "";

  botonRegEjeDisabled = false; 
  botonCerrarEjeDisabled = false; 
  botonAgrActaDisabled = false; 
  loadingVisible = false; 

  constructor(private httpClient: HttpClient, private authService: AuthService, private tckService: TicketService,
                                                                                private ateService: AtencionService) {
    this.urlDetalleTicket = environment.APITicketCore + "/consultas/listaTicketByAtencion";
    this.urlUpLoad = environment.APICargaArchivo + environment.urlUpLoad;
    this.repoblar();
    this.listarActas = this.listarActas.bind(this);
    this.adicionarActa = this.adicionarActa.bind(this);
    this.urlSrvArchivos = environment.EvidenciaMINTTO + "/";
  }
  listarActas() {
    if (this.idAtencionSelected <=0) {
      notify({
        message: "Debe seleccionar un registro para continuar",
        position: {
            my: "center center",
            at: "center center"
        }, width: 500, 
      }, "error", 2000);          
    } else {
      let loadParams = { pIdAtencion: this.idAtencionSelected}; 
      this.dsActas = new CustomStore({
        key: "idActaAtencion",
        load: () => this.sendRequest(environment.APITicketCore + "/consultas/listarActasAprobacion", "LOADACTAS", loadParams),
        remove: (key) => this.sendRequest(environment.APITicketCore + "/maestros/eliminarActasAprobacion", "REMOVEACTA", {pIdActaAtencion: key})
      });
      this.popupActasVisible = true;
      this.tituloVerActas = "Lista de Actas registradas Atención: " + this.idAtencionSelected;
    }
  }
  adicionarActa() {
    if (this.idAtencionSelected <=0) {
      notify({
        message: "Debe seleccionar un registro para continuar",
        position: {
            my: "center center",
            at: "center center"
        }, width: 500, 
      }, "error", 2000);          
    } else {
      this.popupAAVisible = true;
      this.tituloAA = "Agregar Acta de Aprobación: " + this.idAtencionSelected;
      this.formAgregarActa.instance.resetValues();
      this.fuActa.instance.reset();

      //COLOCO EL FOCO EN EL PRIMER CAMPO
      let that = this;
      setTimeout(function() {
          that.formAgregarActa.instance.getEditor("Comentarios").focus();
      }, 500); 
    }
  }
  registrarActaClick() {

    if (this.formAgregarActa.instance.validate().isValid) {
      if (this.fsActas.length <= 0)  {
        notify({
            message: "Debe registrar el archivo del acta",
            position: {
                my: "center center",
                at: "center center"
            }, width: 500, 
        }, "error", 2000);  
        return;
      }

      this.botonAgrActaDisabled = true; 
      this.loadingVisible = true; 

      this.atencion = this.formAgregarActa.instance.option("formData");
      this.atencion.IdUsuarioCreacion = this.authService.usuarioLogueado.idUsuario;
      this.atencion.NombreArchivo = this.fsActas[0].name;
      this.atencion.IdAtencion = this.idAtencionSelected;

      this.ateService.registrarActa(this.atencion).subscribe(data => {
            if (data.codigo == "OK") {
                //SUBIR LOS ARCHIVOS AL SERVIDOR
                if (this.fsActas.length > 0) {
                    this.subirArchivosAA(data.rutaEvidencia);
                } else  {
                    this.botonAgrActaDisabled = false; 
                    this.loadingVisible = false; 
            
                    notify({
                        message: "Grabación Correcta!" ,
                        position: {
                        my: "center middle",
                        at: "center middle"
                        }, width: 300, 
                        }, "success", 1500);  
                    this.ocultarPopupAAClick();                                            
                } 
            } else {

                this.botonAgrActaDisabled = false; 
                this.loadingVisible = false; 

                notify({
                    message: data.mensaje ,
                    position: {
                        my: "center middle",
                        at: "center middle"
                    }, width: 300, 
                }, "error", 5000);
            }    
          });
    }    
  }
  ocultarPopupAAClick() {
    this.popupAAVisible = false;
  }
  screen(width) {
    return ( width < 700 ) ? 'sm' : 'lg';
  }
  repoblar() {
    let loadParams = { IdProveedor: this.authService.usuarioLogueado.idProveedor}; 
    this.dsAtencion = new CustomStore({
                    key: "idAtencion",
                    load: () => this.sendRequest(environment.APITicketCore + "/consultas/listaAtencionesEjecucion", "LOAD", loadParams)
                  });
  }
  sendRequest(url: string, method: string, data: any = {}): any {

    let result;

    switch(method) {
        case "LOAD":
            url = url + "?pIdProveedor=" + data.IdProveedor; 
            result = this.httpClient.get(url);
            break;
        case "LOADACTAS":
              url = url + "?pIdAtencion=" + data.pIdAtencion; 
              console.log(url);
              result = this.httpClient.get(url);
              break;            
        case "REMOVEACTA":
            url = url + "?pIdActaAtencion=" + data.pIdActaAtencion; 
            result = this.httpClient.delete(url);
            break;
    }

    return result
        .toPromise()
        .then((data: any) => {
            if (method === "REMOVEACTA") { 
              if (data.codigo != 'OK') {
                  notify({
                    message: data.mensaje,
                    position: {
                        my: "center center",
                        at: "center center"
                    }, width: 500, 
                  }, "error", 2000);
              }
            } 
            return data;
        })
        .catch(e => {
            throw e && e.error && e.error.Message;
        });
  }
  obtenerTickets(idAtencion: number) {
    return this.ticketsDataMap[idAtencion] = this.ticketsDataMap[idAtencion] || {
      store: AspNetData.createStore({
          key: "idTicket",
          loadParams: { pIdAtencion: idAtencion },
          loadUrl: this.urlDetalleTicket
      })
    };
  }
  selectedChanged(e) {
    this.selectedRowIndex = e.component.getRowIndexByKey(e.selectedRowKeys[0]);
    this.idAtencionSelected = e.selectedRowKeys[0];
  }
  clickTicket(evt) {
    this.idTicketSelected = evt.data.idTicket;
    this.dataTicketSelected = evt.data;
  }
  expandedClick(){
    this.idTicketSelected = -1;
  }
  verEvidencia() {
    this.imagenInicial = 0;
    let idTicketAtencion = this.dataTicketSelected.idTicketAtencion;
    this.tituloVerImagenes = "Imagenes Evidencia Solución Ticket: " + this.idTicketSelected;
    this.tckService.leerTicketAtencionById(idTicketAtencion).subscribe(data => {
          let imagenes: ImagenTicket[] = [];
          data.data.imagenesAtencion.forEach(element => {
              let imagen = new ImagenTicket();
              imagen.PathURLImagen = environment.EvidenciaMINTTO + "/" + element.pathURLImagen;
              imagen.Nombre = element.nombre;
              imagenes.push(imagen)
          });
          this.dsImagenesTck = imagenes;
          if (this.dsImagenesTck.length > 0) {
            this.popupImagenesVisible = true; 
          } else {
            notify({
              message: "No existe evidencia para visualizar!",
              position: {
                  my: "center center",
                  at: "center center"
              }, width: 500, 
            }, "error", 2000); 
          }          
    });
  }
  cerrarEjecucion() {
    this.popupConfirmarVisible = true;
  }
  ocultarPopupConfirmar() {
    this.popupConfirmarVisible = false;
  }
  confirmarOK() {
    let atencion = new Atencion();
    atencion.IdUsuarioCreacion = this.authService.usuarioLogueado.idUsuario;
    atencion.IdAtencion = this.idAtencionSelected;
    this.botonCerrarEjeDisabled = true; 
    this.loadingVisible = true;     
    this.ateService.cerrarEjecucion(atencion).subscribe(data => {
      if (data.codigo == "OK") {
          notify({
            message: "Proceso Correcto!" ,
            position: {
            my: "center middle",
            at: "center middle"
            }, width: 300, 
          }, "success", 1500);  
          this.repoblar();
          this.ocultarPopupConfirmar();    
          this.botonCerrarEjeDisabled = false; 
          this.loadingVisible = false;                                                       
      } else {
          this.botonCerrarEjeDisabled = false; 
          this.loadingVisible = false;             
          notify({
              message: data.mensaje ,
              position: {
                  my: "center middle",
                  at: "center middle"
              }, width: 300, 
          }, "error", 5000);
      }    
    }); 
  }  
  ingresarEjecucion(){
    if (this.dataTicketSelected.ticketAprobado) {
        notify({
            message: "Ticket ya aprobado!" ,
            position: {
            my: "center middle",
            at: "center middle"
          }, width: 300, 
        }, "success", 1500);   
      return;
    }
    this.popupREVisible = true;
    this.tituloRE = "Registrar Ejecución del Ticket: " + this.dataTicketSelected.idTicket;

    this.formRegistrarEjecucion.instance.resetValues();
    this.fileUploader.instance.reset();
    this.imagenes = []; 
    this.tckService.leerTicketAtencionById(this.dataTicketSelected.idTicketAtencion).subscribe(data => {
          let imagenesBD: ImagenTicket[] = [];
          data.data.imagenesAtencion.forEach(element => {
              let imagen = new ImagenTicket();
              imagen.PathURLImagen = environment.EvidenciaMINTTO + element.pathURLImagen;
              imagen.Nombre = element.nombre;
              imagenesBD.push(imagen)
          });
          this.imagenes = imagenesBD;
          if (this.imagenes.length > 0) {
            this.mostrarGridEvidencia = true;
          } else {
            this.mostrarGridEvidencia = false;
          }
          if (this.imagenes.length >=3) {
            this.mostrarFileUploader = false;
          } else {
            this.mostrarFileUploader = true;
          }
    });    
    
    this.ticket = new Ticket();

    this.ticket.IdTicket = this.dataTicketSelected.idTicket;
    this.ticket.IdProveedorAtencion = this.dataTicketSelected.idProveedorAtencion;
    this.ticket.IdTicketAtencion = this.dataTicketSelected.idTicketAtencion; 

    this.ticket.NumeroTicketCliente = this.dataTicketSelected.numeroTicketCliente; 
    this.ticket.DescripcionPrioridad = this.dataTicketSelected.descripcionPrioridad; 
    this.ticket.DescripcionLocal = this.dataTicketSelected.descripcionLocal; 
    this.ticket.DescripcionZona = this.dataTicketSelected.descripcionZona; 
    this.ticket.DescripcionSubZona = this.dataTicketSelected.descripcionSubZona; 
    this.ticket.DescripcionEspecialidad = this.dataTicketSelected.descripcionEspecialidad; 
    this.ticket.Comentarios = this.dataTicketSelected.comentarios; 
    this.ticket.FechaInicio = this.dataTicketSelected.fechaInicio; 
    this.ticket.FechaTermino = this.dataTicketSelected.fechaTermino; 

    if (this.dataTicketSelected.fechaInicioReal != null) {
      this.ticket.FechaInicioReal = this.dataTicketSelected.fechaInicioReal; 
      this.ticket.FechaTerminoReal = this.dataTicketSelected.fechaTerminoReal; 
      this.ticket.ComentariosEjecucion = this.dataTicketSelected.comentariosEjecucion; 
    } else {
      this.ticket.FechaInicioReal = this.dataTicketSelected.fechaInicio; 
      this.ticket.FechaTerminoReal = this.dataTicketSelected.fechaTermino; 
    } 

    this.formRegistrarEjecucion.instance.option("formData", this.ticket);

    //COLOCO EL FOCO EN EL PRIMER CAMPO
    let that = this;
    setTimeout(function() {
        that.formRegistrarEjecucion.instance.getEditor("FechaInicioReal").focus();
    }, 500); 
  }  
  ocultarPopupREClick() {
    this.popupREVisible = false;
  }  
  registrarEjecucionClick() {
    if (this.formRegistrarEjecucion.instance.validate().isValid) {
        if (this.fileSeleccionados.length <= 0 && this.imagenes.length <= 0) {
          notify({
              message: "Debe registrar por lo menos un archivo de evidencia",
              position: {
                  my: "center center",
                  at: "center center"
              }, width: 500, 
          }, "error", 2000);  
          return;
        }

        //MANEJO DE LAS IMAGENES DE EVIDENCIA
        let imagenesTotal: ImagenTicket[] = [] ;
        this.imagenes.forEach(function (value) {
            imagenesTotal.push({'PathURLImagen': value.Nombre, 'Nombre' : value.Nombre, 'Tipo' : '' });
        }); 

        this.fileSeleccionados.forEach(function (value) {
            imagenesTotal.push({'PathURLImagen': value.name, 'Nombre' : value.name, 'Tipo' : '' });
        }); 

        this.ticket.ImagenesTicket = imagenesTotal;        

        //INFORMACION A ACTUALIZAR
        this.ticket = this.formRegistrarEjecucion.instance.option("formData");
        this.ticket.IdUsuarioCreacion = this.authService.usuarioLogueado.idUsuario;

        if (this.ticket.FechaInicioReal > this.ticket.FechaTerminoReal) {
            notify({
              message: "La fecha inicial no puede ser menor a la fecha final!" ,
              position: {
              my: "center middle",
              at: "center middle"
              }, width: 300, 
              }, "success", 1500); 
              return;
        }

        this.botonRegEjeDisabled = true; 
        this.loadingVisible = true; 

        this.ateService.registrarEjecucion(this.ticket).subscribe(data => {
                                          if (data.codigo == "OK") {
                                              //SUBIR LOS ARCHIVOS AL SERVIDOR
                                              if (this.fileSeleccionados.length > 0) {
                                                  this.subirArchivos(data.rutaEvidencia);
                                              } else  {
                                                  notify({
                                                      message: "Grabación Correcta!" ,
                                                      position: {
                                                      my: "center middle",
                                                      at: "center middle"
                                                      }, width: 300, 
                                                      }, "success", 1500);  
                                                  this.repoblar();
                                                  this.ocultarPopupREClick();                                            
                                                  this.idTicketSelected = -1;
                                                  this.botonRegEjeDisabled = false; 
                                                  this.loadingVisible = false; 
                                              } 
                                          } else {
                                              this.botonRegEjeDisabled = false; 
                                              this.loadingVisible = false; 
                                              notify({
                                                  message: data.mensaje ,
                                                  position: {
                                                      my: "center middle",
                                                      at: "center middle"
                                                  }, width: 300, 
                                              }, "error", 5000);
                                          }    
                        });
    }
  }   
  asignarValoresUpLoadFile (e) {
    $(".dx-fileuploader-upload-button").css({"visibility":"hidden"});
  }  
  subirArchivosAA (ruta: string) {
    let headers = {ruta: ruta};
    this.fuActa.instance.option("uploadHeaders", headers);  
    (<any> this.fuActa.instance)._uploadFiles(); 
  }   
  subirArchivos (ruta: string) {
    let headers = {ruta: ruta};
    this.fileUploader.instance.option("uploadHeaders", headers);  
    (<any> this.fileUploader.instance)._uploadFiles(); 
  }  
  cargaFinalizadaAA() {
    notify({
      message: "Grabación Correcta!" ,
      position: {
      my: "center center",
      at: "center center"
      }, width: 300, 
      }, "success", 1500);  
    this.ocultarPopupAAClick();
    this.botonAgrActaDisabled = false; 
    this.loadingVisible = false; 
  }  
  cargaFinalizada () {
    notify({
        message: "Grabación Correcta!" ,
        position: {
        my: "center center",
        at: "center center"
        }, width: 300, 
        }, "success", 1500);  
      this.repoblar();
      this.ocultarPopupREClick();
      this.idTicketSelected = -1;
      this.botonRegEjeDisabled = false; 
      this.loadingVisible = false;       
  }
  eliminoImagen(){
    if (this.imagenes.length <=0 ) {
      this.mostrarGridEvidencia = false;
    }
    this.mostrarFileUploader = true;
  }
}