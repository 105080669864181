import { Ticket } from './Ticket';
import { Proveedor } from './Proveedor';

export class Atencion {
    IdAtencion: number; 
    IdCliente: number; 
    DescripcionAtencion: string;
    IdTipoAsignacion: number;
    Comentarios: string;
    IdUsuarioAsignado: number;
    IdUsuarioCreacion: number;
    CodigoTipoAsignacion: string;
    Proveedores: Proveedor[];
    Tickets: Ticket[];
    PlazoEntregaCotizacion: number;
    IdProveedorAsignado: number;
    FechaRegistro: Date;
    PlazoEntregaAdicional:number;
    NombreArchivo: string;
    ComentariosCancelacion: string;
    FechaInicio: Date; 
    FechaTermino: Date;
}