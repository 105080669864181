import { Component, ViewChild } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import CustomStore from 'devextreme/data/custom_store';
import * as AspNetData from "devextreme-aspnet-data-nojquery";
import config from 'devextreme/core/config';
import notify from 'devextreme/ui/notify';
import { DxDataGridComponent, DxFormComponent } from 'devextreme-angular';
import { AuthService } from 'src/app/shared/services';
import { environment } from '../../../../environments/environment';
import { TicketService } from '../../../shared/services/ticket.service';
import { ImagenTicket } from '../../../models/ImagenTicket';
import { Atencion } from 'src/app/models/Atencion';
import { AtencionService } from 'src/app/shared/services/atencion.service';

config({
  floatingActionButtonConfig: {
      icon: 'rowfield',
      position: {
          of: '#gridAtenciones',
          my: 'right bottom',
          at: 'right bottom',
          offset: '-16 -16'
      }
  }
}); 

@Component({
  templateUrl: 'gestionar-atencion-bandeja.component.html',
  styleUrls: ['gestionar-atencion-bandeja.component.css']
})

export class GestionarAtencionesBandejaComponent {
  @ViewChild(DxDataGridComponent, { static: false }) gridAtenciones: DxDataGridComponent;
  @ViewChild('formCancelarAtencion', { static: false }) formCancelarAtencion: DxFormComponent;  

  ticketsDataMap: object = {};
  dsAtencion: any;
  dsImagenesTck: ImagenTicket[];
  selectedRowIndex = -1;
  idAtencionSelected = -1;
  idTicketSelected = -1;
  popupVisible = false;
  popupImagenesVisible = false;
  tituloRegistro = "";
  tituloVerImagenes = "";
  imagenInicial = 0;
  urlDetalleTicket = "";
  tituloCancelarAtencion = "";
  popupCancelarVisible = false;
  atencion = new Atencion();
  dataAtencionSelected: any;
  botonCancelacionDisabled = false;
  loadingVisible = false;

  constructor(private httpClient: HttpClient, private authService: AuthService, private tckService: TicketService, private ateService: AtencionService) {
    this.urlDetalleTicket = environment.APITicketCore + "/consultas/listaTicketByAtencion";
    this.repoblar();
  }

  repoblar() {
    this.idTicketSelected = -1;
    this.idAtencionSelected = -1;
    this.selectedRowIndex = -1;
    this.dataAtencionSelected = null;   
    
    let idUsuario = this.authService.usuarioLogueado.idUsuario;
    let idCliente = this.authService.usuarioLogueado.idClienteSeleccionado;
    let loadParams = { idCliente: idCliente,  idUsuario: idUsuario}
    this.dsAtencion = new CustomStore({
                    key: "idAtencion",
                    load: () => this.sendRequest(environment.APITicketCore + "/consultas/listaMisAtenciones", "LOAD", loadParams)
                  });
    if (this.gridAtenciones) {
      this.gridAtenciones.instance.clearSelection();
    }                  
  }

  sendRequest(url: string, method: string, data: any = {}): any {

    let result;

    switch(method) {
        case "LOAD":
            url = url + "?idCliente=" + data.idCliente + "&idUsuario=" + data.idUsuario; 
            result = this.httpClient.get(url);
            break;
        case "REMOVE":
            url = url + "?pIdTicket=" + data.pIdTicket; 
            result = this.httpClient.delete(url);
            break;
    }

    return result
        .toPromise()
        .then((data: any) => {
            //console.log(data);
            if (method === "REMOVE") { 
              if (data.codigo != 'OK') {
                  notify({
                    message: data.mensaje,
                    position: {
                        my: "center center",
                        at: "center center"
                    }, width: 500, 
                  }, "error", 2000);
              }
            } 
            return data;
        })
        .catch(e => {
            throw e && e.error && e.error.Message;
        });
  }

  obtenerTickets(idAtencion: number) : any {
    return this.ticketsDataMap[idAtencion] = this.ticketsDataMap[idAtencion] || {
      store: AspNetData.createStore({
          key: "idTicket",
          loadParams: { pIdAtencion: idAtencion },
          loadUrl: this.urlDetalleTicket
      })
    };
  }
  cancelarAtencion() {
    if (this.dataAtencionSelected.sePuedeCancelar) {
      this.formCancelarAtencion.instance.resetValues();
      this.tituloCancelarAtencion = "Cancelar Atención: " + this.idAtencionSelected;
      
      //COLOCO EL FOCO EN EL PRIMER CAMPO
      let that = this;
      setTimeout(function() {
          that.formCancelarAtencion.instance.getEditor("ComentariosCancelacion").focus();
      }, 500); 
      
      this.popupCancelarVisible = true;
    } else {
        notify({
          message: "La atención no puede cancelarse, en el estado actual!" ,
          position: {
          my: "center center",
          at: "center center"
        }, width: 300, }, "error", 1500);  
    }
  }
  actualizarCancelacion() {
    if (this.formCancelarAtencion.instance.validate().isValid) {
       this.botonCancelacionDisabled = true;
       this.loadingVisible = true;
       this.atencion = this.formCancelarAtencion.instance.option("formData");
       this.atencion.IdUsuarioCreacion = this.authService.usuarioLogueado.idUsuario;
       this.atencion.IdAtencion = this.idAtencionSelected;
       this.ateService.registrarCancelarAtencion(this.atencion).toPromise().then(data => {
                              if (data.codigo == "OK") {
                                  this.botonCancelacionDisabled = false;
                                  this.loadingVisible = false;
                                  notify({
                                      message: "Grabación Correcta!" ,
                                      position: {
                                      my: "center center",
                                      at: "center center"
                                    }, width: 300, }, "success", 1500);  
                                    this.repoblar();
                                    this.ocultarCancelarClick();                                            
                              } else {
                                  this.botonCancelacionDisabled = false;
                                  this.loadingVisible = false;
                                  notify({
                                      message: data.mensaje ,
                                      position: {
                                          my: "center center",
                                          at: "center center"
                                      }, width: 300, 
                                  }, "error", 5000);
                              }    
                          });
    } 
  }
  ocultarCancelarClick(){
    this.popupCancelarVisible = false;
  }
  verFlujoAtencion () {
  }
  recargarGrilla() {
    this.idTicketSelected = -1;
    this.gridAtenciones.instance.refresh();
  }
  selectedChanged(e) {
    this.selectedRowIndex = e.component.getRowIndexByKey(e.selectedRowKeys[0]);
    this.idAtencionSelected = e.selectedRowKeys[0];
    this.dataAtencionSelected = e.selectedRowsData[0];
  }
  clickTicket(evt) {
    this.idTicketSelected = evt.data.idTicket;
  }
  verImagenesClick() {
    this.imagenInicial = 0;
    let idTicket = this.idTicketSelected;
    this.tituloVerImagenes = "Imagenes Ticket: " + idTicket;
    this.tckService.leerTicketById(idTicket).subscribe(data => {
          let imagenes: ImagenTicket[] = [];
          data.data.imagenesTicket.forEach(element => {
              let imagen = new ImagenTicket();
              imagen.PathURLImagen = environment.EvidenciaMINTTO + "/" + element.pathURLImagen;
              imagen.Nombre = element.nombre;
              imagenes.push(imagen)
          });
          this.dsImagenesTck = imagenes;
    });
    this.popupImagenesVisible = true;
  }
}