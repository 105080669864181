import { Component, ViewChild } from '@angular/core';
import { HttpClient, HttpParams } from "@angular/common/http";
import CustomStore from 'devextreme/data/custom_store';
import config from 'devextreme/core/config';
import notify from 'devextreme/ui/notify';
import { DxDataGridComponent, DxFormComponent, DxFileUploaderComponent } from 'devextreme-angular';
import { AuthService } from 'src/app/shared/services';
import { environment } from '../../../../environments/environment';
import { TicketService } from '../../../shared/services/ticket.service';
import { ImagenTicket } from '../../../models/ImagenTicket';
import * as AspNetData from "devextreme-aspnet-data-nojquery";
import { Proveedor } from 'src/app/models/Proveedor';
import { ParametricasService } from 'src/app/shared/services/parametricas.service';
import ArrayStore from 'devextreme/data/array_store';
import { AtencionService } from 'src/app/shared/services/atencion.service';
import $ from "jquery";

config({
  floatingActionButtonConfig: {
      icon: 'rowfield',
      position: {
          of: '#gridAtenciones',
          my: 'right bottom',
          at: 'right bottom',
          offset: '-16 -16'
      }
  }
}); 

@Component({
  templateUrl: 'reg-coti-prov-bandeja.component.html',
  styleUrls: ['reg-coti-prov-bandeja.component.css']
})

export class RegCotiProvBandejaComponent {
  @ViewChild('gridAtenciones', { static: false }) gridAtenciones: DxDataGridComponent;
  @ViewChild('formCotizacion', { static: true }) formCotizacion: DxFormComponent; 
  @ViewChild(DxFileUploaderComponent, { static: false }) fileUploader: DxFileUploaderComponent;
  
  dsAtencion: any;
  dataAtencionSelected: any;
  dsImagenesTck: ImagenTicket[];
  fileSeleccionados: any[] = [];
  cotProveedor = new Proveedor();
  monedas: any;
  selectedRowIndex = -1;
  idTicketSelected = -1;
  idAtencionSelected = -1;
  idProveedorAtencionSelected = -1;
  popupCotizacionVisible = false;
  popupImagenesVisible = false;
  tituloRegistro = "";
  tituloVerImagenes = "";
  ticketsDataMap: object = {};
  urlDetalleTicket = "";
  imagenInicial = 0;
  mostrarFileUploader = true;
  urlUpLoad: string;
  tituloCotizacion = "";
  popupConfirmarVisible = false;
  popConfEliminarVisible = false;
  urlSrvArchivos = "";

  botonGrabarDisabled = false;
  botonEnviarDisabled = false;
  botonEliminarCotDisabled = false;
  loadingVisible = false;

  constructor(private httpClient: HttpClient, private authService: AuthService, private paramService: ParametricasService, 
                                              private tckService: TicketService, private ateService: AtencionService) {
    this.urlDetalleTicket = environment.APITicketCore + "/consultas/listaTicketByAtencion";
    this.urlUpLoad = environment.APICargaArchivo + environment.urlUpLoad;
    this.urlSrvArchivos = environment.EvidenciaMINTTO + "/";

    //CARGO LA LISTA 
    this.repoblar();                                            

    //CARGO LOS COMBOS 
    paramService.listarMonedasHabiles().toPromise().then(data => {
                  this.monedas = new ArrayStore({
                                              data: data,
                                              key: "idMoneda"
                                          });     
    })
  }
  repoblar() {
    this.idTicketSelected = -1;
    this.idAtencionSelected = -1;
    this.selectedRowIndex = -1;
    this.dataAtencionSelected = null;   
    this.idProveedorAtencionSelected = -1;
    let loadParams = { IdProveedor: this.authService.usuarioLogueado.idProveedor}; 
    this.dsAtencion = new CustomStore({
                    key: "idAtencion",
                    load: () => this.sendRequest(environment.APITicketCore + "/consultas/listaAtencionCotizar", "LOAD", loadParams)
                  }); 
    if (this.gridAtenciones) {
       this.gridAtenciones.instance.clearSelection();
    }
  }
  sendRequest(url: string, method: string, data: any = {}): any {

    let result;

    switch(method) {
        case "LOAD":
            url = url + "?pIdProveedor=" + data.IdProveedor; 
            result = this.httpClient.get(url);
            break;
        case "REMOVE":
            url = url + "?pIdTicket=" + data.pIdTicket; 
            result = this.httpClient.delete(url);
            break;
    }

    return result
        .toPromise()
        .then((data: any) => {
            if (method === "REMOVE") { 
              if (data.codigo != 'OK') {
                  notify({
                    message: data.mensaje,
                    position: {
                        my: "center center",
                        at: "center center"
                    }, width: 500, 
                  }, "error", 2000);
              }
            } 
            return data;
        })
        .catch(e => {
            throw e && e.error && e.error.Message;
        });
  }
  obtenerTickets(idAtencion: number) : any {
    return this.ticketsDataMap[idAtencion] = this.ticketsDataMap[idAtencion] || {
      store: AspNetData.createStore({
          key: "idTicket",
          loadParams: { pIdAtencion: idAtencion },
          loadUrl: this.urlDetalleTicket
      })
    };
  }
  selectedChanged(e) {
    if (e.selectedRowKeys.length > 0) {
      this.selectedRowIndex = e.component.getRowIndexByKey(e.selectedRowKeys[0]);
      this.idAtencionSelected = e.selectedRowKeys[0];
      this.dataAtencionSelected = e.selectedRowsData[0];
      this.idProveedorAtencionSelected = e.selectedRowsData[0].idProveedorAtencion;
    }
  }
  clickTicket(evt) {
    this.idTicketSelected = evt.data.idTicket;
  }
  expandedClick(){
    this.idTicketSelected = -1;
  }
  verImagenesClick() {
    this.imagenInicial = 0;
    let idTicket = this.idTicketSelected;
    this.tituloVerImagenes = "Imagenes Ticket: " + idTicket;
    this.tckService.leerTicketById(idTicket).subscribe(data => {
          let imagenes: ImagenTicket[] = [];
          data.data.imagenesTicket.forEach(element => {
              let imagen = new ImagenTicket();
              imagen.PathURLImagen = environment.EvidenciaMINTTO + "/" + element.pathURLImagen;
              imagen.Nombre = element.nombre;
              imagenes.push(imagen)
          });
          this.dsImagenesTck = imagenes;
    });
    this.popupImagenesVisible = true; 
  }
  modificarCotizacion(){
    this.popupCotizacionVisible = true;
    this.tituloCotizacion = "Registrar/Modificar Cotización: " + this.idAtencionSelected;
    if (!this.dataAtencionSelected.cotizacionEnviada) {
      this.formCotizacion.instance.resetValues();
      this.fileUploader.instance.reset();
      this.botonGrabarDisabled = false;
    } else {
      this.formCotizacion.instance.option('disabled', true);
      this.fileUploader.instance.option('visible', false);
      this.botonGrabarDisabled = true;
    }

    this.cotProveedor = new Proveedor();
    if (this.dataAtencionSelected.descripcionMoneda != null && this.dataAtencionSelected.descripcionMoneda != "") {
      this.cotProveedor.PrecioTotal = this.dataAtencionSelected.precioTotal.toString();
      this.cotProveedor.PlazoEntrega = this.dataAtencionSelected.plazoEntrega.toString();
      this.cotProveedor.NombreArchivoCotizacion = this.dataAtencionSelected.nombreArchivoCotizacion;
      this.cotProveedor.ComentariosProveedor = this.dataAtencionSelected.comentariosProveedor;
      this.cotProveedor.IdMoneda = this.dataAtencionSelected.idMoneda;
    } else {
      this.cotProveedor.NombreArchivoCotizacion = "";
      this.formCotizacion.instance.option("formData", this.cotProveedor);
    }

    if (!this.dataAtencionSelected.cotizacionEnviada) {
        //COLOCO EL FOCO EN EL PRIMER CAMPO
        let that = this;
        setTimeout(function() {
            that.formCotizacion.instance.getEditor("IdMoneda").focus();
        }, 500); 
    }
  }
  ocultarPopupCotizacionClick(){
    this.popupCotizacionVisible = false;
  }
  registrarCotizacion() {
    this.cotProveedor = this.formCotizacion.instance.option("formData");

    if (this.formCotizacion.instance.validate().isValid) {
      if (this.fileSeleccionados.length <= 0 && this.cotProveedor.NombreArchivoCotizacion=="") {
          notify({
              message: "Debe registrar la cotización para la atención!",
              position: {
                  my: "center center",
                  at: "center center"
              }, width: 500, 
          }, "error", 2000);  
          return;
      }

      this.botonGrabarDisabled = true; 
      this.loadingVisible = true;

      if (this.fileSeleccionados.length > 0)
        this.cotProveedor.NombreArchivoCotizacion = this.fileSeleccionados[0].name;
      else  
        this.cotProveedor.NombreArchivoCotizacion = "";

      this.cotProveedor.IdAtencion = this.idAtencionSelected;
      this.cotProveedor.IdProveedorAtencion = this.idProveedorAtencionSelected;
      this.cotProveedor.IdUsuarioCreacion = this.authService.usuarioLogueado.idUsuario;

      this.ateService.registrarCotizacion(this.cotProveedor).subscribe(data => {
                              if (data.codigo == "OK") {
                                  //SUBIR LOS ARCHIVOS AL SERVIDOR
                                  if (this.fileSeleccionados.length > 0) {
                                      this.subirArchivos(data.data.nombreArchivoCotizacion);
                                  } else  {
                                      this.botonGrabarDisabled = false; 
                                      this.loadingVisible = false;
                                      notify({
                                          message: "Grabación Correcta!" ,
                                          position: {
                                          my: "center middle",
                                          at: "center middle"
                                          }, width: 300, 
                                          }, "success", 1500);  
                                      this.repoblar();
                                      this.ocultarPopupCotizacionClick();   
                                  } 

                              } else {
                                  this.botonGrabarDisabled = false; 
                                  this.loadingVisible = false;
                                  notify({
                                      message: data.mensaje ,
                                      position: {
                                          my: "center middle",
                                          at: "center middle"
                                      }, width: 300, 
                                  }, "error", 5000);
                              }    
                          });
    }
  }
  cargaFinalizada () {
    //ENVIAR NOTIFICACION COTIZACION REGISTRADA
    notify({
        message: "Grabación Correcta!" ,
        position: {
        my: "center center",
        at: "center center"
        }, width: 300, 
        }, "success", 1500);  

    this.botonGrabarDisabled = false; 
    this.loadingVisible = false;        
    this.repoblar();
    this.ocultarPopupCotizacionClick();   
  }
  subirArchivos (ruta: string) {
    let headers = {ruta: ruta};
    this.fileUploader.instance.option("uploadHeaders", headers);  
    (<any> this.fileUploader.instance)._uploadFiles();  
  }  
  asignarValoresUpLoadFile (e) {
    $(".dx-fileuploader-upload-button").css({"visibility":"hidden"});
  }  
  ocultarPopupConfirmar() {
    this.popupConfirmarVisible = false;
  }
  confirmarOK() {
    let cotizacion = new Proveedor();
    cotizacion.IdUsuarioCreacion = this.authService.usuarioLogueado.idUsuario;
    cotizacion.IdProveedorAtencion = this.idProveedorAtencionSelected;
    this.botonEnviarDisabled = true;
    this.loadingVisible = true;

    this.ateService.enviarCotizacionRegistrada(cotizacion).subscribe(data => {
      if (data.codigo == "OK") {
          notify({
            message: "Grabación Correcta!" ,
            position: {
            my: "center middle",
            at: "center middle"
            }, width: 300, 
          }, "success", 1500);  
          this.botonEnviarDisabled = false;
          this.loadingVisible = false;
          this.repoblar();
          this.ocultarPopupConfirmar();
      } else {
          this.botonEnviarDisabled = false;
          this.loadingVisible = false;
          notify({
              message: data.mensaje ,
              position: {
                  my: "center middle",
                  at: "center middle"
              }, width: 300, 
          }, "error", 5000);
      }    
    });
  }  
  enviarCotizacion() {
    if (this.dataAtencionSelected.idMoneda > 0) { 
       if (!this.dataAtencionSelected.cotizacionEnviada) {
          this.popupConfirmarVisible = true;
       } else {
          notify({
            message: 'Cotización ya se encuentra enviada!' ,
            position: {
                my: "center middle",
                at: "center middle"
            }, width: 300, 
          }, "error", 5000);   
       }
    } else {
      notify({
          message: 'Para poder enviar la propuesta primero debe registrarla' ,
          position: {
              my: "center middle",
              at: "center middle"
          }, width: 300, 
        }, "error", 5000);      
    }
  }
  eliminarCotizacion() {
    if (this.dataAtencionSelected.idMoneda > 0) { 
      if (!this.dataAtencionSelected.cotizacionEnviada) {
         this.popConfEliminarVisible = true;
      } else {
         notify({
           message: 'Cotización ya se encuentra enviada, no es posible eliminarla' ,
           position: {
               my: "center middle",
               at: "center middle"
           }, width: 300, 
         }, "error", 5000);   
      }
   } else {
     notify({
         message: 'No existe cotización para eliminar!' ,
         position: {
             my: "center middle",
             at: "center middle"
         }, width: 300, 
       }, "error", 5000);      
   }
  }
  ocultarPopConfEliminar() {
    this.popConfEliminarVisible = false;
  }
  confirmarEliminarOK() {
    let cotizacion = new Proveedor();
    cotizacion.IdUsuarioCreacion = this.authService.usuarioLogueado.idUsuario;
    cotizacion.IdProveedorAtencion = this.idProveedorAtencionSelected;
    this.botonEliminarCotDisabled = true;
    this.loadingVisible = true;
    this.ateService.eliminarCotizacionRegistrada(cotizacion).subscribe(data => {
      if (data.codigo == "OK") {
          this.botonEliminarCotDisabled = false;
          this.loadingVisible = false;
          notify({
            message: "Grabación Correcta!" ,
            position: {
            my: "center middle",
            at: "center middle"
            }, width: 300, 
          }, "success", 1500);  
          this.repoblar();
          this.ocultarPopConfEliminar();
      } else {
          this.botonEliminarCotDisabled = false;
          this.loadingVisible = false;
          notify({
              message: data.mensaje ,
              position: {
                  my: "center middle",
                  at: "center middle"
              }, width: 300, 
          }, "error", 5000);
      }    
    });
  }  
}