import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { DxFormComponent, DxFileUploaderComponent, DxDataGridComponent } from 'devextreme-angular';
import ArrayStore from 'devextreme/data/array_store';
import notify from 'devextreme/ui/notify';

import { TicketService } from '../../../shared/services/ticket.service'; 
import { AuthService } from '../../../shared/services/auth.service';
import { ImagenTicket } from '../../../models/ImagenTicket';
import { Ticket } from '../../../models/Ticket';
import { environment } from 'src/environments/environment';
import $ from "jquery";

@Component({
    selector: 'adm-ticket',
    styleUrls: ['./adm-ticket.component.css'],
    templateUrl: './adm-ticket.component.html'
})

export class AdmTicketComponent {
    ticket = new Ticket();
    fileSeleccionados: any[] = [];
    prioridades: any; locales: any; zonas: any; subZonas: any; especialidades: any;
    imagenes: any;
    urlUpLoad: string;
    idPrioridadInicial: number;
    idLocalInicial: number;
    botonGrabarDisabled = true;
    mostrarFileUploader = true;
    loadingVisible = false;
    comentariosPattern: any = /^[^\/]+$/;

    @Output() cerrarPopup = new EventEmitter<any>();
    @Output() repoblarTickets = new EventEmitter<any>();
    @ViewChild(DxFormComponent, { static: false }) formTicket: DxFormComponent;
    @ViewChild('uploaderImagen', { static: false }) uploaderImagen: DxFileUploaderComponent;
    @ViewChild(DxDataGridComponent, { static: false }) gridImagenes: DxDataGridComponent;

    constructor(private tckService: TicketService, private authService: AuthService) {
        this.urlUpLoad = environment.APICargaArchivo + environment.urlUpLoad;
        let idUsuario = this.authService.usuarioLogueado.idUsuario;
        let idCliente = this.authService.usuarioLogueado.idClienteSeleccionado;
        this.cambioLocal = this.cambioLocal.bind(this);
        this.cambioZona = this.cambioZona.bind(this);

        this.tckService.listarPrioridades(false).subscribe(data => {
                                        this.prioridades = new ArrayStore({data: data,key: "idTipo"}); 
                                        this.idPrioridadInicial = data[0].idTipo;
                                    }); 
        this.tckService.listarLocalesByClienteUsuario(idUsuario, idCliente, false).subscribe(data => {
                                       this.locales = new ArrayStore({data: data,key: "idLocal"}); 
                                       if (data.length > 0){
                                           this.idLocalInicial = data[0].idLocal;
                                       } else {
                                          notify({
                                                message: "El usuario no tienen locales asignados!",
                                                position: {
                                                    my: "center center",
                                                    at: "center center"
                                                }, width: 500, 
                                           }, "error", 2000);                                            
                                       }
                                   });
         this.tckService.listarEspecialidades(idCliente, false).subscribe(data => {
                                    this.especialidades = new ArrayStore({
                                       data: data,
                                       key: "idEspecialidad"
                                   }); 
                               }); 
    }
    screen(width) {
        return ( width < 700 ) ? 'sm' : 'lg';
    }
    public inicializarControles(pIdTicket: number, permitirEditar: boolean){
        if (pIdTicket == 0) {
            this.gridImagenes.instance.option('visible', false);
            this.formTicket.instance.resetValues();
            this.uploaderImagen.instance.reset();
            this.ticket.IdPrioridad = this.idPrioridadInicial; 
            this.ticket.IdLocal = this.idLocalInicial;
            this.ticket.NumeroTicketCliente = this.formatDate(new Date());
            this.ticket.IdTicket = 0;
            this.imagenes = [];
            this.formTicket.instance.option("formData", this.ticket);
        } else {
            //OBTENER EL TICKET SELECCIONADO Y ASIGNAR VALORES
            this.tckService.leerTicketById(pIdTicket).subscribe(data => {
                this.imagenes = data.data.imagenesTicket;
                this.formTicket.instance.resetValues();
                this.uploaderImagen.instance.reset();
                this.ticket.IdTicket = data.data.idTicket;
                this.ticket.NumeroTicketCliente = data.data.numeroTicketCliente;
                this.ticket.IdPrioridad = data.data.idPrioridad;
                this.ticket.IdLocal = data.data.idLocal;
                this.ticket.IdZona = data.data.idZona;
                this.ticket.IdSubZona = data.data.idSubZona;
                this.ticket.IdEspecialidad = data.data.idEspecialidad;
                this.ticket.Comentarios = data.data.comentarios;
                if (this.imagenes.length > 0) {
                    this.gridImagenes.instance.option('visible', true);
                 } else {
                    this.gridImagenes.instance.option('visible', false);
                 }
                 if (this.imagenes.length >=3 || !permitirEditar) {
                   this.mostrarFileUploader = false;
                 } else {
                   this.mostrarFileUploader = true;
                 }

                this.formTicket.instance.option("formData", this.ticket);
            }); 
        }

        if (permitirEditar) {
            //COLOCO EL FOCO EN EL PRIMER CAMPO
            let that = this;
            setTimeout(function() {
                that.formTicket.instance.getEditor("NumeroTicketCliente").focus();
            }, 500); 
            this.formTicket.instance.option("disabled", false);
            this.gridImagenes.instance.option("disabled", false);
            this.gridImagenes.instance.option("height", 200);
            this.botonGrabarDisabled = false;
            this.mostrarFileUploader = true;
        } else {
            this.formTicket.instance.option("disabled", true);
            this.gridImagenes.instance.option("disabled", true);
            this.gridImagenes.instance.option("height", 200);
            this.botonGrabarDisabled = true;
            this.mostrarFileUploader = false;
        }
    }

    public inicializarControlesGestor(pIdTicket: number, permitirEditar: boolean){
        if (pIdTicket == 0) {
            this.gridImagenes.instance.option('visible', false);
            this.formTicket.instance.resetValues();
            this.uploaderImagen.instance.reset();
            this.ticket.IdPrioridad = this.idPrioridadInicial; 
            this.ticket.IdLocal = this.idLocalInicial;
            this.ticket.IdTicket = 0;
            this.imagenes = [];
            this.formTicket.instance.option("formData", this.ticket);
        } else {
            //OBTENER EL TICKET SELECCIONADO Y ASIGNAR VALORES
            this.tckService.leerTicketById(pIdTicket).subscribe(data => {
                this.imagenes = data.data.imagenesTicket;
                this.formTicket.instance.resetValues();
                this.uploaderImagen.instance.reset();
                this.ticket.IdTicket = data.data.idTicket;
                this.ticket.NumeroTicketCliente = data.data.numeroTicketCliente;
                this.ticket.IdPrioridad = data.data.idPrioridad;
                this.ticket.IdLocal = data.data.idLocal;
                this.ticket.IdZona = data.data.idZona;
                this.ticket.IdSubZona = data.data.idSubZona;
                this.ticket.IdEspecialidad = data.data.idEspecialidad;
                this.ticket.Comentarios = data.data.comentarios;
                if (this.imagenes.length > 0) {
                    this.gridImagenes.instance.option('visible', true);
                 } else {
                    this.gridImagenes.instance.option('visible', false);
                 }
                 if (this.imagenes.length >=3 || !permitirEditar) {
                   this.mostrarFileUploader = false;
                 } else {
                   this.mostrarFileUploader = true;
                 }

                this.formTicket.instance.option("formData", this.ticket);
            }); 
        }

        this.formTicket.instance.itemOption("NumeroTicketCliente", "disabled", true)
        this.formTicket.instance.itemOption("IdLocal", "disabled", true)
        this.formTicket.instance.itemOption("IdZona", "disabled", true)
        this.formTicket.instance.itemOption("IdSubZona", "disabled", true)
        this.formTicket.instance.itemOption("Comentarios", "disabled", true)
        this.gridImagenes.instance.option("disabled", true);
        this.gridImagenes.instance.option("height", 200);
        this.gridImagenes.instance.option("height", 200);
        this.botonGrabarDisabled = false;
        this.mostrarFileUploader = false;
    }

    formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();
    
        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;
    
        return [year, month, day].join('');
    }
    eliminoImagen(){
        if (this.imagenes.length <=0 ) {
            this.gridImagenes.instance.option('visible', false);
        }
        this.mostrarFileUploader = true;
      }
    cambioLocal(evt) {
        if (evt.selectedItem) {
            this.idLocalInicial = evt.selectedItem.idLocal;
            this.tckService.listarZonasByLocal(this.idLocalInicial, false).toPromise().then((data: any) => {
                this.zonas = new ArrayStore({
                            data: data,
                            key: "idZona"
                        }); 
                this.subZonas = new ArrayStore({
                    data: null,
                    key: "idZona"
                });                
           }); 
       }
    }
    cambioZona(evt) {
        if (evt.selectedItem) {
            let idZona = evt.selectedItem.idZona;
            this.tckService.listarSubZonasByZona(idZona, false).toPromise().then(data => {
                        this.subZonas = new ArrayStore({
                                            data: data,
                                            key: "idSubZona"
                                        }); 
            });
       }
    }    
    actualizarClick (args){
        if (this.formTicket.instance.validate().isValid) {
            if (this.fileSeleccionados.length <= 0 && this.imagenes.length <= 0) {
                notify({
                    message: "Debe registrar por lo menos un archivo de evidencia",
                    position: {
                        my: "center center",
                        at: "center center"
                    }, width: 500, 
                }, "error", 2000);  
                return;
            }
            
            this.ticket = this.formTicket.instance.option("formData");
            let imagenesTotal: ImagenTicket[] = [] ;
            this.imagenes.forEach(function (value) {
                imagenesTotal.push({'PathURLImagen': value.nombre, 'Nombre' : value.nombre, 'Tipo' : '' });
            }); 

            this.fileSeleccionados.forEach(function (value) {
                imagenesTotal.push({'PathURLImagen': value.name, 'Nombre' : value.name, 'Tipo' : '' });
            }); 

            if (imagenesTotal.length > 3) {
                notify({
                    message: "Solo puede subir tres imagenes como evidencia debe eliminar alguna de las registradas!",
                    position: {
                        my: "center center",
                        at: "center center"
                    }, width: 500, 
                }, "error", 2000);  
                return;
            } 

            this.botonGrabarDisabled = true;
            this.loadingVisible = true;
            this.ticket.ImagenesTicket = imagenesTotal;  
            let idUsuario = this.authService.usuarioLogueado.idUsuario;
            let idCliente = this.authService.usuarioLogueado.idClienteSeleccionado;
            this.ticket.IdUsuarioSolicitante = idUsuario;
            this.ticket.IdOrigenTicket = 1; //SIEMPRE SE COLOCARA 1 => ORIGEN LIBRE / 2 => ORIGEN SUPERVISION
            this.ticket.IdCliente = idCliente;
            this.tckService.registrarTicket(this.ticket).subscribe(data => {
                                    if (data.codigo == "OK") {
                                        //SUBIR LOS ARCHIVOS AL SERVIDOR
                                        if (this.fileSeleccionados.length > 0) {
                                            this.subirArchivos(data.rutaEvidencia);
                                        } else  {
                                            this.loadingVisible = false;
                                            notify({
                                                message: "Grabación Correcta!" ,
                                                position: {
                                                my: "center middle",
                                                at: "center middle"
                                                }, width: 300, 
                                                }, "success", 1500);  
                                            this.repoblarTickets.emit();
                                            this.cerrarPopupClick();                                            
                                        } 
                                    } else {
                                        this.botonGrabarDisabled = false;
                                        this.loadingVisible = false;
                                        notify({
                                            message: data.mensaje ,
                                            position: {
                                                my: "center middle",
                                                at: "center middle"
                                            }, width: 300, 
                                        }, "error", 5000);
                                    }    
                                });
        }
    }
    cargaFinalizada () {
        this.loadingVisible = false;
        //ENVIAR NOTIFICACION TICKET AGREGADO
        notify({
            message: "Grabación Correcta!" ,
            position: {
            my: "center center",
            at: "center center"
            }, width: 300, 
            }, "success", 1500);  
        this.repoblarTickets.emit();
        this.cerrarPopupClick();
    }
    subirArchivos (ruta: string) {
        let headers = {ruta: ruta};
        this.uploaderImagen.instance.option("uploadHeaders", headers);  
        (<any> this.uploaderImagen.instance)._uploadFiles();  
    }
    asignarValoresUpLoadFile (e) {
        $(".dx-fileuploader-upload-button").css({"visibility":"hidden"});
    }
    cerrarPopupClick() {
        this.cerrarPopup.emit();
    }
}