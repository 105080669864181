import { Injectable } from '@angular/core';

export class ChartEvaluacion {
    periodo: string;
    total: number;
    observadas: number;
    puntaje: number;
}

let chartDataFalencia: ChartEvaluacion[] = [
    { periodo: "Oct-2019", total: 78, observadas: 12, puntaje: 0 },
    { periodo: "Nov-2019", total: 80, observadas: 15, puntaje: 0 },
    { periodo: "Dic-2019", total: 82, observadas: 20, puntaje: 0 },
];

@Injectable()
export class DashBoardsService {
    getDataFalencias(): ChartEvaluacion[] {
         var data = chartDataFalencia 

        return data.map(function (item) {
            return {
                periodo: item.periodo,
                total: item.total,
                observadas: item.observadas,
                puntaje: 0
            };
        });
    } 

    getDataPuntajes(): ChartEvaluacion[] {
        var data = chartDataFalencia 

       return data.map(function (item) {
           return {
               periodo: item.periodo,
               total: item.total,
               observadas: item.observadas,
               puntaje: (1- (item.observadas / item.total)) * 100
           };
       });
   } 
}