export class SubZona {
    IdSubZona: number;
    DescripcionSubZona: string;
    IdTipoSubZona: number;
    IdUsuarioCreacion: number;
    DescipcionTipoSubZona: string;
    RegistroActivo: boolean;
    Puntaje: number;
    PuntajeText: string;
    NombreImagen: string; 
    PathURLImagen: string; 
}