import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { DxFormComponent } from 'devextreme-angular';
import notify from 'devextreme/ui/notify';

import { AuthService } from '../../../shared/services/auth.service';
import { Supervision } from 'src/app/models/Supervision';
import { SupervisionService } from 'src/app/shared/services/supervision.service';

@Component({
    selector: 'adm-prog-supervision',
    styleUrls: ['./adm-prog-sup.component.css'],
    templateUrl: './adm-prog-sup.component.html'
})

export class AdmProgSupComponent {
    supervision = new Supervision();
    botonGrabarDisabled = true;
    fechaMin: Date = new Date();

    @Output() cerrarPopup = new EventEmitter<any>();
    @Output() repoblarSupervisiones = new EventEmitter<any>();

    @ViewChild(DxFormComponent, { static: false }) formSupervision: DxFormComponent;

    constructor(private supService: SupervisionService, private authService: AuthService) {
    }

    public inicializarControles(rondaSupervision: any, permitirEditar: boolean){
        //console.log(rondaSupervision);
        if (rondaSupervision.idSupervision == 0) {
            this.formSupervision.instance.resetValues();
            this.supervision.PeriodoSupervision = rondaSupervision.periodoSupervision; 
            this.supervision.SupervisarInfraestructura = rondaSupervision.supervisarInfraestructura;
            this.supervision.SupervisarActivos = rondaSupervision.supervisarActivos;
            this.supervision.SoloRealizarInventario = rondaSupervision.soloRealizarInventario;
            this.supervision.IdSupervision = 0;
            this.formSupervision.instance.option("formData", this.supervision); 
        } else {
            //OBTENER LA SUPERVISION SELECCIONADO Y ASIGNAR VALORES
            this.formSupervision.instance.resetValues();
            this.supervision.PeriodoSupervision = rondaSupervision.periodoSupervision; 
            this.supervision.SupervisarInfraestructura = rondaSupervision.supervisarInfraestructura;
            this.supervision.SupervisarActivos = rondaSupervision.supervisarActivos;
            this.supervision.SoloRealizarInventario = rondaSupervision.soloRealizarInventario;
            this.supervision.DescripcionSupervision = rondaSupervision.descripcionSupervision; 
            this.supervision.FechaInicio = rondaSupervision.fechaInicio;
            this.supervision.IdEstadoProceso = rondaSupervision.idEstadoProceso;
            this.supervision.IdSupervision = rondaSupervision.idSupervision;
            this.supervision.FechaCreacion = rondaSupervision.fechaCreacion;
            this.formSupervision.instance.option("formData", this.supervision); 
        }

        if (permitirEditar) {
            //COLOCO EL FOCO EN EL PRIMER CAMPO
            let that = this;
            setTimeout(function() {
                that.formSupervision.instance.getEditor("DescripcionSupervision").focus();
            }, 500); 
            this.formSupervision.instance.option("disabled", false);
            this.botonGrabarDisabled = false;
        } else {
            this.formSupervision.instance.option("disabled", true);
            this.botonGrabarDisabled = true;
        }
    }

    actualizarClick (args){
         if (this.formSupervision.instance.validate().isValid) {

             this.supervision = this.formSupervision.instance.option("formData");
             let idUsuario = this.authService.usuarioLogueado.idUsuario;
             let idCliente = this.authService.usuarioLogueado.idClienteSeleccionado;
             this.supervision.IdUsuarioCreacion = idUsuario;
             this.supervision.TipoSupervision = "NORMAL"; //SIEMPRE SE COLOCARA NORMAL PARA ESTE MANTENIMIENTO
             this.supervision.IdCliente = idCliente;
             this.supService.registrarRondaSupervision(this.supervision).subscribe(data => {
                                     if (data.codigo == "OK") {
                                        notify({
                                                message: "Grabación Correcta!" ,
                                                position: {
                                                my: "center center",
                                                at: "center center"
                                            }, width: 300, 
                                        }, "success", 1500);  
                                        this.repoblarSupervisiones.emit();
                                        this.cerrarPopupClick();                                            
                                     } else {
                                         notify({
                                             message: data.mensaje ,
                                             position: {
                                                 my: "center center",
                                                 at: "center center"
                                             }, width: 300, 
                                         }, "error", 5000);
                                     }    
                                 });
        }
    }
    cerrarPopupClick() {
        this.cerrarPopup.emit();
    }
}