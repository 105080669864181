import { Component, NgModule, Input, Output, EventEmitter } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AuthService } from '../../services';
import { UserPanelModule } from '../user-panel/user-panel.component';
import { DxButtonModule } from 'devextreme-angular/ui/button';
import { DxToolbarModule } from 'devextreme-angular/ui/toolbar';
import { DxTooltipModule, DxTemplateModule } from 'devextreme-angular';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-header',
  templateUrl: 'header.component.html',
  styleUrls: ['./header.component.scss'] 
})

export class HeaderComponent {
  @Output() menuToggle = new EventEmitter<boolean>();
  @Output() recargarMenu = new EventEmitter<any>();
  @Output() cargarMenuCompleto = new EventEmitter<any>();

  @Input() menuToggleEnabled = false;
  @Input() title: string;

  menuSupervision = false;
  menuTicket = false;
  menuContratos = false;
  menuIoT = false;
  menuConfiguracion = false;
  pathImagenUsuario = "";
  pathLogoCliente = "";

  tooltipVisibleSup = false;
  tooltipVisibleTck = false;
  tooltipVisibleCont = false;
  tooltipVisibleIoT = false;
  tooltipVisibleConf = false;

  userMenuItems = [{
    text: 'Perfil',
    icon: 'user'
  }, {
    text: 'Cerrar Sesión',
    icon: 'runner',
    onClick: () => {
      this.authService.logOut();
    }
  }];

  constructor(private authService: AuthService, private sanitizer: DomSanitizer) { 
    
    this.btnSupReady = this.btnSupReady.bind(this);
    this.btnTckReady = this.btnTckReady.bind(this);
    this.btnContratosReady = this.btnContratosReady.bind(this);
    this.btnIoTReady = this.btnIoTReady.bind(this);
    this.btnConfReady = this.btnConfReady.bind(this);
    
    this.toggleToolTipSupervision = this.toggleToolTipSupervision.bind(this);
    this.toggleToolTipTicket = this.toggleToolTipTicket.bind(this);
    this.toggleToolTipContratos = this.toggleToolTipContratos.bind(this);
    this.toggleToolTipIoT = this.toggleToolTipIoT.bind(this);
    this.toggleToolTipConf = this.toggleToolTipConf.bind(this);

    let idUsuario = this.authService.usuarioLogueado.idUsuario;
    let idRol = this.authService.usuarioLogueado.idRolSeleccionado;
    this.pathImagenUsuario = this.authService.usuarioLogueado.pathURLFotoUsuario;
    this.pathLogoCliente = environment.EvidenciaMINTTO + "/" +this.authService.usuarioLogueado.pathURLLogoCliente;
    this.authService.listarOpcionesUsuario(idUsuario, idRol)
                         .subscribe(data => {
                             if (data.codigo == "OK") {
                               let cargarMenu = true;
                               this.cargarMenuCompleto.emit(data.items);
                               for (let i in data.items) {
                                 if (data.items[i].codigoModulo == 'SUPERV') {
                                     this.menuSupervision = true;
                                     if (cargarMenu) {
                                        cargarMenu = false;  
                                        this.recargarMenu.emit('SUPERV');
                                     }
                                 } else if (data.items[i].codigoModulo == 'TICKET') {
                                    this.menuTicket = true;
                                    if (cargarMenu) {                                    
                                        this.recargarMenu.emit('TICKET');
                                    }
                                 } else if (data.items[i].codigoModulo == 'CONTR') {
                                    this.menuContratos = true;
                                    if (cargarMenu) {
                                        this.recargarMenu.emit('CONTR');
                                    }
                                 } else if (data.items[i].codigoModulo == 'IOT') {
                                    this.menuIoT = true;
                                    if (cargarMenu) {
                                        this.recargarMenu.emit('IOT');
                                    }
                                 } else if (data.items[i].codigoModulo == 'ADMIN') {
                                    this.menuConfiguracion = true;
                                    if (cargarMenu) {
                                        this.recargarMenu.emit('ADMIN');
                                    }
                                 }
                               }
                             }                             
                           });

  }

  clickSupervision = () => {
    this.recargarMenu.emit('SUPERV');
  }
  clickTicket = () => {
    this.recargarMenu.emit('TICKET');
  }
  clickContratos = () => {
    this.recargarMenu.emit('CONTR');
  }
  clickIoT = () => {
    this.recargarMenu.emit('IOT');
  }
  clickConfiguracion = () => {
    this.recargarMenu.emit('ADMIN');
  }
  toggleMenu = () => {
    this.menuToggle.emit();
  }

  toggleToolTipSupervision() {
    this.tooltipVisibleSup = !this.tooltipVisibleSup;
  }
  toggleToolTipTicket() {
    this.tooltipVisibleTck = !this.tooltipVisibleTck;
  }
  toggleToolTipContratos() {
    this.tooltipVisibleCont = !this.tooltipVisibleCont;
  }
  toggleToolTipIoT() {
    this.tooltipVisibleIoT = !this.tooltipVisibleIoT;
  }
  toggleToolTipConf() {
    this.tooltipVisibleConf = !this.tooltipVisibleConf;
  }  
  btnSupReady(e) {
    e.element.id = "btnSupervision";
    e.element.onmouseenter = this.toggleToolTipSupervision;
    e.element.onmouseleave = this.toggleToolTipSupervision;
  }
  btnTckReady(e) {
    e.element.id = "btnTicket";
    e.element.onmouseenter = this.toggleToolTipTicket;
    e.element.onmouseleave = this.toggleToolTipTicket;
  }
  btnContratosReady(e) {
    e.element.id = "btnContratos";
    e.element.onmouseenter = this.toggleToolTipContratos;
    e.element.onmouseleave = this.toggleToolTipContratos;
  }
  btnIoTReady(e) {
    e.element.id = "btnIoT";
    e.element.onmouseenter = this.toggleToolTipIoT;
    e.element.onmouseleave = this.toggleToolTipIoT;
  }  
  btnConfReady(e) {
    e.element.id = "btnConfiguracion";
    e.element.onmouseenter = this.toggleToolTipConf;
    e.element.onmouseleave = this.toggleToolTipConf;
  } 
}

@NgModule({
  imports: [
    CommonModule,
    DxButtonModule,
    UserPanelModule,
    DxToolbarModule, DxTooltipModule, DxTemplateModule
  ],
  declarations: [ HeaderComponent ],
  exports: [ HeaderComponent ]
})
export class HeaderModule { }
