import { Component, ViewChild } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import CustomStore from 'devextreme/data/custom_store';
import * as AspNetData from "devextreme-aspnet-data-nojquery";
import config from 'devextreme/core/config';
import notify from 'devextreme/ui/notify';
import { DxDataGridComponent, DxLinearGaugeModule } from 'devextreme-angular';
import { AuthService } from 'src/app/shared/services';
import { environment } from '../../../../environments/environment';
import { TicketService } from '../../../shared/services/ticket.service';
import { ImagenTicket } from '../../../models/ImagenTicket';
import { AdmTicketComponent } from '../adms/adm-ticket.component'
import { FiltroGeneral } from 'src/app/models/filtroGeneral';
import { EstadoProceso } from 'src/app/models/EstadoProceso';
//import { ConsHistorialTckComponent } from '../consultas/cons-historial-tickets.component';

config({
  floatingActionButtonConfig: {
      icon: 'rowfield',
      position: {
          of: '#gridTickets',
          my: 'right bottom',
          at: 'right bottom',
          offset: '-16 -16'
      }
  }
}); 

@Component({
  templateUrl: 'mis-tickets-bandeja.component.html',
  styleUrls: ['mis-tickets-bandeja.component.css']
})

export class MisTicketsBandejaComponent {
  @ViewChild(DxLinearGaugeModule, { static: false }) gaugeTicket: DxLinearGaugeModule;
  @ViewChild(DxDataGridComponent, { static: false }) gridTickets: DxDataGridComponent;
  @ViewChild(AdmTicketComponent, { static: false }) admTicket: AdmTicketComponent;
  dsTicket: any;
  dsImagenesTck: ImagenTicket[];
  selectedRowIndex = -1;
  idTicketSelected = -1;
  popupVisible = false;
  popupImagenesVisible = false;
  popupHistorialVisible = false;
  tituloRegistro = "";
  tituloVerImagenes = "";
  tituloHistorialTicket = "";
  valorAlcanzado = 0;
  imagenInicial = 0;
  tituloDetalleFalencia = "";
  popupDetFalenciaVisible = false;
  dsSoluciones:any;
  dataTicketSelected:any;
  urlDetalleTicket = "";
  descripcionFalencia = "";

  //idTipoAsignacion : string;
  listaEstados: EstadoProceso[];
  esEditable = false;

  filtroEvaluacion: FiltroGeneral;

  constructor(private httpClient: HttpClient, private authService: AuthService, private tckService: TicketService) {
    this.filtroEvaluacion = new FiltroGeneral();
    let fechaActual = new Date(); 
    fechaActual.setDate(fechaActual.getDate() - 30);
    this.filtroEvaluacion.FechaInicio = fechaActual;
    this.filtroEvaluacion.FechaTermino = new Date();

    this.repoblarTickets();
    this.verFlujoAtencion = this.verFlujoAtencion.bind(this);
    this.verImagenesClick = this.verImagenesClick.bind(this);
    this.verDetalleFalencia = this.verDetalleFalencia.bind(this);
    this.verActaAprobacion = this.verActaAprobacion.bind(this);
    this.urlDetalleTicket = environment.APITicketCore + "/maestros/listaSolByFalenciaSup";

    
  }
  isFalenciaIconVisible(e) {
    return e.row.data.codigoOrigenTicket == "SUPE";
  }
  verDetalleFalencia() {
    
    if (this.idTicketSelected > 0 && this.dataTicketSelected.idFalenciaSupervision != null) {
      let idFalencia = this.dataTicketSelected.idFalenciaSupervision;
      let esActivo = this.dataTicketSelected.falenciaActivo;
      this.descripcionFalencia = this.dataTicketSelected.comentarios;

      this.dsSoluciones = AspNetData.createStore({
                                  key: "idSolucionFalencia",
                                  loadParams: { pIdFalencia: idFalencia, pEsActivo: esActivo },
                                  loadUrl: this.urlDetalleTicket
                          })

      this.tituloDetalleFalencia = "Detalle de la Falencia Ticket: " + this.idTicketSelected;
      this.popupDetFalenciaVisible = true                   
    } else {
        notify({
          message: "Seleccione el registro primero!",
          position: {
              my: "center center",
              at: "center center"
          }, width: 500, 
        }, "error", 2000);
    }   
  } 
  verActaAprobacion(e) {
    var win = window.open(environment.EvidenciaMINTTO + "/" + e.row.data.pathURLActa, '_blank');
    win.focus();
  }

  isActaAprobacion(e) {
    return e.row.data.pathURLActa != "";
  }
/*
  customizeTooltip(arg: any) {
    let historialAtencion = JSON.parse(localStorage.getItem('historial'));
    this.idTipoAsignacion = localStorage.getItem('IdTipoAsignacion');

    let idEstado = 0; 

    if (this.idTipoAsignacion == "21")
    {
        if (arg.value == 0) {
          idEstado = 1;
        } else if (arg.value == 20) {
          idEstado = 2;
        } else if (arg.value == 40) {
          idEstado = 3;
        } else if (arg.value == 60) {
          idEstado = 4;
        }  else if (arg.value == 80) {
          idEstado = 5;
        } else if (arg.value == 100) {
          idEstado = 6;
        } else {
          return arg.valueText;
        }
    }
    else
    {
        if (arg.value == 0) {
          idEstado = 1;
        } else if (arg.value == 20) {
          idEstado = 2;
        } else if (arg.value == 40) {
          idEstado = 3;
        } else if (arg.value == 60) {
          idEstado = 4;
        }  else if (arg.value == 80) {
          idEstado = 5;
        } else {
          return arg.valueText;
        }
    }


    let historia: any;
    historialAtencion.forEach(element => {
        if (element.idEstadoProceso == idEstado) {
          historia = element;
        }
    }); 

    let resultado = {text: "Estado: NO INICIADO" };
    if (historia) {
      resultado = { text: "Estado: " + historia.idEstadoProceso}
    }
    return resultado;
  }

  */

  repoblarTickets () {
    let idUsuario = (this.authService.usuarioLogueado.idRolSeleccionado == 3) ? -1 : this.authService.usuarioLogueado.idUsuario;
    let idCliente = this.authService.usuarioLogueado.idClienteSeleccionado;
    let loadParams = { idCliente: idCliente,  idUsuario: idUsuario, fechaInicio : this.filtroEvaluacion.FechaInicio, fechaTermino: this.filtroEvaluacion.FechaTermino}
    this.dsTicket = new CustomStore({
                    key: "idTicket",
                    load: () => this.sendRequest(environment.APITicketCore + "/consultas/consultarMisTicketsWeb", "LOAD", loadParams),
                    remove: (key) => this.sendRequest(environment.APITicketCore + "/ticket/eliminarTicketById", "REMOVE", {pIdTicket: key})
                  });
  }
  sendRequest(url: string, method: string, data: any = {}): any {

    let result;

    switch(method) {
        case "LOAD":
            var fechaInicio = data.fechaInicio.getFullYear().toString() + ((data.fechaInicio.getMonth() > 8) ? (data.fechaInicio.getMonth() + 1).toString() : ('0' + (data.fechaInicio.getMonth() + 1).toString())) + ((data.fechaInicio.getDate() > 9) ? data.fechaInicio.getDate().toString() : ('0' + data.fechaInicio.getDate().toString())) ;
            var fechaTermino = data.fechaTermino.getFullYear().toString() + ((data.fechaTermino.getMonth() > 8) ? (data.fechaTermino.getMonth() + 1).toString() : ('0' + (data.fechaTermino.getMonth() + 1).toString())) +  ((data.fechaTermino.getDate() > 9) ? data.fechaTermino.getDate().toString() : ('0' + data.fechaTermino.getDate().toString()))  ;
            url = url + "?idCliente=" + data.idCliente + "&idUsuario=" + data.idUsuario+ "&fechaInicio=" + fechaInicio+ "&fechaTermino=" + fechaTermino; 
            //console.log(url);
            result = this.httpClient.get(url);
            break;
        case "REMOVE":
            url = url + "?pIdTicket=" + data.pIdTicket; 
            result = this.httpClient.delete(url);
            break;
    }

    return result
        .toPromise()
        .then((data: any) => {
            //console.log(data);
            if (method === "REMOVE") { 
              if (data.codigo != 'OK') {
                  notify({
                    message: data.mensaje,
                    position: {
                        my: "center center",
                        at: "center center"
                    }, width: 500, 
                  }, "error", 2000);
              }
            } 
            return data;
        })
        .catch(e => {
            throw e && e.error && e.error.Message;
        });
  }
  editRow() {
    this.popupVisible = true;
    this.tituloRegistro = "Modificar Ticket: " + this.idTicketSelected;
    let datosRow = this.gridTickets.instance.getSelectedRowsData();
    console.log(datosRow);
    let permitirModificar = true;
    if (datosRow[0].ticketSeleccionado)
      permitirModificar = false;
    else if (datosRow[0].idFalenciaSupervision != null)
      permitirModificar = false;

    this.admTicket.inicializarControles(this.idTicketSelected, permitirModificar);
  }

  deleteRow() {
      this.gridTickets.instance.deleteRow(this.selectedRowIndex);
  }

  addRow() {
      this.popupVisible = true;
      this.tituloRegistro = "Nuevo Ticket";
      this.admTicket.inicializarControles(0, true);
  }

  selectedChanged(e) {

    this.selectedRowIndex = e.component.getRowIndexByKey(e.selectedRowKeys[0]);
    this.idTicketSelected = e.selectedRowKeys[0];
    this.dataTicketSelected = e.selectedRowsData[0];

    this.esEditable = (this.authService.usuarioLogueado.idUsuario == this.dataTicketSelected.idUsuarioSolicitante);
  }

  verImagenesClick(evt) {
    this.imagenInicial = 0;
    let idTicket = evt.row.data.idTicket;
    this.tituloVerImagenes = "Imagenes Ticket: " + idTicket;
    this.tckService.leerTicketById(idTicket).toPromise().then(data => {
          let imagenes: ImagenTicket[] = [];
          data.data.imagenesTicket.forEach(element => {
              let imagen = new ImagenTicket();
              imagen.PathURLImagen = environment.EvidenciaMINTTO + "/" + element.pathURLImagen;
              imagen.Nombre = element.nombre;
              imagenes.push(imagen)
          });
          this.dsImagenesTck = imagenes;
    });
    this.popupImagenesVisible = true;
  }

  verFlujoAtencion (evt) {
    let idTicket = evt.row.data.idTicket;
    this.tituloHistorialTicket = "Historial Atención del Ticket: " + idTicket;
    this.tckService.leerTicketById(idTicket).toPromise().then(data => {
       localStorage.setItem('historial', JSON.stringify(data.data.historialAtencion));
       //localStorage.setItem('IdTipoAsignacion', JSON.stringify(data.data.IdTipoAsignacion));

       this.listaEstados =  [];

       data.data.historialAtencion.forEach(element => {
        element.estadoProceso.classActive = "pass";
        this.listaEstados.push(element.estadoProceso);
       });

       let ultimoEstado = this.listaEstados[this.listaEstados.length - 1];

       this.listaEstados.forEach(element => {
         if(element.idEstadoProceso == data.data.idEstadoProceso)
            element.classActive = "active";
       });

        if (ultimoEstado.idEstadoProceso != 5){
            data.data.listaEstadosTicket.forEach(element => {
              if ((element.orden > ultimoEstado.orden)/* && element.idEstadoProceso != 18 && element.idEstadoProceso != 7*/)
              {
                let estadoProceso = this.listaEstados.find(x=>x.idEstadoProceso == element.idEstadoProceso);
                if (estadoProceso == undefined)
                {
                  if (element.idEstadoProceso != 18 && element.idEstadoProceso != 7){
                    element.classActive = "noActive";
                    this.listaEstados.push(element);
                  }
                }
              }
            });
        }


       let registros = data.data.historialAtencion.length;
       if (registros > 0) {
          let valor = (registros - 1) * 20;
          if (valor > 100) {
            this.valorAlcanzado = 100;
          } else {
            this.valorAlcanzado = valor;
          }
       } else {
        this.valorAlcanzado = 0;
       }
    });    
    this.popupHistorialVisible = true;
    evt.event.preventDefault();
  }

  /*
  customizeText(arg: any) {
    
    this.idTipoAsignacion = localStorage.getItem('IdTipoAsignacion');

    if (this.idTipoAsignacion == "21"){
        if (arg.value == 0) {
          return "Registrado";
        } else if (arg.value == 20) {
          return "A/R";  
        } else if (arg.value == 40) {
          return "En Cotización";  
        } else if (arg.value == 60) {
          return "EE/APP";  
        }  else if (arg.value == 80) {
          return "Terminado";  
        } else if (arg.value == 100) {
          return "Aprobado";  
        } else {
          return arg.valueText;
        }
    }else
    {
      if (arg.value == 0) {
        return "Registrado";
      } else if (arg.value == 20) {
        return "A/R";  
      } else if (arg.value == 40) {
        return "EE/APP";  
      }  else if (arg.value == 60) {
        return "Terminado";  
      } else if (arg.value == 80) {
        return "Aprobado";  
      } else {
        return arg.valueText;
      }
    }
  }  
  */

  ocultarPopup() {
    this.popupVisible = false;
  }
}