import { Component, ViewChild } from '@angular/core';
import { HttpClient, HttpParams } from "@angular/common/http";
import * as AspNetData from "devextreme-aspnet-data-nojquery";
import CustomStore from 'devextreme/data/custom_store';
import config from 'devextreme/core/config';
import notify from 'devextreme/ui/notify';
import { DxDataGridComponent, DxFormComponent } from 'devextreme-angular';
import { AuthService } from 'src/app/shared/services';
import { environment } from '../../../../environments/environment';
import { TicketService } from '../../../shared/services/ticket.service';
import { ImagenTicket } from '../../../models/ImagenTicket';
import { Ticket } from 'src/app/models/Ticket';
import { AtencionService } from 'src/app/shared/services/atencion.service';
import { Atencion } from 'src/app/models/Atencion';

config({
  floatingActionButtonConfig: {
      icon: 'rowfield',
      position: {
          of: '#gridAtenciones',
          my: 'right bottom',
          at: 'right bottom',
          offset: '-16 -16'
      }
  }
}); 

@Component({
  templateUrl: 'prog-ejec-bandeja.component.html',
  styleUrls: ['prog-ejec-bandeja.component.css']
})

export class ProgramarEjecucionBandejaComponent {
  @ViewChild(DxDataGridComponent, { static: false }) gridAtenciones: DxDataGridComponent;
  @ViewChild('formProgramarEjecucion', { static: true }) formProgramarEjecucion: DxFormComponent; 
  
  dsAtencion: any;
  dsImagenesTck: ImagenTicket[];
  ticket = new Ticket();
  selectedRowIndex = -1;
  idTicketSelected = -1;
  dataTicketSelected: any;
  popupPEVisible = false;
  popupImagenesVisible = false;
  tituloPE = "";
  tituloVerImagenes = "";
  ticketsDataMap: object = {};
  urlDetalleTicket = "";
  imagenInicial = 0;
  idAtencionSelected = -1;
  fechaMin: Date = new Date();
  popupConfirmarVisible = false;
  botonRegProgDisabled = false; 
  botonCerrarProgDisabled = false;
  loadingVisible = false;

  constructor(private httpClient: HttpClient, private authService: AuthService, private tckService: TicketService, 
                                              private ateService: AtencionService) {
    this.urlDetalleTicket = environment.APITicketCore + "/consultas/listaTicketByAtencion";
    this.repoblar();
  }
  repoblar() {

    let loadParams = { IdProveedor: this.authService.usuarioLogueado.idProveedor}; 
    this.dsAtencion = new CustomStore({
                    key: "idAtencion",
                    load: () => this.sendRequest(environment.APITicketCore + "/consultas/listaAtencionesProgramar", "LOAD", loadParams)
                  });
  }
  sendRequest(url: string, method: string, data: any = {}): any {

    let result;

    switch(method) {
        case "LOAD":
            url = url + "?pIdProveedor=" + data.IdProveedor; 
            result = this.httpClient.get(url);
            break;
        case "REMOVE":
            url = url + "?pIdTicket=" + data.pIdTicket; 
            result = this.httpClient.delete(url);
            break;
    }

    return result
        .toPromise()
        .then((data: any) => {
            if (method === "REMOVE") { 
              if (data.codigo != 'OK') {
                  notify({
                    message: data.mensaje,
                    position: {
                        my: "center center",
                        at: "center center"
                    }, width: 500, 
                  }, "error", 2000);
              }
            } 
            return data;
        })
        .catch(e => {
            throw e && e.error && e.error.Message;
        });
  }
  obtenerTickets(idAtencion: number) {
    return this.ticketsDataMap[idAtencion] = this.ticketsDataMap[idAtencion] || {
      store: AspNetData.createStore({
          key: "idTicket",
          loadParams: { pIdAtencion: idAtencion },
          loadUrl: this.urlDetalleTicket
      })
    };
  }
  clickTicket(evt) {
    this.idTicketSelected = evt.data.idTicket;
    this.dataTicketSelected = evt.data;
  }
  expandedClick(){
    this.idTicketSelected = -1;
  }
  selectedChanged(e) {
    this.selectedRowIndex = e.component.getRowIndexByKey(e.selectedRowKeys[0]);
    this.idAtencionSelected = e.selectedRowKeys[0];
  }
  verImagenesClick() {
    this.imagenInicial = 0;
    let idTicket = this.idTicketSelected;
    this.tituloVerImagenes = "Imagenes Ticket: " + idTicket;
    this.tckService.leerTicketById(idTicket).subscribe(data => {
          let imagenes: ImagenTicket[] = [];
          data.data.imagenesTicket.forEach(element => {
              let imagen = new ImagenTicket();
              imagen.PathURLImagen = environment.EvidenciaMINTTO + "/" + element.pathURLImagen;
              imagen.Nombre = element.nombre;
              imagenes.push(imagen)
          });
          this.dsImagenesTck = imagenes;
    });
    this.popupImagenesVisible = true; 
  }
  programarAsignacion(){
    this.popupPEVisible = true;
    this.tituloPE = "Programar Ejecución del Ticket: " + this.dataTicketSelected.idTicket;

    this.formProgramarEjecucion.instance.resetValues();
    
    this.formProgramarEjecucion.instance.getEditor("NumeroTicketCliente").option('disabled', true);
    this.formProgramarEjecucion.instance.getEditor("DescripcionPrioridad").option('disabled', true);
    this.formProgramarEjecucion.instance.getEditor("DescripcionLocal").option('disabled', true);
    this.formProgramarEjecucion.instance.getEditor("DescripcionZona").option('disabled', true);
    this.formProgramarEjecucion.instance.getEditor("DescripcionSubZona").option('disabled', true);
    this.formProgramarEjecucion.instance.getEditor("DescripcionEspecialidad").option('disabled', true);
    this.formProgramarEjecucion.instance.getEditor("Comentarios").option('disabled', true);

    this.ticket = new Ticket();
    this.ticket.IdTicket = this.dataTicketSelected.idTicket;
    this.ticket.NumeroTicketCliente = this.dataTicketSelected.numeroTicketCliente; 
    this.ticket.DescripcionPrioridad = this.dataTicketSelected.descripcionPrioridad; 
    this.ticket.DescripcionLocal = this.dataTicketSelected.descripcionLocal; 
    this.ticket.DescripcionZona = this.dataTicketSelected.descripcionZona; 
    this.ticket.DescripcionSubZona = this.dataTicketSelected.descripcionSubZona; 
    this.ticket.DescripcionEspecialidad = this.dataTicketSelected.descripcionEspecialidad; 
    this.ticket.Comentarios = this.dataTicketSelected.comentarios; 
    if (this.dataTicketSelected.fechaInicio != null) {
      this.ticket.FechaInicio = this.dataTicketSelected.fechaInicio; 
      this.ticket.FechaTermino = this.dataTicketSelected.fechaTermino; 
    } else {
      this.ticket.FechaInicio = this.fechaMin; 
      this.ticket.FechaTermino = this.fechaMin; 
    }

    this.ticket.IdProveedorAtencion = this.dataTicketSelected.idProveedorAtencion;
    this.ticket.IdTicketAtencion = this.dataTicketSelected.idTicketAtencion;

    this.formProgramarEjecucion.instance.option("formData", this.ticket);

    //COLOCO EL FOCO EN EL PRIMER CAMPO
    let that = this;
    setTimeout(function() {
        that.formProgramarEjecucion.instance.getEditor("FechaInicio").focus();
    }, 500); 
  }  
  ocultarPopupPEClick() {
    this.popupPEVisible = false;
  }
  ocultarPopupConfirmar() {
    this.popupConfirmarVisible = false;
  }
  confirmarOK() {
    let atencion = new Atencion();
    atencion.IdUsuarioCreacion = this.authService.usuarioLogueado.idUsuario;
    atencion.IdAtencion = this.idAtencionSelected;
    this.botonCerrarProgDisabled = true;
    this.loadingVisible = true;

    this.ateService.cerrarProgramacion(atencion).subscribe(data => {
      if (data.codigo == "OK") {
          this.botonCerrarProgDisabled = false;
          this.loadingVisible = false;
          notify({
            message: "Grabación Correcta!" ,
            position: {
            my: "center middle",
            at: "center middle"
            }, width: 300, 
          }, "success", 1500);  
          this.repoblar();
          this.ocultarPopupConfirmar();
      } else {
          this.botonCerrarProgDisabled = false;
          this.loadingVisible = false;
          notify({
              message: data.mensaje ,
              position: {
                  my: "center middle",
                  at: "center middle"
              }, width: 300, 
          }, "error", 5000);
      }    
    });
  }
  cerrarProgramacion() {
    this.popupConfirmarVisible = true;
  }
  registrarProgramacionClick() {
    if (this.formProgramarEjecucion.instance.validate().isValid) {
       
        this.ticket = this.formProgramarEjecucion.instance.option("formData");
        this.ticket.IdUsuarioCreacion = this.authService.usuarioLogueado.idUsuario;

        if (this.ticket.FechaInicio > this.ticket.FechaTermino) {
            notify({
              message: "La fecha final no puede ser menor a la fecha inicio!" ,
              position: {
              my: "center middle",
              at: "center middle"
              }, width: 300, 
            }, "error", 1500); 
            return; 
        }

        this.botonRegProgDisabled = true; 
        this.loadingVisible = true;

        this.ateService.programarEjecucion(this.ticket).subscribe(data => {
                             if (data.codigo == "OK") {
                                 this.botonRegProgDisabled = false; 
                                 this.loadingVisible = false;
                                 notify({
                                   message: "Grabación Correcta!" ,
                                   position: {
                                   my: "center middle",
                                   at: "center middle"
                                   }, width: 300, 
                                 }, "success", 1500);  
                                 this.repoblar();
                                 this.ocultarPopupPEClick();                                            
                             } else {
                                this.botonRegProgDisabled = false; 
                                this.loadingVisible = false;
                                 notify({
                                     message: data.mensaje ,
                                     position: {
                                         my: "center middle",
                                         at: "center middle"
                                     }, width: 300, 
                                 }, "error", 5000);
                             }    
                         });
   }
  }      
}