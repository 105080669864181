import { Component, ViewChild } from '@angular/core';
import { HttpClient, HttpParams } from "@angular/common/http";
import CustomStore from 'devextreme/data/custom_store';
import config from 'devextreme/core/config';
import notify from 'devextreme/ui/notify';
import { DxDataGridComponent } from 'devextreme-angular';
import { AuthService } from 'src/app/shared/services';
import { environment } from '../../../../environments/environment';
import { AdmProgSupComponent } from '../adms/adm-prog-sup.component';
import { SupervisionService } from 'src/app/shared/services/supervision.service';

config({
  floatingActionButtonConfig: {
      icon: 'rowfield',
      position: {
          of: '#gridProgramaciones',
          my: 'right bottom',
          at: 'right bottom',
          offset: '-16 -16'
      }
  }
}); 

@Component({
  templateUrl: 'programacion-bandeja.component.html',
  styleUrls: ['programacion-bandeja.component.css']
})

export class ProgSupBandejaComponent {
  @ViewChild(DxDataGridComponent, { static: false }) gridProgSupervision: DxDataGridComponent;
  @ViewChild(AdmProgSupComponent, { static: false }) admProgSupervision: AdmProgSupComponent;

  dsProgSupervision: any;
  selectedRowIndex = -1;
  idProgSupervisionSelected = 0;
  popupVisible = false;
  tituloRegistro = "";

  constructor(private httpClient: HttpClient, private authService: AuthService, private supService: SupervisionService) {
    this.repoblarRondasSupervision();
  }

  repoblarRondasSupervision () {
    let idCliente = this.authService.usuarioLogueado.idClienteSeleccionado;
    let loadParams = { idCliente: idCliente}
    this.dsProgSupervision = new CustomStore({
                    key: "idSupervision",
                    load: () => this.sendRequest(environment.APISupervision +  "/admRondaSup/listarRondasByIdCliente", "LOAD", loadParams),
                    remove: (key) => this.sendRequest(environment.APISupervision +  "/admRondaSup/eliminarRondaByIdSupervision", "REMOVE", {pIdSupervision: key})
                  });
  }

  sendRequest(url: string, method: string, data: any = {}): any {

    let result;

    switch(method) {
        case "LOAD":
            url = url + "?pIdCliente=" + data.idCliente; 
            result = this.httpClient.get(url);
            break;
        case "REMOVE":
            url = url + "?pIdSupervision=" + data.pIdSupervision; 
            result = this.httpClient.delete(url);
            break;
    }

    return result
        .toPromise()
        .then((data: any) => {
            if (method === "REMOVE") { 
              if (data.codigo != 'OK') {
                  notify({
                    message: data.mensaje,
                    position: {
                        my: "center center",
                        at: "center center"
                    }, width: 500, 
                  }, "error", 2000);
              }
            } 
            return data;
        })
        .catch(e => {
            throw e && e.error && e.error.Message;
        });
  }

  editRow() {
    let datosRow = this.gridProgSupervision.instance.getSelectedRowsData();
    this.supService.leerSupervisionById(datosRow[0].idSupervision).subscribe(data => {
        this.popupVisible = true;
        this.tituloRegistro = "Modificar Ronda de Supervisión";
        this.admProgSupervision.inicializarControles(data, data.permitirModificar);
    }); 
  }
  deleteRow() {
    this.gridProgSupervision.instance.deleteRow(this.selectedRowIndex);
  }
  addRow() {
    let idCliente = this.authService.usuarioLogueado.idClienteSeleccionado;
    this.supService.leerProximaRonda(idCliente).subscribe(data => {
          if (data.codigo == "OK") {
            this.popupVisible = true;
            this.tituloRegistro = "Nueva Ronda de Supervisión";
            this.admProgSupervision.inicializarControles(data.data, true);
          } else {
            notify({
                message: data.mensaje,
                position: {
                    my: "center center",
                    at: "center center"
                }, width: 500, 
              }, "error", 2000);
          }
    });
  }

  selectedChanged(e) {
    this.selectedRowIndex = e.component.getRowIndexByKey(e.selectedRowKeys[0]);
    this.idProgSupervisionSelected = e.selectedRowKeys[0];
  }
  ocultarPopup() {
    this.popupVisible = false;
  }
  addRondaExcepcional(){
    
  }
}