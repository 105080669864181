import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { catchError } from 'rxjs/operators';
import { of } from 'zen-observable';
import notify from 'devextreme/ui/notify';
import { environment } from '../../../environments/environment';
import { Supervision } from 'src/app/models/Supervision';
import { JerarquiLZ } from './cliente.service';
import { Ticket } from 'src/app/models/Ticket';

@Injectable()
export class SupervisionService {
  
  private httpOptions: any;

  constructor(private httpClient: HttpClient) {
    this.httpOptions = {
      headers: new HttpHeaders({'Content-Type': 'application/json'})
    };
  }
  recuperarEviFalenciaByParms(pIdSupervision : number, pIdLocal: number, pIdZona: number, pIdSubZona: number, pIdEspecialidad: number, pIdUsuario: number) : any{
    let url: string = environment.APISupervision + "/reportes/imagenesFalenciasByParams?pIdSupervision=" + pIdSupervision 
                                                                            + "&pIdLocal=" + pIdLocal
                                                                            + "&pIdZona=" + pIdZona
                                                                            + "&pIdSubZona=" + pIdSubZona
                                                                            + "&pIdEspecialidad=" + pIdEspecialidad
                                                                            + "&pIdUsuario=" + pIdUsuario;
    return this.httpClient.get(url)
                  .pipe(catchError(err => 
                                     { 
                                       console.log(err);
                                       notify({
                                        message: "Se presento un error al conectarse con el servidor! ERROR: " + err.message,
                                        position: {
                                            my: "center bottom",
                                            at: "center bottom"
                                        }, width: 500, 
                                      }, "error", 5000);                                       
                                       return of(null); 
                                     }
                                 )); 
  }
  listarChartSubZonaEspecialidad(pIdLocalSupervision: number, pIdCliente: number, pFiltro: string) : any{
    let url: string = environment.APISupervision + "/evaluacion/listarChartSubZonaEspecialidad?pIdLocalSupervision=" + pIdLocalSupervision
                                                 + "&pIdCliente=" + pIdCliente
                                                 + "&pFiltro=" + pFiltro;
    return this.httpClient.get(url)
                  .pipe(catchError(err => 
                                     { 
                                       console.log(err);
                                       notify({
                                        message: "Se presento un error al conectarse con el servidor! ERROR: " + err.message,
                                        position: {
                                            my: "center bottom",
                                            at: "center bottom"
                                        }, width: 500, 
                                      }, "error", 5000);                                       
                                       return of(null); 
                                     }
                                 )); 
  }
  listarChartZonaEspecialidad(pIdLocalSupervision: number, pIdCliente: number, pFiltro: string): any {
    let url: string = environment.APISupervision + "/evaluacion/listarChartZonaEspecialidad?pIdLocalSupervision=" + pIdLocalSupervision
                                                 + "&pIdCliente=" + pIdCliente
                                                 + "&pFiltro=" + pFiltro;
    return this.httpClient.get(url)
                  .pipe(catchError(err => 
                                     { 
                                       console.log(err);
                                       notify({
                                        message: "Se presento un error al conectarse con el servidor! ERROR: " + err.message,
                                        position: {
                                            my: "center bottom",
                                            at: "center bottom"
                                        }, width: 500, 
                                      }, "error", 5000);                                       
                                       return of(null); 
                                     }
                                 )); 
  }
  listarChartLSupEspecialidad(pIdLocalSupervision: number, pIdCliente: number, pFiltro: string) : any{
    let url: string = environment.APISupervision + "/evaluacion/listarChartLSupEspecialidad?pIdLocalSupervision=" + pIdLocalSupervision
                                                 + "&pIdCliente=" + pIdCliente
                                                 + "&pFiltro=" + pFiltro;
    return this.httpClient.get(url)
                  .pipe(catchError(err => 
                                     { 
                                       console.log(err);
                                       notify({
                                        message: "Se presento un error al conectarse con el servidor! ERROR: " + err.message,
                                        position: {
                                            my: "center bottom",
                                            at: "center bottom"
                                        }, width: 500, 
                                      }, "error", 5000);                                       
                                       return of(null); 
                                     }
                                 )); 
  }
  listarChartFalenciasLSup(pIdLocalSupervision: number, pIdCliente: number) : any{
    let url: string = environment.APISupervision + "/evaluacion/listarChartFalenciasLSup?pIdLocalSupervision=" + pIdLocalSupervision
                                                 + "&pIdCliente=" + pIdCliente;
    return this.httpClient.get(url)
                  .pipe(catchError(err => 
                                     { 
                                       console.log(err);
                                       notify({
                                        message: "Se presento un error al conectarse con el servidor! ERROR: " + err.message,
                                        position: {
                                            my: "center bottom",
                                            at: "center bottom"
                                        }, width: 500, 
                                      }, "error", 5000);                                       
                                       return of(null); 
                                     }
                                 )); 
  }
  leerLocalSupervisionById(pIdLocalSupervision: number): any {
    let url: string = environment.APISupervision + "/evaluacion/leerLocalSupervision?pIdLocalSupervision=" + pIdLocalSupervision;
    return this.httpClient.get(url)
                  .pipe(catchError(err => 
                                     { 
                                       console.log(err);
                                       notify({
                                        message: "Se presento un error al conectarse con el servidor! ERROR: " + err.message,
                                        position: {
                                            my: "center bottom",
                                            at: "center bottom"
                                        }, width: 500, 
                                      }, "error", 5000);                                       
                                       return of(null); 
                                     }
                                 )); 
  }
  listarRondasVigentes(pIdCliente: number): any {
    let url: string = environment.APISupervision + "/evaluacion/listarRondasVigentes?pIdCliente=" + pIdCliente;
     return this.httpClient.get(url)
                   .pipe(catchError(err => 
                                      { 
                                        console.log(err);
                                        notify({
                                          message: "Se presento un error al conectarse con el servidor! ERROR: " + err.message,
                                          position: {
                                              my: "center bottom",
                                              at: "center bottom"
                                          }, width: 500, 
                                        }, "error", 5000);                                        
                                        return of(null); 
                                      }
                                  )); 
  }
  listarRondasCerradas(pIdCliente: number) : any{
    let url: string = environment.APISupervision + "/evaluacion/listarRondasCerradas?pIdCliente=" + pIdCliente;
    return this.httpClient.get(url)
                    .pipe(catchError(err => 
                                        { 
                                          console.log(err);
                                          notify({
                                            message: "Se presento un error al conectarse con el servidor! ERROR: " + err.message,
                                            position: {
                                                my: "center bottom",
                                                at: "center bottom"
                                            }, width: 500, 
                                          }, "error", 5000);                                        
                                          return of(null); 
                                        }
                                    )); 
  }  
  leerProximaRonda(pIdCliente: number) : any{
    let url: string = environment.APISupervision + "/admRondaSup/leerProxRondaByIdCliente?pIdCliente=" + pIdCliente;
     return this.httpClient.get(url)
                   .pipe(catchError(err => 
                                      { 
                                        console.log(err);
                                        notify({
                                          message: "Se presento un error al conectarse con el servidor! ERROR: " + err.message,
                                          position: {
                                              my: "center bottom",
                                              at: "center bottom"
                                          }, width: 500, 
                                        }, "error", 5000);                                        
                                        return of(null); 
                                      }
                                  )); 
  }
  registrarRondaSupervision (supervision: Supervision) : any{

     let url: string = environment.APISupervision + "/admRondaSup/registrarRondaByIdCliente";
     return this.httpClient.post(url, supervision, this.httpOptions)
                    .pipe(catchError(err => 
                                       { 
                                         console.log("ERROR NO CONTROLADO: " + err.message);
                                         notify({
                                           message: "Se presento un error al conectarse con el servidor! ERROR: " + err.message,
                                           position: {
                                               my: "center bottom",
                                               at: "center bottom"
                                           }, width: 500, 
                                         }, "error", 5000);
                                         return of(null); 
                                       }
                                   ));  
  }
  leerSupervisionById(pIdSupervision: number) : any{
    let urlLeerSupervision: string = environment.APISupervision + "/admRondaSup/leerSupervisionById?pIdSupervision=" + pIdSupervision;
    return this.httpClient.get(urlLeerSupervision)
                  .pipe(catchError(err => 
                                     { 
                                       console.log(err);
                                       notify({
                                        message: "Se presento un error al conectarse con el servidor! ERROR: " + err.message,
                                        position: {
                                            my: "center bottom",
                                            at: "center bottom"
                                        }, width: 500, 
                                      }, "error", 5000);                                       
                                       return of(null); 
                                     }
                                 )); 
  }
  listarJerarquiaEvaluacionByLocal(pIdFiltro: string, pTipo: string, pSoloObservados: string): any {
    let url: string = environment.APISupervision + "/evaluacion/obtenerArbolJerarquia?pIdFiltro=" 
                                                 + pIdFiltro 
                                                 + "&pTipo=" + pTipo
                                                 + "&pSoloObservados=" + pSoloObservados;
    return this.httpClient.get(url).pipe(catchError(err => 
                   { 
                      console.log(err);
                      notify({
                             message: "Se presento un error al conectarse con el servidor! ERROR: " + err.message,
                             position: {
                                          my: "center bottom",
                                          at: "center bottom"
                                       }, width: 500, 
                             }, "error", 5000);                                        
                      return of(null); 
                    }));          
  }    
  listarImagenesFSZ(pIdFalenciaSup: number) : any{
    let urlLeerSupervision: string = environment.APISupervision + "/evaluacion/listarImagenesByIdFalSZ?pIdFalenciaSupervision=" + pIdFalenciaSup;
    return this.httpClient.get(urlLeerSupervision)
                  .pipe(catchError(err => 
                                     { 
                                       console.log(err);
                                       notify({
                                        message: "Se presento un error al conectarse con el servidor! ERROR: " + err.message,
                                        position: {
                                            my: "center bottom",
                                            at: "center bottom"
                                        }, width: 500, 
                                      }, "error", 5000);                                       
                                       return of(null); 
                                     }
                                 )); 
  }  
  listarImagenesFActSZ(pIdFalenciaSup: number) : any{
    let urlLeerSupervision: string = environment.APISupervision + "/evaluacion/listarImagenesByIdFalActSZ?pIdFalenciaSupervision=" + pIdFalenciaSup;
    return this.httpClient.get(urlLeerSupervision)
                  .pipe(catchError(err => 
                                     { 
                                       console.log(err);
                                       notify({
                                        message: "Se presento un error al conectarse con el servidor! ERROR: " + err.message,
                                        position: {
                                            my: "center bottom",
                                            at: "center bottom"
                                        }, width: 500, 
                                      }, "error", 5000);                                       
                                       return of(null); 
                                     }
                                 )); 
  }  
  registrarImagenesFalencia (ticket: Ticket): any {

    let url: string = environment.APISupervision + "/evaluacion/registrarImagenesFalencia";
    return this.httpClient.post(url, ticket, this.httpOptions)
                   .pipe(catchError(err => 
                                      { 
                                        console.log("ERROR NO CONTROLADO: " + err.message);
                                        notify({
                                          message: "Se presento un error al conectarse con el servidor! ERROR: " + err.message,
                                          position: {
                                              my: "center middle",
                                              at: "center middle"
                                          }, width: 500, 
                                        }, "error", 5000);
                                        return of(null); 
                                      }
                                  ));  
  }  
}