import { Component, ViewChild } from '@angular/core';
import { HttpClient, HttpParams } from "@angular/common/http";
import CustomStore from 'devextreme/data/custom_store';
import config from 'devextreme/core/config';
import notify from 'devextreme/ui/notify';
import * as AspNetData from "devextreme-aspnet-data-nojquery";
import { DxDataGridComponent, DxFormComponent } from 'devextreme-angular';
import { AuthService } from 'src/app/shared/services';
import { TicketService } from '../../../shared/services/ticket.service';
import { ImagenTicket } from '../../../models/ImagenTicket';
import { Proveedor } from 'src/app/models/Proveedor';
import { AtencionService } from 'src/app/shared/services/atencion.service';
import { environment } from 'src/environments/environment';
import { Atencion } from 'src/app/models/Atencion';

config({
  floatingActionButtonConfig: {
      icon: 'rowfield',
      position: {
          of: '#gridAtenciones',
          my: 'right bottom',
          at: 'right bottom',
          offset: '-16 -16'
      }
  }
}); 

@Component({
  templateUrl: 'aprobar-cotizacion-bandeja.component.html',
  styleUrls: ['aprobar-cotizacion-bandeja.component.css']
})

export class AprobarCotizacionBandejaComponent {
  @ViewChild(DxDataGridComponent, { static: false }) gridAtenciones: DxDataGridComponent;
  @ViewChild('formCotizacion', { static: true }) formCotizacion: DxFormComponent; 
  @ViewChild('formExtender', { static: true }) formExtender: DxFormComponent; 
  dsAtencion: any;
  dsImagenesTck: ImagenTicket[];
  cotProveedor = new Proveedor();
  dataCotizacionSelected: any;
  idAtencionSelected = -1;
  dataAtencionSelected: any;
  selectedRowIndex = -1;
  idCotizacionSelected = -1;
  popupAprobarVisible = false;
  tituloAprobarCotizacion = "";
  cotizacionesDataMap: object = {};
  urlDetalleTicket = "";
  popupExtenderVisible = false;
  tituloExtenderPlazo = "";
  atencion = new Atencion();
  urlSrvArchivos = "";
  botonAprCotDisabled = false;
  botonExtPlzDisabled = false;
  loadingVisible = false;

  constructor(private httpClient: HttpClient, private authService: AuthService, private tckService: TicketService, 
                                              private ateService: AtencionService) {
    this.urlDetalleTicket = environment.APITicketCore + "/consultas/listaCotByAtencion";
    this.repoblar();
    this.urlSrvArchivos = environment.EvidenciaMINTTO + "/";
  }

  repoblar() {
    this.idAtencionSelected = -1;
    this.selectedRowIndex = -1;
    this.dataAtencionSelected = null;   

    let idUsuario = this.authService.usuarioLogueado.idUsuario;
    let idCliente = this.authService.usuarioLogueado.idClienteSeleccionado;
    let loadParams = { idCliente: idCliente,  idUsuario: idUsuario}
    this.dsAtencion = new CustomStore({
                    key: "idAtencion",
                    load: () => this.sendRequest(environment.APITicketCore + "/consultas/listaAtencionesAproProv", "LOAD", loadParams)
                  });

    if (this.gridAtenciones) {
       this.gridAtenciones.instance.clearSelection();
    }                  
  }

  sendRequest(url: string, method: string, data: any = {}): any {

    let result;

    switch(method) {
        case "LOAD":
            url = url + "?idCliente=" + data.idCliente + "&idUsuario=" + data.idUsuario; 
            result = this.httpClient.get(url);
            break;
        case "REMOVE":
            url = url + "?pIdTicket=" + data.pIdTicket; 
            result = this.httpClient.delete(url);
            break;
    }

    return result
        .toPromise()
        .then((data: any) => {
            if (method === "REMOVE") { 
              if (data.codigo != 'OK') {
                  notify({
                    message: data.mensaje,
                    position: {
                        my: "center center",
                        at: "center center"
                    }, width: 500, 
                  }, "error", 2000);
              }
            } 
            return data;
        })
        .catch(e => {
            throw e && e.error && e.error.Message;
        });
  }
  obtenerCotizaciones(idAtencion: number) : any {
    return this.cotizacionesDataMap[idAtencion] = this.cotizacionesDataMap[idAtencion] || {
      store: AspNetData.createStore({
          key: "idProveedor",
          loadParams: { pIdAtencion: idAtencion },
          loadUrl: this.urlDetalleTicket
      })
    };
  }

  selectedChanged(e) {
    this.selectedRowIndex = e.component.getRowIndexByKey(e.selectedRowKeys[0]);
    this.idAtencionSelected = e.selectedRowKeys[0];
    this.dataAtencionSelected = e.selectedRowsData[0];
  }
  clickCotizacion(evt) {
    this.idCotizacionSelected = evt.data.idProveedorAtencion;
    this.dataCotizacionSelected = evt.data;
  }
  expandedClick(){
    this.idCotizacionSelected = -1;
  }  
  aprobarCotizacion(){
    if (this.dataCotizacionSelected.cotizacionEnviada) {
      this.popupAprobarVisible = true;
      this.tituloAprobarCotizacion = "Aprobar Cotización para la Atención: " + this.dataCotizacionSelected.idAtencion;

      this.formCotizacion.instance.resetValues();
      
      this.formCotizacion.instance.getEditor("DescripcionMoneda").option('disabled', true);
      this.formCotizacion.instance.getEditor("PrecioTotal").option('disabled', true);
      this.formCotizacion.instance.getEditor("PlazoEntrega").option('disabled', true);
      this.formCotizacion.instance.getEditor("ComentariosProveedor").option('disabled', true);
      this.formCotizacion.instance.getEditor("DescripcionProveedor").option('disabled', true);
      this.formCotizacion.instance.getEditor("NombreArchivoCotizacion").option('disabled', true);

      this.cotProveedor = new Proveedor();
      this.cotProveedor.IdProveedorAtencion = this.dataCotizacionSelected.idProveedorAtencion;
      this.cotProveedor.DescripcionProveedor = this.dataCotizacionSelected.descripcionProveedor; 
      this.cotProveedor.DescripcionMoneda = this.dataCotizacionSelected.descripcionMoneda; 
      this.cotProveedor.PrecioTotal = this.dataCotizacionSelected.precioTotal; 
      this.cotProveedor.PlazoEntrega = this.dataCotizacionSelected.plazoEntrega; 
      this.cotProveedor.NombreArchivoCotizacion = this.dataCotizacionSelected.nombreArchivoCotizacion; 
      this.cotProveedor.ComentariosProveedor = this.dataCotizacionSelected.comentariosProveedor; 

      this.formCotizacion.instance.option("formData", this.cotProveedor);

      //COLOCO EL FOCO EN EL PRIMER CAMPO
      let that = this;
      setTimeout(function() {
          that.formCotizacion.instance.getEditor("ComentariosAprobador").focus();
      }, 500); 
    } else {
        notify({
          message: "El proveedor no ha registrado su cotización!",
          position: {
              my: "center middle",
              at: "center middle"
          }, width: 300, 
      }, "error", 5000);      
    }
  }
  ocultarPopupCotizacionClick() {
    this.popupAprobarVisible = false;
  }
  registrarAprobacionCotizacion() {
    if (this.formCotizacion.instance.validate().isValid) {
       this.botonAprCotDisabled = true;
       this.loadingVisible = true;

       this.cotProveedor = this.formCotizacion.instance.option("formData");
       this.cotProveedor.IdUsuarioCreacion = this.authService.usuarioLogueado.idUsuario;

      this.ateService.aprobarCotizacion(this.cotProveedor).subscribe(data => {
                              if (data.codigo == "OK") {
                                  this.botonAprCotDisabled = false;
                                  this.loadingVisible = false;
                         
                                  notify({
                                    message: "Grabación Correcta!" ,
                                    position: {
                                    my: "center middle",
                                    at: "center middle"
                                    }, width: 300, 
                                  }, "success", 1500);  
                                  this.repoblar();
                                  this.ocultarPopupCotizacionClick();                                            
                              } else {
                                  this.botonAprCotDisabled = false;
                                  this.loadingVisible = false;
                         
                                  notify({
                                      message: data.mensaje ,
                                      position: {
                                          my: "center middle",
                                          at: "center middle"
                                      }, width: 300, 
                                  }, "error", 5000);
                              }    
                          });
    }
  }  
  ocultarPopupExtenderClick() {
    this.popupExtenderVisible = false;
  }
  extenderPlazo () {
    this.popupExtenderVisible = true;
    this.tituloExtenderPlazo = "Extender Plazo Atención: " + this.dataAtencionSelected.idAtencion;
    
    this.formExtender.instance.resetValues();

    this.atencion = new Atencion(); 
    this.atencion.IdAtencion = this.dataAtencionSelected.idAtencion;
    this.atencion.DescripcionAtencion = this.dataAtencionSelected.descripcionAtencion;
    this.atencion.FechaRegistro = this.dataAtencionSelected.fechaCreacion;
    this.atencion.PlazoEntregaCotizacion = this.dataAtencionSelected.plazoEntregaCotizacion;

    this.formExtender.instance.option("formData", this.atencion);

    //COLOCO EL FOCO EN EL PRIMER CAMPO
    let that = this;
    setTimeout(function() {
               that.formExtender.instance.getEditor("PlazoEntregaAdicional").focus();
                }, 500); 
  }  
  actualizarExtension(){
    if (this.formExtender.instance.validate().isValid) {

      this.atencion = this.formExtender.instance.option("formData");
      this.botonExtPlzDisabled = true;
      this.loadingVisible = true;
      let atencionEnviar = new Atencion(); 
      atencionEnviar.IdAtencion = this.dataAtencionSelected.idAtencion;
      atencionEnviar.PlazoEntregaCotizacion = this.atencion.PlazoEntregaAdicional;
      atencionEnviar.IdUsuarioCreacion = this.authService.usuarioLogueado.idUsuario;

      this.ateService.extenderPlazoCotizacion(atencionEnviar).subscribe(data => {
                             if (data.codigo == "OK") {
                                this.botonExtPlzDisabled = false;
                                this.loadingVisible = false;
                        
                                 notify({
                                   message: "Grabación Correcta!" ,
                                   position: {
                                   my: "center middle",
                                   at: "center middle"
                                   }, width: 300, 
                                 }, "success", 1500);  
                                 this.repoblar();
                                 this.ocultarPopupExtenderClick();     
                             } else {
                                this.botonExtPlzDisabled = false;
                                this.loadingVisible = false;                               
                                 notify({
                                     message: data.mensaje ,
                                     position: {
                                         my: "center middle",
                                         at: "center middle"
                                     }, width: 300, 
                                 }, "error", 5000);
                             }    
                         });
   }
  }
}