import { Component, NgModule, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Router } from '@angular/router';

import { AuthService, AppInfoService } from '../../services';
import { DxButtonModule, DxButtonComponent } from 'devextreme-angular/ui/button';
import { DxCheckBoxModule } from 'devextreme-angular/ui/check-box';
import { DxTextBoxModule } from 'devextreme-angular/ui/text-box';
import { DxValidatorModule } from 'devextreme-angular/ui/validator';
import { DxValidationGroupModule } from 'devextreme-angular/ui/validation-group';
import { DxTextBoxComponent } from 'devextreme-angular';
import { DxSelectBoxComponent, DxSelectBoxModule, DxLoadPanelModule } from 'devextreme-angular';
import notify from 'devextreme/ui/notify';

@Component({
  selector: 'app-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss']
})

export class LoginFormComponent {
  usuario = '';
  clave = ''; 
  clientesVisible = false;
  clientes: any;
  loadingVisible = false;

  @ViewChild('txtUsuario', { static: false }) txtUsuario: DxTextBoxComponent;
  @ViewChild('txtClave', { static: false }) txtClave: DxTextBoxComponent;
  @ViewChild('cboClientes', { static: false }) cboClientes: DxSelectBoxComponent;
  
  constructor(private authService: AuthService, public appInfo: AppInfoService, private router: Router) {
    let that = this;
    setTimeout(function() {that.txtUsuario.instance.focus();}, 500);

    document.addEventListener('keydown', this.keyboardDown);
  }

  public keyboardDown = (event: KeyboardEvent): void => {
    if (event.key == 'Enter') {
      let btn = document.getElementById("btnIngresar");
      setTimeout(function() {
        if (btn != null) {
          btn.focus();
          btn.click();
        }
      }, 500);
    }
  }
  
  onLoginClick(args) {
    if (!args.validationGroup.validate().isValid) {
      if (this.usuario == "") {
        let that = this;
        setTimeout(function() {that.txtUsuario.instance.focus();}, 500);
      } else {
        let that = this;
        setTimeout(function() {that.txtClave.instance.focus();}, 500);
      }
      notify({
          message: "Debe completar los datos para poder ingresar!",
          position: {
              my: "center bottom",
              at: "center bottom"
          }, width: 300, 
        }, "error", 3000);
      
      return;
    }

    //MUESTRO EL PANEL
    this.loadingVisible = true;

    if (this.cboClientes) {
       let clienteSeleccionado = this.cboClientes.instance.option('value');
       let data = this.authService.usuarioLogueado;
       data.idClienteSeleccionado = clienteSeleccionado;
       data.clientesAsignados.forEach(element => {
           if (element.cliente.idCliente == clienteSeleccionado) {
             data.pathURLLogoCliente = element.cliente.pathURLLogo;
           }
       });
       args.validationGroup.reset();
       this.authService.registrarSessionUsuario(data);
       this.loadingVisible = false;
       this.router.navigate(['/']);
    } else {
      this.authService.logIn(this.usuario, this.clave)
                      .subscribe(data => {
                              this.loadingVisible = false;
                              if (data.codigo == "OK") {
                                if (data.data.clientesAsignados.length > 1) {
                                    this.txtUsuario.instance.option('disabled', true);
                                    this.txtClave.instance.option('disabled', true);
                                    this.clientesVisible = true;
                                    this.clientes = data.data.clientesAsignados;
                                    this.authService.registrarSessionTemporal(data.data);  
                                } else {
                                  args.validationGroup.reset();
                                  this.authService.registrarSessionUsuario(data.data);
                                  this.router.navigate(['/']);
                                }
                              } else {
                                this.loadingVisible = false;
                                notify({
                                  message: data.mensaje ,
                                  position: {
                                      my: "center bottom",
                                      at: "center bottom"
                                  }, width: 300, 
                                }, "error", 3000);
                              }
                    });
    }
  }
}

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    DxButtonModule,
    DxCheckBoxModule,
    DxTextBoxModule,
    DxValidatorModule,
    DxSelectBoxModule,
    DxLoadPanelModule,
    DxValidationGroupModule
  ],
  declarations: [ LoginFormComponent ],
  exports: [ LoginFormComponent ]
})
export class LoginFormModule { }