// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
 
  /* PRODUCCION: */
  production: true,
  APISeguridad: 'http://54.174.148.16:6001', 
  APIAdministracion: 'http://54.174.148.16:6002', 
  APITicketCore: 'http://54.174.148.16:6003',
  EvidenciaMINTTO: 'http://54.174.148.16:6004',
  APICargaArchivo: 'http://54.174.148.16:6005',
  APISupervision: 'http://54.174.148.16:6006', 
  urlUpLoad: '/upload/agregarArchivo' 

  /*  TEST:   */
  /*
  production: false, 
  APISeguridad: 'http://3.132.243.170:9001', 
  APIAdministracion: 'http://3.132.243.170:9002', 
  APITicketCore: 'http://3.132.243.170:9003',
  EvidenciaMINTTO: 'http://3.132.243.170:9004',
  APICargaArchivo: 'http://3.132.243.170:9005',
  APISupervision: 'http://3.132.243.170:9006', 
  urlUpLoad: '/upload/agregarArchivo' 
  */
};

 /*
  APISeguridad: 'http://localhost:54106', 
  APIAdministracion: 'http://localhost:14519', 
  APITicketCore: 'http://localhost:50599',
  EvidenciaMINTTO: 'http://3.132.243.170:9004',
  //APICargaArchivo: 'http://localhost:31933',
  APISupervision: 'http://localhost:6979', 
  urlUpLoad: '/upload/agregarArchivo' ,
  APICargaArchivo: 'http://3.132.243.170:9005'
 */

/*
PARA: 
  APICargaArchivo: 
  Agregar Archivos: upload/agregarArchivo
  APISupervision: 'http://localhost:9006' / 6979
  APITicketCore: 'http://localhost:9003' / 50599
  APISeguridad: 'http://localhost:9001'  / 54106
  APIAdministracion: 'http://localhost:9002' / 14519
*/

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
