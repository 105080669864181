import { Component, ViewChild } from '@angular/core';
import { HttpClient, HttpParams } from "@angular/common/http";
import CustomStore from 'devextreme/data/custom_store';
import { Router} from '@angular/router';
import config from 'devextreme/core/config';
import notify from 'devextreme/ui/notify';
import { DxDataGridComponent, DxFormComponent } from 'devextreme-angular';
import { AuthService } from 'src/app/shared/services';
import { environment } from '../../../../environments/environment';
import { SupervisionService } from 'src/app/shared/services/supervision.service';
import { ParametricasService } from 'src/app/shared/services/parametricas.service';
import { FiltroGeneral } from 'src/app/models/filtroGeneral';
import ArrayStore from 'devextreme/data/array_store';
import { TicketService } from 'src/app/shared/services/ticket.service';
import { exportDataGrid } from 'devextreme/exporter/exceljs/excelExporter';
import {Row, Workbook, Worksheet} from 'exceljs';
import saveAs from 'file-saver';
import { ImagenTicket } from 'src/app/models/ImagenTicket';

config({
  floatingActionButtonConfig: {
      icon: 'rowfield',
      position: {
          of: '#gridTickets',
          my: 'right bottom',
          at: 'right bottom',
          offset: '-16 -16'
      }
  }
}); 

@Component({
  templateUrl: 'cons-detalle-sup.component.html',
  styleUrls: ['cons-detalle-sup.component.css']
})

export class ConsDetalleSupComponent {
  @ViewChild('gridFalencias', { static: false }) gridFalencias: DxDataGridComponent;
  @ViewChild('gridFalenciasExportar', { static: false }) gridFalenciasExportar: DxDataGridComponent;
  @ViewChild('formFiltro', { static: false }) formFiltro: DxFormComponent;
  
  filtroEvaluacion: FiltroGeneral;
  dsFalencias: any;
  locales:any; zonas:any; subZonas:any; especialidades:any; rondas:any; zonasGeograficas:any;
  existenRegistros = false;
  tituloVerImagenes = "";
  popupImagenesVisible = false;
  dsImagenesFalencia: ImagenTicket[];
  imagenInicial = 0;
  idFalenciaSelected = 0;
  idLocalSupSelected = 0;
  dataFalenciaSelected: any;
  visibleGridExportar = false;
  visibleGrid = true;
  loadingVisible = false;
  NoseleccionoZG = true;
  NoseleccionoLocal = true;

  constructor(private httpClient: HttpClient, private authService: AuthService, 
                      private supService: SupervisionService, private router: Router, private paramService: ParametricasService, 
                      private tckService: TicketService) {

    this.filtroEvaluacion = new FiltroGeneral();
    let idCliente = this.authService.usuarioLogueado.idClienteSeleccionado;

    this.cambioZG = this.cambioZG.bind(this);
    this.cambioLocal = this.cambioLocal.bind(this);
    this.cambioZona = this.cambioZona.bind(this);

    this.tckService.listarEspecialidades(idCliente, true).subscribe(data => {
        this.especialidades = new ArrayStore({data: data, key: "idEspecialidad"}); 
    }); 

    this.supService.listarRondasCerradas(idCliente).subscribe(data => {
      this.rondas = new ArrayStore({data: data,key: "idSupervision"}); 
      if (data.length > 0) {
          this.filtroEvaluacion.IdSupervision = data[0].idSupervision;
      }
    });

    this.paramService.listarZonasGeograficas(true).toPromise().then(data => {
        this.zonasGeograficas = new ArrayStore({
                          data: data,
                          key: "idZonaGeografica"
                      }); 
    });

    this.filtroEvaluacion.IdLocal = -1;
    this.filtroEvaluacion.IdZona = -1;
    this.filtroEvaluacion.IdSubZona = -1;
    this.filtroEvaluacion.IdEspecialidad = -1;
    this.filtroEvaluacion.IdZG = -1;

    //COLOCO EL FOCO EN EL PRIMER CAMPO
    let that = this;
    setTimeout(function() {
      that.formFiltro.instance.getEditor("IdSupervision").focus();
    }, 500);

  }
  cambioZG(evt) {
    this.dsFalencias = null;
    this.existenRegistros = false;
    let idUsuario = this.authService.usuarioLogueado.idUsuario;
    let idCliente = this.authService.usuarioLogueado.idClienteSeleccionado;

    if (evt.selectedItem) {
      let idZG = evt.selectedItem.idZonaGeografica;
      if (idZG === -1) {
        this.NoseleccionoZG = true;
      } else {
        this.NoseleccionoZG = false;
      }
      this.tckService.listarLocByCliUsuarioZG(idUsuario, idCliente, true, idZG).subscribe(data => {
            this.locales = new ArrayStore({data: data, key: "idLocal"}); 
            let idLocal = -1;
            if (data.length == 0) {
                notify({
                        message: "El usuario no tienen locales asignados!",
                        position: {
                            my: "center center",
                            at: "center center"
                        }, width: 500, 
                    }, "error", 2000);                                            
            } else {
              idLocal = data[0].idLocal;
            }

            //CADA VEZ QUE SE CARGA SE DEBE COLOCAR EN EL PRIMER REGISTRO
            this.filtroEvaluacion.IdLocal = -1;
            
            //CARGO LOS OTROS COMBOS 
            this.tckService.listarZonasByLocal(idLocal, true).toPromise().then((data: any) => {
                this.zonas = new ArrayStore({data: data, key: "idZona" }); 
                this.filtroEvaluacion.IdZona = -1;
                
                //CARGO LAS SUBZONAS VACIAS SOLO CON LA OPCION TODOS
                this.tckService.listarSubZonasByZona(-1, true).toPromise().then(data => {
                    this.subZonas = new ArrayStore({data: data,key: "idSubZona"}); 
                    this.filtroEvaluacion.IdSubZona = -1;            
                });
            });             
        });      
    }
  }
  cambioLocal(evt) {
    this.dsFalencias = null;
    this.existenRegistros = false;

    if (evt.selectedItem) {
       let idLocal = evt.selectedItem.idLocal;
       if (idLocal === -1) {
          this.NoseleccionoLocal = true;
          this.visibleGridExportar = false;
          this.visibleGrid = true;
       } else {
         this.NoseleccionoLocal = false;
         this.visibleGridExportar = true;
         this.visibleGrid = false;
       }
       this.tckService.listarZonasByLocal(idLocal, true).toPromise().then((data: any) => {
            this.zonas = new ArrayStore({data: data, key: "idZona" }); 
            this.filtroEvaluacion.IdZona = -1;
            
            //CARGO LAS SUBZONAS VACIAS SOLO CON LA OPCION TODOS
            this.tckService.listarSubZonasByZona(-1, true).toPromise().then(data => {
                this.subZonas = new ArrayStore({data: data,key: "idSubZona"}); 
                this.filtroEvaluacion.IdSubZona = -1;            
            });
       }); 
    }
  }
  cambioZona(evt) {
    if (evt.selectedItem) {
        let idZona = evt.selectedItem.idZona;
        this.tckService.listarSubZonasByZona(idZona, true).toPromise().then(data => {
            this.subZonas = new ArrayStore({data: data,key: "idSubZona"}); 
            this.filtroEvaluacion.IdSubZona = -1;            
        });
    }
  }  
  repoblar() {
    if (this.formFiltro.instance.validate().isValid) {
      let idUsuario = this.authService.usuarioLogueado.idUsuario;
      this.filtroEvaluacion = this.formFiltro.instance.option("formData");
      let loadParams = { idSupervision: this.filtroEvaluacion.IdSupervision, idLocal: this.filtroEvaluacion.IdLocal, 
                                               idZona: this.filtroEvaluacion.IdZona,
                                               idSubZona: this.filtroEvaluacion.IdSubZona,
                                               idEspecialidad: this.filtroEvaluacion.IdEspecialidad, 
                                               idUsuario: idUsuario };
      this.dsFalencias = new CustomStore({
                                    key: "idFalenciaSupervision",
                                    load: () => this.sendRequest(environment.APISupervision + "/reportes/listarDetalleSupervision", "LOAD", loadParams)
                                  });
    }
  }
  sendRequest(url: string, method: string, data: any = {}): any {

    let result;
    
    switch(method) {
        case "LOAD":
            url = url + "?pIdSupervision=" + data.idSupervision 
                      + "&pIdLocal=" + data.idLocal 
                      + "&pIdZona=" + data.idZona 
                      + "&pIdSubZona=" + data.idSubZona 
                      + "&pIdEspecialidad=" + data.idEspecialidad
                      + "&pIdUsuario=" + data.idUsuario ; 
            result = this.httpClient.get(url);
            break;
    }

    return result
        .toPromise()
        .then((data: any) => {
          if (data.length > 0) {
            this.existenRegistros = true;
            this.idLocalSupSelected = data[0].idLocalSupervision;
          } else  {
            this.existenRegistros = false;
            this.idLocalSupSelected = 0;
          }

          return data;})
        .catch(ex => {
              notify({
                message: ex,
                position: {
                    my: "center center",
                    at: "center center"
                }, width: 500, 
              }, "error", 2000);
        });
  }
  exportarExcel() {
    //MUESTRO EL PANEL
    this.loadingVisible = true;
    this.gridFalenciasExportar.instance.exportToExcel(false);
  }
  async onExporting (evt) {

    //RECUPERO EL LOGO DEL CLIENTE
    let logoFile = await this.tckService.recuperarArchivoEvidencia(this.authService.usuarioLogueado.pathURLLogoCliente).toPromise();

    //RECUPERO LOS DATOS DEL LOCAL 
    let datosLocal = await this.supService.leerLocalSupervisionById(this.idLocalSupSelected).toPromise();

    //RECUPERO LAS IMAGENES DE LA CONSULTA - TODO: MEJORAR PARA VER QUE SE PIDA LAS IMAGENES LINEA POR LINEA 
    this.filtroEvaluacion = this.formFiltro.instance.option("formData");
    let idUsuario = this.authService.usuarioLogueado.idUsuario;
    let imagenes = await this.supService.recuperarEviFalenciaByParms(this.filtroEvaluacion.IdSupervision, this.filtroEvaluacion.IdLocal, 
                                                                 this.filtroEvaluacion.IdZona,
                                                                 this.filtroEvaluacion.IdSubZona,
                                                                 this.filtroEvaluacion.IdEspecialidad, 
                                                                 idUsuario).toPromise();

    const workbook: Workbook = new Workbook();
    let worksheet = workbook.addWorksheet('Falencias');
    let context = this;
    let idFalenciaSupervision = 0; 

    exportDataGrid({
        component: evt.component,
        worksheet: worksheet,
        topLeftCell: { row: 5, column: 1 },
        customizeCell: options => {

          const { gridCell, excelCell } = options;

          if (gridCell.rowType === 'header') {
              Object.assign(excelCell, {font: { bold: true }, alignment: { horizontal: 'center' },
                                        border: { top: {style:'thin'}, left: {style:'thin'}, 
                                                  bottom: {style:'thin'},right: {style:'thin'}}});
              if (gridCell.column.dataField === 'img1' || gridCell.column.dataField === 'img2' || gridCell.column.dataField === 'img3' ) {
                  worksheet.getColumn(excelCell.col).width = 50;
              }                                                                         
          }
          
          if (gridCell.rowType === 'data') {
             Object.assign(excelCell, {border: { top: {style:'thin'}, left: {style:'thin'}, 
                                                bottom: {style:'thin'},right: {style:'thin'}}});

             if (gridCell.column.dataField === 'descripcionFalencia' || gridCell.column.dataField === 'descripcionSolucion') {
                Object.assign(excelCell, {alignment: { vertical: 'top' }});
             } else {
               Object.assign(excelCell, {alignment: { vertical: 'middle' }});
             }

             let idActual = gridCell.data.idFalenciaSupervision; 
             if (idActual != idFalenciaSupervision ) {
                if (gridCell.column.dataField === 'descripcionSolucion') {

                    let fila = excelCell.row;
                    let contador = 1;
                    
                    worksheet.getRow(excelCell.row).height = 150;

                    imagenes.forEach(element => {
                        if (element.fileBase64 != null) {
                          if (idActual === element.idAgrupador) {

                            let imageEvidencia = workbook.addImage({
                              base64: element.fileBase64, 
                              extension: element.extensionArchivo,
                            }); 
                            
                            let posicion = "";
                            if (contador == 1) {
                              posicion = "F" + fila + ":" + "F" + fila;
                              contador = contador + 1;
                            } else if (contador == 2) {
                              posicion = "G" + fila + ":" + "G" + fila;
                              contador = contador + 1;
                            } else if (contador == 3) {
                              posicion = "H" + fila + ":" + "H" + fila;
                              contador = contador + 1;
                            } 

                            //SE COLOCA LA IMAGEN
                            worksheet.addImage(imageEvidencia, posicion);  
                          }                        
                        }  
                    });

                    idFalenciaSupervision = idActual;
                } 
              }
          }
        }
    }).then(function() {
          context.customizeHeader(worksheet, workbook, logoFile, datosLocal);
          return Promise.resolve();
    }).then(function() {
          workbook.xlsx.writeBuffer().then(function(buffer) {
            saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'DetalleSupervision.xlsx');
          });
    });

    this.loadingVisible = false;
    evt.cancel = true;
  
  }
  customizeHeader(worksheet, workbook, imgLogo, datosLocal){

    const generalStyles = {
        font: { bold: true },
        alignment: { horizontal: 'left' }
    };
    
    var imageLogo = workbook.addImage({
        base64: imgLogo.fileBase64,
        extension: imgLogo.extensionArchivo,
    });
  
    //SE COLOCA LA IMAGEN
    worksheet.addImage(imageLogo, 'A1:A4');
      
    for (var rowIndex = 1; rowIndex <= 4; rowIndex++) {
        Object.assign(worksheet.getRow(rowIndex).getCell(2), generalStyles);
    } 
    
    worksheet.getColumn(2).values = [ 'Agencia:', 'Fecha:', 'Dirección:', 'Atendido por:'];

    this.filtroEvaluacion = this.formFiltro.instance.option("formData");
    let descripcionLocal = this.formFiltro.instance.getEditor("IdLocal").option("selectedItem").descripcionLocal;
    let fechaInicio = datosLocal.data.fechaInicioText; 
    let direccion = datosLocal.data.direccion; 
    let encargadoLocal = datosLocal.data.contactoLocal; 
    worksheet.getColumn(3).values = [descripcionLocal, fechaInicio, direccion, encargadoLocal];
  }
  selectedChanged(e) {
    this.idFalenciaSelected = e.selectedRowKeys[0];
    this.dataFalenciaSelected = e.selectedRowsData[0];
    this.dsImagenesFalencia = null;
  }
  verImagenesClick() {

    if (this.idFalenciaSelected == 0) {
      notify({
        message: "Seleccione el registro primero!",
        position: {
            my: "center center",
            at: "center center"
        }, width: 500, 
      }, "error", 2000);
      return;
    }

    this.imagenInicial = 0;
    this.dsImagenesFalencia = null;
    this.tituloVerImagenes = "Imagenes Falencia: " + this.dataFalenciaSelected.descripcionFalencia;

    this.supService.listarImagenesFSZ(this.idFalenciaSelected).toPromise().then(data => {
      let imagenesTotal: ImagenTicket[] = [] ;
      data.forEach(function (value) {
          imagenesTotal.push({'PathURLImagen': environment.EvidenciaMINTTO + "/" + value.pathURLImagen, 'Nombre' : value.nombre, 'Tipo' : '' });
      });   
      this.dsImagenesFalencia = imagenesTotal;
      this.popupImagenesVisible = true;
    });  
  }  
}