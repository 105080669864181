import { Component, ViewChild } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { AuthService } from 'src/app/shared/services';
import { TicketService } from '../../../shared/services/ticket.service';

@Component({
  templateUrl: 'proveedores-bandeja.component.html',
  styleUrls: ['proveedores-bandeja.component.css']
})

export class ProveedoresBandejaComponent {
  constructor(private httpClient: HttpClient, private authService: AuthService, private tckService: TicketService) {
  }

  customizeTooltip(arg: any) {
    console.log('recibido!');
    return {
        text: "Registro: 28/10/2019 \n Usuario: LUIS.DEXTRE \n Avance: 20%"
    };
  }
}