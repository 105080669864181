import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';  
import { BrowserModule } from '@angular/platform-browser';
import { Routes, RouterModule } from '@angular/router';

import { DxDataGridModule, 
         DxSpeedDialActionModule, DxPopupModule,  
         DxTemplateModule, DxButtonModule, DxTextBoxModule, DxTextAreaModule, 
         DxSelectBoxModule, DxFileUploaderModule, DxGalleryModule, 
         DxTabPanelModule, DevExtremeModule, DxTreeViewModule, DxTileViewModule, DxLinearGaugeModule, 
         DxTooltipModule } from 'devextreme-angular';
import { DxValidatorModule } from 'devextreme-angular/ui/validator';
import { DxValidationGroupModule } from 'devextreme-angular/ui/validation-group';

import { LoginFormComponent } from './shared/components';
import { HomeComponent } from './pages/home/home.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { AuthGuardService } from './shared/services';

import { MisTicketsBandejaComponent } from './pages/tickets/bandejas/mis-tickets-bandeja.component';
import { AtenderTicketsBandejaComponent} from '../app/pages/tickets/bandejas/atender-tickets-bandeja.component';
import { GestionarAtencionesBandejaComponent } from './pages/tickets/bandejas/gestionar-atencion-bandeja.component';
import { RegCotiProvBandejaComponent } from './pages/tickets/bandejas/reg-coti-prov-bandeja.component';
import { AprobarCotizacionBandejaComponent} from './pages/tickets/bandejas/aprobar-cotizacion-bandeja.component';
import { RegistrarEjecucionBandejaComponent} from './pages/tickets/bandejas/reg-eje-bandeja.component';
import { ProgramarEjecucionBandejaComponent } from './pages/tickets/bandejas/prog-ejec-bandeja.component';
import { AprobarAsignacionBandejaComponent } from './pages/tickets/bandejas/aprob-asig-bandeja.component';
import { ClientesBandejaComponent } from './pages/administracion/bandejas/clientes-bandeja.component';
import { ProgSupBandejaComponent } from './pages/supervision/bandejas/programacion-bandeja.component';

import { AdmTicketComponent } from './pages/tickets/adms/adm-ticket.component';
import { AdmClienteComponent } from './pages/administracion/adms/adm-cliente.component';
import { AdmCliLocalComponent } from './pages/administracion/adms/adm-cli-local.component';
import { AdmProgSupComponent } from './pages/supervision/adms/adm-prog-sup.component';
import { AdmEvaluacionComponent } from './pages/supervision/adms/adm-evaluacion.component';

import { EvaluacionBandejaComponent } from './pages/supervision/bandejas/evaluacion-bandeja.component';
import { ProveedoresBandejaComponent } from './pages/administracion/bandejas/proveedores-bandeja.component';
import { AdmTckSupervisionComponent } from './pages/tickets/adms/adm-gen-tcksup.component';
import { ConsHistorialTckComponent } from './pages/tickets/consultas/cons-historial-tickets.component';
import { ConsDetalleSupComponent } from './pages/supervision/consultas/cons-detalle-sup.component';
import { HistorialEvaluacionComponent } from './pages/supervision/consultas/hist-evaluacion-sup.component';
import { HistEvaluacionDetalleComponent } from './pages/supervision/consultas/hist-eva-detalle.component';
import { DetAprobacionTicketComponent } from './pages/tickets/adms/det-aprob-tck.component';

const routes: Routes = [
  {
    path: 'det-aprobacion-ate-ticket',
    component: DetAprobacionTicketComponent,
    canActivate: [ AuthGuardService ]
  },
  {
    path: 'hist-eva-detalle',
    component: HistEvaluacionDetalleComponent,
    canActivate: [ AuthGuardService ]
  },  
  {
    path: 'sup-historial',
    component: HistorialEvaluacionComponent,
    canActivate: [ AuthGuardService ]
  },
  {
    path: 'sup-detalle-sup',
    component: ConsDetalleSupComponent,
    canActivate: [ AuthGuardService ]
  },
  {
    path: 'tck-cons-hist-tck',
    component: ConsHistorialTckComponent,
    canActivate: [ AuthGuardService ]
  },
  {
    path: 'ticket-supervision',
    component: AdmTckSupervisionComponent,
    canActivate: [ AuthGuardService ]
  },  
  {
    path: 'conf-proveedores',
    component: ProveedoresBandejaComponent,
    canActivate: [ AuthGuardService ]
  },
  {
    path: 'adm-evaluacion-supervision',
    component: AdmEvaluacionComponent,
    canActivate: [ AuthGuardService ]
  },
  {
    path: 'eva-sup-vigente-bandeja',
    component: EvaluacionBandejaComponent,
    canActivate: [ AuthGuardService ]
  },
  {
    path: 'sup-prog-supervision',
    component: ProgSupBandejaComponent,
    canActivate: [ AuthGuardService ]
  },  
  {
    path: 'conf-clientes',
    component: ClientesBandejaComponent,
    canActivate: [ AuthGuardService ]
  },  
  {
    path: 'ges-local-zonas-clientes',
    component: AdmCliLocalComponent,
    canActivate: [ AuthGuardService ]
  },   
  {
    path: 'mis-ticket-bandeja',
    component: MisTicketsBandejaComponent,
    canActivate: [ AuthGuardService ]
  },  
  {
    path: 'atender-ticket-bandeja',
    component: AtenderTicketsBandejaComponent,
    canActivate: [ AuthGuardService ]
  },
  {
    path: 'gestionar-atencion-bandeja',
    component: GestionarAtencionesBandejaComponent,
    canActivate: [ AuthGuardService ]
  },  
  {
    path: 'reg-coti-prov-bandeja',
    component: RegCotiProvBandejaComponent,
    canActivate: [ AuthGuardService ]
  },  
  {
    path: 'apro-coti-prov-bandeja',
    component: AprobarCotizacionBandejaComponent,
    canActivate: [ AuthGuardService ]
  },
  {
    path: 'prog-ejec-bandeja',
    component: ProgramarEjecucionBandejaComponent,
    canActivate: [ AuthGuardService ]
  },
  {
    path: 'reg-eje-bandeja',
    component: RegistrarEjecucionBandejaComponent,
    canActivate: [ AuthGuardService ]
  },
  {
    path: 'aprob-aten-bandeja',
    component: AprobarAsignacionBandejaComponent,
    canActivate: [ AuthGuardService ]
  },
  {
    path: 'profile',
    component: ProfileComponent,
    canActivate: [ AuthGuardService ]
  },
  {
    path: 'home',
    component: HomeComponent,
    canActivate: [ AuthGuardService ]
  },
  {
    path: 'login-form',
    component: LoginFormComponent,
    canActivate: [ AuthGuardService ]
  },
  {
    path: '**',
    redirectTo: 'home',
    canActivate: [ AuthGuardService ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes), DxDataGridModule, 
            DxSpeedDialActionModule, DxPopupModule, CommonModule, BrowserModule, DxTooltipModule, 
            DxTemplateModule, DxButtonModule, DxTextBoxModule, DxTextAreaModule, DxSelectBoxModule, 
            DxFileUploaderModule, DxValidatorModule, DxValidationGroupModule, DxGalleryModule,
            DxTabPanelModule, DevExtremeModule, DxTreeViewModule, DxTileViewModule, DxLinearGaugeModule],
  providers: [AuthGuardService],
  exports: [RouterModule],
  declarations: [HomeComponent, ProfileComponent, MisTicketsBandejaComponent, AtenderTicketsBandejaComponent,
                 AdmTicketComponent, GestionarAtencionesBandejaComponent, RegCotiProvBandejaComponent, AprobarCotizacionBandejaComponent, 
                 RegistrarEjecucionBandejaComponent, ProgramarEjecucionBandejaComponent, AprobarAsignacionBandejaComponent, 
                 ClientesBandejaComponent, AdmClienteComponent, AdmCliLocalComponent, ProgSupBandejaComponent, AdmProgSupComponent, 
                 EvaluacionBandejaComponent, AdmEvaluacionComponent, ProveedoresBandejaComponent,
                 AdmTckSupervisionComponent, ConsHistorialTckComponent, ConsDetalleSupComponent, HistorialEvaluacionComponent, 
                 HistEvaluacionDetalleComponent, DetAprobacionTicketComponent]
})
export class AppRoutingModule { }
