import { Component, NgModule, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthService } from '../../services';

import { DxListModule } from 'devextreme-angular/ui/list';
import { DxContextMenuModule } from 'devextreme-angular/ui/context-menu';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-user-panel',
  templateUrl: 'user-panel.component.html',
  styleUrls: ['./user-panel.component.scss']
})

export class UserPanelComponent {
  @Input()
  menuItems: any;

  @Input()
  menuMode: string;

  nombreUsuario = '';
  pathImagenUsuario = '';

  constructor(private authService: AuthService, private sanitizer: DomSanitizer) {
      this.nombreUsuario = authService.usuarioLogueado.login.toUpperCase();
      this.pathImagenUsuario = authService.usuarioLogueado.pathURLFotoUsuario;
  }

  getBackground() {
    return this.sanitizer.bypassSecurityTrustStyle('linear-gradient(rgba(29, 29, 29, 0), rgba(16, 16, 23, 0.5)), url(' + this.pathImagenUsuario + ')');
  }
}

@NgModule({
  imports: [
    DxListModule,
    DxContextMenuModule,
    CommonModule
  ],
  declarations: [ UserPanelComponent ],
  exports: [ UserPanelComponent ]
})
export class UserPanelModule { }
