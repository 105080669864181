import { Component, ViewChild } from '@angular/core';
import { HttpClient, HttpParams } from "@angular/common/http";
import * as AspNetData from "devextreme-aspnet-data-nojquery";
import CustomStore from 'devextreme/data/custom_store';
import { Router} from '@angular/router';
import config from 'devextreme/core/config';
import notify from 'devextreme/ui/notify';
import { DxDataGridComponent, DxFormComponent } from 'devextreme-angular';
import { AuthService } from 'src/app/shared/services';
import { environment } from '../../../../environments/environment';
import { SupervisionService } from 'src/app/shared/services/supervision.service';
import { ParametricasService } from 'src/app/shared/services/parametricas.service';
import { FiltroGeneral } from 'src/app/models/filtroGeneral';
import ArrayStore from 'devextreme/data/array_store';
import { TicketService } from 'src/app/shared/services/ticket.service';
import { ImagenTicket } from 'src/app/models/ImagenTicket';

config({
  floatingActionButtonConfig: {
      icon: 'rowfield',
      position: {
          of: '#gridTickets',
          my: 'right bottom',
          at: 'right bottom',
          offset: '-16 -16'
      }
  }
}); 

@Component({
  templateUrl: 'adm-gen-tcksup.component.html',
  styleUrls: ['adm-gen-tcksup.component.css']
})

export class AdmTckSupervisionComponent {
  @ViewChild('gridFalencias', { static: false }) gridFalencias: DxDataGridComponent;
  @ViewChild('gridFalencias', { static: false }) gridFalenciasSeleccionados: DxDataGridComponent;
  @ViewChild('formFiltro', { static: true }) formFiltro: DxFormComponent;
  @ViewChild('formRegistro', { static: true }) formRegistro: DxFormComponent;

  filtroEvaluacion: FiltroGeneral;
  datos: FiltroGeneral;
  zonasGeograficas: any;
  dsTickets: any;
  selectedRowIndex = -1;
  idTicketSelected = 0;
  popupVisible = false;
  tituloRegistro = "";
  locales:any; zonas:any; subZonas:any; especialidades:any; tiposActivos:any; prioridades:any;
  falenciasDataMap: object = {};
  urlDetalleTicket = "";
  tituloVerImagenes = "";
  popupImagenesVisible = false;
  dsImagenesTck: ImagenTicket[];
  imagenInicial = 0;
  dataFalenciaSelected:any;
  popupGenerarVisible = false;
  dsFalenciasSeleccionadas: any;
  dsFalencias:any;
  tituloGenerar = "";
  popupConfirmarVisible = false;
  botonGenerarDisabled = false;
  loadingVisible = false;

  constructor(private httpClient: HttpClient, private authService: AuthService, 
                      private supService: SupervisionService, private router: Router, private paramService: ParametricasService, 
                      private tckService: TicketService) {

    this.urlDetalleTicket = environment.APITicketCore + "/maestros/listaSolByFalenciaSup";
    this.filtroEvaluacion = new FiltroGeneral();
    this.datos = new FiltroGeneral();
    let idUsuario = this.authService.usuarioLogueado.idUsuario;
    let idCliente = this.authService.usuarioLogueado.idClienteSeleccionado;
    this.cambioLocal = this.cambioLocal.bind(this);
    this.cambioZona = this.cambioZona.bind(this);

    this.tckService.listarLocalesByClienteUsuario(idUsuario, idCliente, true).subscribe(data => {
       this.locales = new ArrayStore({data: data, key: "idLocal"}); 
       if (data.length < 2){
          notify({
                message: "El usuario no tienen locales asignados!",
                position: {
                    my: "center center",
                    at: "center center"
                }, width: 500, 
           }, "error", 2000);                                            
       }
    });

    this.tckService.listarEspecialidades(idCliente, true).subscribe(data => {
        this.especialidades = new ArrayStore({data: data, key: "idEspecialidad"}); 
    }); 

    this.paramService.listarTiposActivo(true).subscribe(data => {
      this.tiposActivos = new ArrayStore({data: data, key: "idTipo"}); 
    });   
    
    this.tckService.listarPrioridades(false).subscribe(data => {
      this.prioridades = new ArrayStore({data: data,key: "idTipo"}); 
    }); 

    this.filtroEvaluacion.IdLocal = -1;
    this.filtroEvaluacion.IdZona = -1;
    this.filtroEvaluacion.IdSubZona = -1;
    this.filtroEvaluacion.IdEspecialidad = -1;
    this.filtroEvaluacion.IdTipoActivo = -1;

    //COLOCO EL FOCO EN EL PRIMER CAMPO
    let that = this;
    setTimeout(function() {
      that.formFiltro.instance.getEditor("IdLocal").focus();
    }, 500);

  }
  cambioLocal(evt) {
    if (evt.selectedItem) {
        let idLocal = evt.selectedItem.idLocal;
        this.tckService.listarZonasByLocal(idLocal, true).toPromise().then((data: any) => {
            this.zonas = new ArrayStore({data: data, key: "idZona" }); 
            this.filtroEvaluacion.IdZona = -1;
            
            //CARGO LAS SUBZONAS VACIAS SOLO CON LA OPCION TODOS
            this.tckService.listarSubZonasByZona(-1, true).toPromise().then(data => {
                this.subZonas = new ArrayStore({data: data,key: "idSubZona"}); 
                this.filtroEvaluacion.IdSubZona = -1;            
            });
       }); 
    }
  }
  cambioZona(evt) {
    if (evt.selectedItem) {
        let idZona = evt.selectedItem.idZona;
        this.tckService.listarSubZonasByZona(idZona, true).toPromise().then(data => {
            this.subZonas = new ArrayStore({data: data,key: "idSubZona"}); 
            this.filtroEvaluacion.IdSubZona = -1;            
        });
    }
  }  
  repoblar() {
    if (this.formFiltro.instance.validate().isValid) {
      let idUsuario = this.authService.usuarioLogueado.idUsuario;
      let idCliente = this.authService.usuarioLogueado.idClienteSeleccionado;
      this.filtroEvaluacion = this.formFiltro.instance.option("formData");
      let loadParams = { idCliente: idCliente, idUsuario: idUsuario, 
                                                          idLocal: this.filtroEvaluacion.IdLocal, 
                                                          idZona: this.filtroEvaluacion.IdZona,
                                                          idSubZona: this.filtroEvaluacion.IdSubZona,
                                                          idEspecialidad: this.filtroEvaluacion.IdEspecialidad, 
                                                          idTipoActivo: this.filtroEvaluacion.IdTipoActivo};
      this.dsFalencias = new CustomStore({
                      key: "idCorrelativo",
                      load: () => this.sendRequest(environment.APITicketCore + "/consultas/listaFalenciasPendientes", "LOAD", loadParams)
                    });
    }
  }
  obtenerSoluciones(idFalencia: number, esActivo: boolean) : any {
    return this.falenciasDataMap[idFalencia] = this.falenciasDataMap[idFalencia] || {
      store: AspNetData.createStore({
          key: "idSolucionFalencia",
          loadParams: { pIdFalencia: idFalencia, pEsActivo: esActivo },
          loadUrl: this.urlDetalleTicket
      })
    };
  }
  sendRequest(url: string, method: string, data: any = {}): any {

    let result;

    switch(method) {
        case "LOAD":
            url = url + "?pIdCliente=" + data.idCliente 
                      + "&pIdUsuario=" + data.idUsuario 
                      + "&pIdLocal=" + data.idLocal 
                      + "&pIdZona=" + data.idZona 
                      + "&pIdSubZona=" + data.idSubZona 
                      + "&pIdEspecialidad=" + data.idEspecialidad
                      + "&pIdTipoActivo=" + data.idTipoActivo + "&pUltSupervision=false"; 
            result = this.httpClient.get(url);
            break;
    }

    return result
        .toPromise()
        .then((data: any) => {
          if (method === "REMOVE") { 
            if (data.codigo != 'OK') {
                notify({
                  message: data.mensaje,
                  position: {
                      my: "center center",
                      at: "center center"
                  }, width: 500, 
                }, "error", 2000);
            }
          } 
          return data;
        })
        .catch(e => {
              notify({
                message: e.error.Message,
                position: {
                    my: "center center",
                    at: "center center"
                }, width: 500, 
              }, "error", 2000);
        });
  }
  selectedChanged(e) {
    this.selectedRowIndex = e.component.getRowIndexByKey(e.selectedRowKeys[0]);
    this.idTicketSelected = e.selectedRowKeys[0];
    this.dataFalenciaSelected = e.selectedRowsData[0];
  }
  verImagenes(evt) {
    this.imagenInicial = 0;
    let idFalencia = this.dataFalenciaSelected.idFalenciaSupervision; 
    let esActivo = this.dataFalenciaSelected.falenciaActivo; 
    this.tituloVerImagenes = "Imagenes Falencia";
    this.tckService.listatImagenesByIdFalencia(idFalencia, esActivo).toPromise().then(data => {
          let imagenes: ImagenTicket[] = [];
          data.forEach(element => {
              let imagen = new ImagenTicket();
              imagen.PathURLImagen = environment.EvidenciaMINTTO + "/" + element.pathURLImagen;
              imagen.Nombre = element.nombre;
              imagenes.push(imagen)
          });
          this.dsImagenesTck = imagenes;
    });
    this.popupImagenesVisible = true;
  } 
  ocultarPopupGenerarClick(){
    this.popupGenerarVisible = false;
  } 
  verTicketsSeleccionados() {
    this.dsFalenciasSeleccionadas = this.gridFalenciasSeleccionados.instance.getSelectedRowsData();
    this.datos = new FiltroGeneral();
    this.formRegistro.instance.resetValues();
    this.formRegistro.instance.option("formData", this.datos);

    if (this.dsFalenciasSeleccionadas.length > 0) {
        this.popupGenerarVisible = true;
        this.tituloGenerar = "Generar Tickets";
    } else {
        notify({
          message: "Debe seleccionar algun registro para proceder con la generación!",
          position: {
              my: "center middle",
              at: "center middle"
          }, width: 500, 
        }, "warning", 3000);        
    }     
  }
  ocultarPopupConfirmar() {
    this.popupConfirmarVisible = false;
  }
  procederGenerarTickets() {
    if (this.formRegistro.instance.validate().isValid) {
      this.botonGenerarDisabled = false;
      this.popupConfirmarVisible = true;
    }
  }
  confirmarOK() {
    this.botonGenerarDisabled = true;

    //MUESTRO EL PANEL
    this.loadingVisible = true;

    //COMPLETAR LA DATA
    let idUsuario = this.authService.usuarioLogueado.idUsuario;
    let idCliente = this.authService.usuarioLogueado.idClienteSeleccionado;
    this.datos = this.formRegistro.instance.option("formData");

    this.dsFalenciasSeleccionadas.forEach(element => {
      element.IdPrioridad = this.datos.IdPrioridad;
      element.IdUsuarioSolicitante = idUsuario;
      element.IdCliente = idCliente;
    });

    //LANZAR GENERACION 
    this.tckService.generarTicketsBysFalencias(this.dsFalenciasSeleccionadas).subscribe(data => {
      if (data.codigo == "OK") {
          notify({
                message: "Grabación Correcta!" ,
                position: {
                my: "center middle",
                at: "center middle"
                }, width: 300, 
          }, "success", 1500);  
          
          //APAGAR EL PANEL DE ESPERA
          this.loadingVisible = false;
          this.botonGenerarDisabled = false;
          this.repoblar();
          this.ocultarPopupConfirmar();     
          this.ocultarPopupGenerarClick();                                                          
      } else {
          //APAGAR EL PANEL DE ESPERA
          this.loadingVisible = false;          
          notify({
              message: data.mensaje ,
              position: {
                  my: "center middle",
                  at: "center middle"
              }, width: 300, 
          }, "error", 5000);
          this.botonGenerarDisabled = false;
        }    
      });
  }
}