import { Component, ViewChild } from '@angular/core';
import { HttpClient, HttpParams } from "@angular/common/http";
import CustomStore from 'devextreme/data/custom_store';
import { Router} from '@angular/router';
import config from 'devextreme/core/config';
import notify from 'devextreme/ui/notify';
import { DxDataGridComponent, DxFormComponent } from 'devextreme-angular';
import { AuthService } from 'src/app/shared/services';
import { environment } from '../../../../environments/environment';
import { SupervisionService } from 'src/app/shared/services/supervision.service';
import { ParametricasService } from 'src/app/shared/services/parametricas.service';
import { FiltroGeneral } from 'src/app/models/filtroGeneral';
import ArrayStore from 'devextreme/data/array_store';
import { TicketService } from 'src/app/shared/services/ticket.service';
import { exportDataGrid } from 'devextreme/exporter/exceljs/excelExporter';
import {Row, Workbook, Worksheet} from 'exceljs';
import saveAs from 'file-saver';
import { ImagenTicket } from 'src/app/models/ImagenTicket';

config({
  floatingActionButtonConfig: {
      icon: 'rowfield',
      position: {
          of: '#gridTickets',
          my: 'right bottom',
          at: 'right bottom',
          offset: '-16 -16'
      }
  }
}); 

@Component({
  templateUrl: 'cons-historial-tickets.component.html',
  styleUrls: ['cons-historial-tickets.component.css']
})

export class ConsHistorialTckComponent {
  @ViewChild('gridTickets', { static: false }) gridTickets: DxDataGridComponent;
  @ViewChild('gridTckSelLocal', { static: false }) gridTckSelLocal: DxDataGridComponent;
  @ViewChild('formFiltro', { static: false }) formFiltro: DxFormComponent;
  
  filtroEvaluacion: FiltroGeneral;
  dsTickets: any;
  locales:any; zonas:any; subZonas:any; especialidades:any; origenesTck:any;
  existenRegistros = false;
  tituloVerImagenes = "";
  popupImagenesVisible = false;
  dsImagenesTck: ImagenTicket[];
  imagenInicial = 0;
  idTicketSelected = 0;
  imgTckExcel: any;
  visibleGridAllLocal = true; 
  visibleGridSelLocal = false;
  loadingVisible = false;
  dsTicketsReport: any;
  constructor(private httpClient: HttpClient, private authService: AuthService, 
                      private supService: SupervisionService, private router: Router, private paramService: ParametricasService, 
                      private tckService: TicketService) {

    this.dsTicketsReport = null;
    this.filtroEvaluacion = new FiltroGeneral();
    let idUsuario = this.authService.usuarioLogueado.idUsuario;
    let idCliente = this.authService.usuarioLogueado.idClienteSeleccionado;
    this.cambioLocal = this.cambioLocal.bind(this);
    this.cambioZona = this.cambioZona.bind(this);

    this.tckService.listarLocalesByClienteUsuario(idUsuario, idCliente, true).subscribe(data => {
       this.locales = new ArrayStore({data: data, key: "idLocal"}); 
       if (data.length == 0){
          notify({
                message: "El usuario no tienen locales asignados!",
                position: {
                    my: "center center",
                    at: "center center"
                }, width: 500, 
           }, "error", 2000);                                            
       }
    });

    this.tckService.listarEspecialidades(idCliente, true).subscribe(data => {
        this.especialidades = new ArrayStore({data: data, key: "idEspecialidad"}); 
    }); 

    this.paramService.listarOrigenTicket(true).subscribe(data => {
      this.origenesTck = new ArrayStore({data: data, key: "idTipo"}); 
    });   
    
    this.filtroEvaluacion.IdLocal = -1;
    this.filtroEvaluacion.IdZona = -1;
    this.filtroEvaluacion.IdSubZona = -1;
    this.filtroEvaluacion.IdEspecialidad = -1;
    this.filtroEvaluacion.IdOrigenTck = -1;

    let fechaActual = new Date();
    this.filtroEvaluacion.FechaInicio = new Date(fechaActual.getFullYear(), fechaActual.getMonth(), 1);
    this.filtroEvaluacion.FechaTermino = new Date();

    //COLOCO EL FOCO EN EL PRIMER CAMPO
    let that = this;
    setTimeout(function() {
      that.formFiltro.instance.getEditor("FechaInicio").focus();
    }, 500);

  }
  cambioLocal(evt) {
    this.dsTickets = null;
    this.existenRegistros = false;
    let idLocal = evt.selectedItem.idLocal;
    if (idLocal === -1) {
      this.visibleGridAllLocal = true;
      this.visibleGridSelLocal = false;
    } else {
      this.visibleGridAllLocal = false;
      this.visibleGridSelLocal = true;
    }

    if (evt.selectedItem) {
        this.tckService.listarZonasByLocal(idLocal, true).toPromise().then((data: any) => {
            this.zonas = new ArrayStore({data: data, key: "idZona" }); 
            this.filtroEvaluacion.IdZona = -1;
            
            //CARGO LAS SUBZONAS VACIAS SOLO CON LA OPCION TODOS
            this.tckService.listarSubZonasByZona(-1, true).toPromise().then(data => {
                this.subZonas = new ArrayStore({data: data,key: "idSubZona"}); 
                this.filtroEvaluacion.IdSubZona = -1;            
            });
       }); 
    }
  }
  cambioZona(evt) {
    if (evt.selectedItem) {
        let idZona = evt.selectedItem.idZona;
        this.tckService.listarSubZonasByZona(idZona, true).toPromise().then(data => {
            this.subZonas = new ArrayStore({data: data,key: "idSubZona"}); 
            this.filtroEvaluacion.IdSubZona = -1;            
        });
    }
  }  
  repoblar() {
    if (this.formFiltro.instance.validate().isValid) {
      let idCliente = this.authService.usuarioLogueado.idClienteSeleccionado;
      let idUsuario = this.authService.usuarioLogueado.idUsuario;
      this.filtroEvaluacion = this.formFiltro.instance.option("formData");
      let loadParams = { idCliente: idCliente, idLocal: this.filtroEvaluacion.IdLocal, 
                                               idZona: this.filtroEvaluacion.IdZona,
                                               idSubZona: this.filtroEvaluacion.IdSubZona,
                                               idEspecialidad: this.filtroEvaluacion.IdEspecialidad, 
                                               idOrigen: this.filtroEvaluacion.IdOrigenTck, 
                                               fechaInicio: this.filtroEvaluacion.FechaInicio.toDateString(),
                                               fechaFin: this.filtroEvaluacion.FechaTermino.toDateString(), 
                                               idUsuario: idUsuario};
      this.dsTickets = new CustomStore({
                                    key: "idTicket",
                                    load: () => this.sendRequest(environment.APITicketCore + "/reportes/historialTickets", "LOAD", loadParams)
                                  });
    }
  }
  sendRequest(url: string, method: string, data: any = {}): any {

    let result;
    
    switch(method) {
        case "LOAD":
            url = url + "?pIdCliente=" + data.idCliente 
                      + "&pIdLocal=" + data.idLocal 
                      + "&pIdZona=" + data.idZona 
                      + "&pIdSubZona=" + data.idSubZona 
                      + "&pIdEspecialidad=" + data.idEspecialidad
                      + "&pIdOrigen=" + data.idOrigen 
                      + "&pInicio=" + data.fechaInicio
                      + "&pFin=" + data.fechaFin
                      + "&pIdUsuario=" + data.idUsuario; 
            result = this.httpClient.get(url);
            break;
    }

    return result
        .toPromise()
        .then((data: any) => {
          if (method === "REMOVE") { 
            if (data.codigo != 'OK') {
                notify({
                  message: data.mensaje,
                  position: {
                      my: "center center",
                      at: "center center"
                  }, width: 500, 
                }, "error", 2000);
            }
          } 
          if (data.length>0) {
            this.existenRegistros = true;
            this.dsTicketsReport = data;
          } else  {
            this.existenRegistros = false;
          }
          return data;})
        .catch(ex => {
              notify({
                message: ex.error.Message,
                position: {
                    my: "center center",
                    at: "center center"
                }, width: 500, 
              }, "error", 2000);
        });
  }
  exportarExcel() {
    
    //MUESTRO EL PANEL
    this.loadingVisible = true;

    if (this.visibleGridAllLocal) {
      this.gridTickets.instance.exportToExcel(false);
    } else {
      this.gridTckSelLocal.instance.exportToExcel(false);
    }
  }
  async onExporting (evt) {

    //RECUPERO EL LOGO DEL CLIENTE
    let logoFile = null;
    if (this.authService.usuarioLogueado.pathURLLogoCliente != null) {
       logoFile = await this.tckService.recuperarArchivoEvidencia(this.authService.usuarioLogueado.pathURLLogoCliente).toPromise();
    }

    //RECUPERO LAS IMAGENES DE LA CONSULTA - TODO: MEJORAR PARA VER QUE SE PIDA LAS IMAGENES LINEA POR LINEA 
    this.filtroEvaluacion = this.formFiltro.instance.option("formData");
    let idCliente = this.authService.usuarioLogueado.idClienteSeleccionado;
    let imagenes = null;
    if (this.visibleGridSelLocal) {
       imagenes = await this.tckService.recuperarEviTicketByParms(idCliente, this.filtroEvaluacion.IdLocal, this.filtroEvaluacion.IdZona, 
                                                                    this.filtroEvaluacion.IdSubZona, this.filtroEvaluacion.IdEspecialidad,
                                                                    this.filtroEvaluacion.IdOrigenTck, 
                                                                    this.filtroEvaluacion.FechaInicio.toDateString(),
                                                                    this.filtroEvaluacion.FechaTermino.toDateString()).toPromise();
    }

    const workbook: Workbook = new Workbook();
    let worksheet = workbook.addWorksheet('Tickets');
    let wsMateriales = workbook.addWorksheet('Materiales');
    let wsIncidencias = workbook.addWorksheet('Incidencias');
    let wstecnicos = workbook.addWorksheet('Técnicos de Apoyo');
    let context = this;
    let idTicket = 0; 

    exportDataGrid({
        component: evt.component,
        worksheet: worksheet,  wsMateriales,  wsIncidencias, wstecnicos,
        topLeftCell: { row: 8, column: 1 },
        customizeCell: options => {

          const { gridCell, excelCell } = options;

          if (gridCell.rowType === 'header') {
              Object.assign(excelCell, {font: { bold: true }, alignment: { horizontal: 'center' },
                                        border: { top: {style:'thin'}, left: {style:'thin'}, 
                                                  bottom: {style:'thin'},right: {style:'thin'}}});
              if (gridCell.column.dataField === 'imgAntes1' || gridCell.column.dataField === 'imgAntes2' 
                        || gridCell.column.dataField === 'imgAntes3' || gridCell.column.dataField === 'imgDespues1'
                        || gridCell.column.dataField === 'imgDespues2' || gridCell.column.dataField === 'imgDespues3') {
                  worksheet.getColumn(excelCell.col).width = 50;
              }                                                                         
          }
          
          if (gridCell.rowType === 'data') {
             Object.assign(excelCell, {border: { top: {style:'thin'}, left: {style:'thin'}, 
                                                bottom: {style:'thin'},right: {style:'thin'}}});

             if (gridCell.column.dataField === 'comentarios') {
                Object.assign(excelCell, {alignment: { vertical: 'top' }});
             } else if (gridCell.column.dataField === 'descripcionPrioridad' 
                            || gridCell.column.dataField === 'fechaPrgInicio' || gridCell.column.dataField === 'fechaPrgFin'
                            || gridCell.column.dataField === 'fechaEjeInicio' || gridCell.column.dataField === 'fechaEjeFin'
                            || gridCell.column.dataField === 'fechaRegistro'  || gridCell.column.dataField === 'numeroTicketCliente'
                            || gridCell.column.dataField === 'descripcionOrigen') {
                  Object.assign(excelCell, {alignment: { horizontal: 'center',  vertical: 'middle' }});
             } else {
               Object.assign(excelCell, {alignment: { vertical: 'middle' }});
             }

             let idActual = gridCell.data.idTicket; 
             if (idActual != idTicket && this.visibleGridSelLocal) {
                if (gridCell.column.dataField === 'tecnicoAsignado') {
             
                    let fila = excelCell.row;
                    let contadorAntes = 1;
                    let contadorDespues = 1;
                 
                    worksheet.getRow(excelCell.row).height = 150;

                    imagenes.forEach(element => {
                      if (idActual === element.idAgrupador) {
                        if (element.fileBase64 != null) {
                          
                            let imageEvidencia = workbook.addImage({
                              base64: element.fileBase64, 
                              extension: element.extensionArchivo,
                            }); 
                            
                            let posicion = "";
                            if (element.tipo == "ANTES" && contadorAntes == 1) {
                              posicion = "W" + fila + ":" + "W" + fila;
                              contadorAntes = contadorAntes + 1;
                            } else if (element.tipo == "ANTES" && contadorAntes == 2) {
                              posicion = "X" + fila + ":" + "X" + fila;
                              contadorAntes = contadorAntes + 1;
                            } else if (element.tipo == "ANTES" && contadorAntes == 3) {
                              posicion = "Y" + fila + ":" + "Y" + fila;
                              contadorAntes = contadorAntes + 1;
                            } else if (element.tipo == "DESPUES" && contadorDespues == 1) {
                              posicion = "Z" + fila + ":" + "Z" + fila;
                              contadorDespues = contadorDespues + 1;
                            } else if (element.tipo == "DESPUES" && contadorDespues == 2) {
                              posicion = "AA" + fila + ":" + "AA" + fila;
                              contadorDespues = contadorDespues + 1;
                            } else if (element.tipo == "DESPUES" && contadorDespues == 3) {
                              posicion = "AB" + fila + ":" + "AB" + fila;
                              contadorDespues = contadorDespues + 1;
                            }

                            //SE COLOCA LA IMAGEN
                            worksheet.addImage(imageEvidencia, posicion);  
                        }
                      }                        
                    });

                  idTicket = idActual;

                }
             }
          }
        }
    }).then(function() {
          context.customizeHeader(worksheet, workbook, logoFile);
          context.customizeReporte (wsMateriales, "MATERIALES" );
          context.customizeReporte (wsIncidencias, "INCIDENCIAS" );
          context.customizeReporte (wstecnicos, "TECNICOS" );
          return Promise.resolve();
    }).then(function() {
          workbook.xlsx.writeBuffer().then(function(buffer) {
            saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'HistorialTickets.xlsx');
          });
    });

    this.loadingVisible = false;
    evt.cancel = true;
  
  }

  customizeReporte(worksheet, type){
    const generalStyles = {
      font: { bold: true},
      alignment: { horizontal: 'center' },
      fill: { type: 'pattern', pattern:'solid', fgColor: { argb:'dddddd' }}
    };

    const generalStylesLeft = {
      font: { bold: false },
      alignment: { horizontal: 'left' }
    };

    const generalStylesRight = {
      font: { bold: false },
      alignment: { horizontal: 'right' }
    };

    if (type == "MATERIALES") {
      worksheet.getRow(1).values = [ 'Id',	'Número Ticket Cliente',	'Código Material',	'Descripción Material'	,'Cantidad',	'Costo',	'Importe'];
      worksheet.columns = [  
        { width: 15 }, { width: 20 }, { width: 20 }, { width: 100 }, { width: 15 }, { width: 15 }, { width: 15 }
      ];  
      Object.assign(worksheet.getRow(1).getCell(1), generalStyles);    
      Object.assign(worksheet.getRow(1).getCell(2), generalStyles);    
      Object.assign(worksheet.getRow(1).getCell(3), generalStyles);    
      Object.assign(worksheet.getRow(1).getCell(4), generalStyles);    
      Object.assign(worksheet.getRow(1).getCell(5), generalStyles);    
      Object.assign(worksheet.getRow(1).getCell(6), generalStyles);    
      Object.assign(worksheet.getRow(1).getCell(7), generalStyles); 
    }
    if (type == "INCIDENCIAS") {
      worksheet.getRow(1).values = [ 'Id',	'Número Ticket Cliente',	'Código Incidencia',	'Descripción Incidencia'	,'Duración Minutos'];
      worksheet.columns = [  
        { width: 15 }, { width: 20 }, { width: 20 }, { width: 50 }, { width: 20 }
      ];
      Object.assign(worksheet.getRow(1).getCell(1), generalStyles);    
      Object.assign(worksheet.getRow(1).getCell(2), generalStyles);    
      Object.assign(worksheet.getRow(1).getCell(3), generalStyles);    
      Object.assign(worksheet.getRow(1).getCell(4), generalStyles);    
      Object.assign(worksheet.getRow(1).getCell(5), generalStyles);    
    }
    if (type == "TECNICOS") {
      worksheet.getRow(1).values = [ 'Id',	'Número Ticket Cliente',	'Técnico',	'Duración Minutos'];
      worksheet.columns = [  
        { width: 15 }, { width: 20 }, { width: 50 }, { width: 20 }
      ];
      Object.assign(worksheet.getRow(1).getCell(1), generalStyles);    
      Object.assign(worksheet.getRow(1).getCell(2), generalStyles);    
      Object.assign(worksheet.getRow(1).getCell(3), generalStyles);    
      Object.assign(worksheet.getRow(1).getCell(4), generalStyles);   
    }

    let i =  1;
 
    

   this.dsTicketsReport.forEach(element => {
      if (type == "MATERIALES" && element.listaMateriales != null) {
        element.listaMateriales.forEach(element => {
          i++;
          worksheet.getRow(i).values = [ element.idTicket,	element.numeroTicketCliente, element.codigoMaterial, element.descripcionMaterial, element.cantidad, element.costo,	element.importe];    
          Object.assign(worksheet.getRow(i).getCell(5), generalStylesRight);    
          Object.assign(worksheet.getRow(i).getCell(6), generalStylesRight);    
          Object.assign(worksheet.getRow(i).getCell(7), generalStylesRight);   
          worksheet.getRow(i).getCell(6).numFmt = '#,##0.00;[Red]\-#,##0.00';   
          worksheet.getRow(i).getCell(7).numFmt = '#,##0.00;[Red]\-#,##0.00';    
        });
      }

      if (type == "INCIDENCIAS" && element.listaIncidencias != null) {
        element.listaIncidencias.forEach(element => {
          i++;
          worksheet.getRow(i).values = [ element.idTicket,	element.numeroTicketCliente, element.codigoIncidencia, element.descripcionIncidencia, element.duracionMinutos];    
          Object.assign(worksheet.getRow(i).getCell(5), generalStylesRight);  
          worksheet.getRow(i).getCell(5).numFmt = '#,##0.00;[Red]\-#,##0.00';     
        });
      }

      if (type == "TECNICOS" && element.listaTecnicos != null) {
        element.listaTecnicos.forEach(element => {
          i++;
          worksheet.getRow(i).values = [ element.idTicket,	element.numeroTicketCliente, element.nombresCompletos, element.duracionMinutos];
          Object.assign(worksheet.getRow(i).getCell(4), generalStylesRight); 
          worksheet.getRow(i).getCell(4).numFmt = '#,##0.00;[Red]\-#,##0.00';   
        });
      }
      
    });
  }
  customizeHeader(worksheet, workbook, imgLogo){

    const generalStyles = {
        font: { bold: true },
        alignment: { horizontal: 'left' }
    };
    
    if (imgLogo != null) {
      var imageLogo = workbook.addImage({
          base64: imgLogo.fileBase64,
          extension: imgLogo.extensionArchivo,
      });
    
      //SE COLOCA LA IMAGEN
      worksheet.addImage(imageLogo, 'A1:A6');
    }
      
    for (var rowIndex = 1; rowIndex < 8; rowIndex++) {
        Object.assign(worksheet.getRow(rowIndex).getCell(2), generalStyles);
    } 
    
    worksheet.getColumn(2).values = [ 'Cliente:', 'Rango:', 'Local:', 'Zona:', 'Sub-Zona:', 'Especialidad:', 'Origen:'];

    let idCliente = this.authService.usuarioLogueado.idClienteSeleccionado; 
    let cliente = null;
    this.authService.usuarioLogueado.clientesAsignados.forEach(element => {
        if (element.idCliente === idCliente){
          cliente = element.cliente;
        }
    });

    let razonSocialCliente = cliente.descripcionCliente;
    this.filtroEvaluacion = this.formFiltro.instance.option("formData");
    let rango = "Del: " + this.filtroEvaluacion.FechaInicio.toLocaleDateString('en-GB') + " al " + this.filtroEvaluacion.FechaTermino.toLocaleDateString('en-GB');
    let descripcionLocal = this.formFiltro.instance.getEditor("IdLocal").option("selectedItem").descripcionLocal;
    let descripcionZona = this.formFiltro.instance.getEditor("IdZona").option("selectedItem").zona.descripcionZona;
    let descripcionSubZona = this.formFiltro.instance.getEditor("IdSubZona").option("selectedItem").subZona.descripcionSubZona;
    let descripcionEspecialidad = this.formFiltro.instance.getEditor("IdEspecialidad").option("selectedItem").especialidad.descripcionEspecialidad;
    let descripcionOrigen = this.formFiltro.instance.getEditor("IdOrigenTck").option("selectedItem").descripcionTipo;

    worksheet.getColumn(3).values = [razonSocialCliente, rango, descripcionLocal, descripcionZona, 
                                                              descripcionSubZona, descripcionEspecialidad, descripcionOrigen];
  }
  selectedChanged(e) {
    this.idTicketSelected = e.selectedRowKeys[0];
    this.dsImagenesTck = null;
  }
  verImagenesClick() {

    if (this.idTicketSelected == 0 || !this.idTicketSelected) {
      notify({
        message: "Seleccione el registro primero!",
        position: {
            my: "center center",
            at: "center center"
        }, width: 500, 
      }, "error", 2000);
      return;
    }

    this.imagenInicial = 0;
    this.dsImagenesTck = null;
    this.tituloVerImagenes = "Imagenes Ticket: " + this.idTicketSelected;
    this.tckService.leerTicketById(this.idTicketSelected).toPromise().then(data => {
          let imagenes: ImagenTicket[] = [];
          //CARGO LAS IMAGENES ANTES DE LA ATENCION 
          data.data.imagenesTicket.forEach(element => {
              let imagen = new ImagenTicket();
              imagen.PathURLImagen = environment.EvidenciaMINTTO + "/" + element.pathURLImagen;
              imagen.Nombre = element.nombre;
              imagen.Tipo = "ANTES";
              imagenes.push(imagen)
          });
          
          //CARGO LAS IMAGENES DESPUES DE LA ATENCION 
          data.data.imagenesAtencion.forEach(element => {
              let imagen = new ImagenTicket();
              imagen.PathURLImagen = environment.EvidenciaMINTTO + "/" + element.pathURLImagen;
              imagen.Nombre = element.nombre;
              imagen.Tipo = "DESPUES";
              imagenes.push(imagen)
          });

          //ASIGNO LAS IMAGENES AL CONTROL PARA SU VISUALIZACION
          this.dsImagenesTck = imagenes;

          this.popupImagenesVisible = true;
    });
  }  
}