import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { DxFormComponent, DxFileUploaderComponent, DxDataGridComponent } from 'devextreme-angular';
import ArrayStore from 'devextreme/data/array_store';
import notify from 'devextreme/ui/notify';

import { TicketService } from '../../../shared/services/ticket.service'; 
import { AuthService } from '../../../shared/services/auth.service';
import { ImagenTicket } from '../../../models/ImagenTicket';
import { Ticket } from '../../../models/Ticket';
import { environment } from 'src/environments/environment';
import $ from "jquery";
import { Router, ActivatedRoute } from '@angular/router';
import { Calificacion } from 'src/app/models/Calificacion';
import { AtencionService } from 'src/app/shared/services/atencion.service';

@Component({
    selector: 'det-aprobacion-ate-ticket',
    styleUrls: ['./det-aprob-tck.component.css'],
    templateUrl: './det-aprob-tck.component.html'
})

export class DetAprobacionTicketComponent {
    comentariosPattern: any = /^[^\/]+$/;
    ticket = new Ticket();
    aprobacion = new Ticket();
    calificacion = new Calificacion();

    botonGrabarDisabled = true;
    botonRechazarDisabled = false; 
    botonGenerarDisabled = false;
    botonProcesarDisabled = false;

    tituloTicket = "";
    dsImgIniciales: ImagenTicket[] = [];
    dsImgFinales : ImagenTicket[] = [];
    dsImgFirma : ImagenTicket[] = [];
    idTicketAtencion: any;
    tckAprobado: any;
    tckObservado: any;
    dsMateriales: any;
    dsIncidencias: any;
    dsTecnicos: any;
    popupConfirmarVisible = false;
    preguntaProcesar = "";
    accionSeleccionada = "";
    loadingVisible = false;
    idTicket = -1;
    urlSrvArchivos = "";
    nombreArchivoActaAprobacion = "";
    pathURLActaAprobacion = "";

    @ViewChild('formActividades', { static: false }) formActividades: DxFormComponent;
    @ViewChild('formCalificacion', { static: false }) formCalificacion: DxFormComponent;
    @ViewChild('formAprobacion', { static: false }) formAprobacion: DxFormComponent;

    constructor(private tckService: TicketService, private authService: AuthService, private ateService: AtencionService,
                                                   private router: Router, private route: ActivatedRoute) {

        this.idTicketAtencion = this.route.snapshot.paramMap.get('idTicketAtencion');
        this.tckAprobado = this.route.snapshot.paramMap.get('tckAprobado');
        this.tckObservado = this.route.snapshot.paramMap.get('tckObservado');
        this.urlSrvArchivos = environment.EvidenciaMINTTO + "/";
        
        this.tckService.leerTicketAtencionById(this.idTicketAtencion).subscribe(data => {
            let numeroTck = "S/N";
            if (data.data.NumeroTicketCliente != null) {
                numeroTck = data.data.NumeroTicketCliente;
            }
            this.tituloTicket = "Ticket: " + data.data.idTicket + ", Numero: " + numeroTck;
            this.idTicket = data.data.idTicket;

            let imagenes: ImagenTicket[] = [];
            data.data.imagenesAtencion.forEach(element => {
                let imagen = new ImagenTicket();
                imagen.PathURLImagen = environment.EvidenciaMINTTO + "/" + element.pathURLImagen;
                imagen.Nombre = element.nombre;
                imagen.Tipo = element.tipo;

                if (element.tipo == "INICIAL") {
                    this.dsImgIniciales.push(imagen);
                } else {
                    this.dsImgFinales.push(imagen);
                }

                imagenes.push(imagen)
            });

            //ASIGNO LOS VALORES REGISTRO DE ACTIVIDADES
            this.ticket.Comentarios = data.data.comentariosEjecucion;
            this.ticket.ComentariosEjecucion = data.data.solucionAplicadaCliente.descripcionActividad;
            this.formActividades.instance.option('formData', this.ticket);

            this.dsMateriales = data.data.materialesAtencion;
            this.dsIncidencias = data.data.incidenciasAtencion;
            this.dsTecnicos = data.data.tecnicosAtencion;

            //ASIGNACION DE CALIFICACION 
            this.calificacion.Calificacion = data.data.calificacionCliente.descripcionTipo;
            this.calificacion.CorreoReceptor = data.data.correoReceptor;
            this.calificacion.NumeroDocumento = data.data.documentoReceptor;
            this.calificacion.UsuarioReceptor = data.data.usuarioReceptor;
            this.formCalificacion.instance.option('formData', this.calificacion);
            
            let imgFirma = new ImagenTicket();
            imgFirma.PathURLImagen = environment.EvidenciaMINTTO + "/" + data.data.pathURLFirma;
            imgFirma.Nombre = data.data.nombreFirma;
            this.dsImgFirma.push(imgFirma)
        });

        //COLOCO EL FOCO EN EL PRIMER CAMPO
        let that = this;
        setTimeout(function() {
            that.formAprobacion.instance.getEditor("Comentarios").focus();
        }, 500); 

        //BLOQUEO LOS CONTROLES SI YA ESTA APROBADO O RECHAZADO 
        if (this.tckAprobado=="true" || this.tckObservado == "true")
        {
            this.botonGrabarDisabled = true;
            this.botonRechazarDisabled = true; 
            this.botonGenerarDisabled = true;

            this.formAprobacion.instance.option('readOnly', true);

        }
    }
    regresar() {
        this.router.navigate(["aprob-aten-bandeja", { idTicketAtencion: this.idTicketAtencion }]);
    }
    generarActaClick(evt) {
        if (this.formAprobacion.instance.validate().isValid) {
            this.accionSeleccionada = "GENERAR";
            this.preguntaProcesar = "Esta seguro de proceder con la generación del acta";
            this.popupConfirmarVisible = true;
        }
    }
    aprobarClick(evt) {
        if (this.formAprobacion.instance.validate().isValid) {
            this.accionSeleccionada = "APROBAR";
            this.preguntaProcesar = "Esta seguro de aprobar la atención del ticket";
            this.popupConfirmarVisible = true;
        }
    }
    rechazarClick(evt) {
        if (this.formAprobacion.instance.validate().isValid) {
            this.accionSeleccionada = "RECHAZAR";
            this.preguntaProcesar = "Esta seguro de rechazar la atención del ticket";
            this.popupConfirmarVisible = true;
        }
    }
    ocultarPopupConfirmar() {
        this.popupConfirmarVisible = false;
    }
    confirmarOK() {

        if (this.accionSeleccionada == "GENERAR") {

            this.popupConfirmarVisible = false;
            this.loadingVisible = true;
            this.botonGrabarDisabled = true;
            this.botonRechazarDisabled = true; 
            this.botonGenerarDisabled = true;
            
            //LLAMAR AL SERVIDOR PARA GENERAR EL ACTA 
            let tck = new Ticket();
            tck.IdTicketAtencion = this.idTicketAtencion;;
            this.tckService.generarActaAprobacion(tck).subscribe(data => {

                this.loadingVisible = false;

                if (data.codigo == "OK")  {
                    this.pathURLActaAprobacion = data.urlRecuperaArchivo;
                    this.nombreArchivoActaAprobacion = data.nombreArchivo;
                    //LUEGO DE GENERAR SE DEBE ACTIVAR EL BOTON DE APROBACIÓN
                    this.botonGrabarDisabled = false;
                    this.botonRechazarDisabled = false; 
                    this.botonGenerarDisabled = false;
                } else {
                    notify({
                        message: data.mensaje,
                        position: {
                        my: "center middle",
                        at: "center middle"
                      }, width: 300, 
                     }, "error", 1500);  
                }
            });


        } else if (this.accionSeleccionada == "APROBAR") {
            this.popupConfirmarVisible = false;
            this.loadingVisible = true;
            this.botonGrabarDisabled = true;
            this.botonRechazarDisabled = true; 
            this.botonGenerarDisabled = true;

            let tck = this.formAprobacion.instance.option("formData");
            tck.IdTicket = this.idTicket; 
            tck.IdTicketAtencion = this.idTicketAtencion;
            tck.IdOrigenTicket = 1;
            tck.IdUsuarioCreacion = this.authService.usuarioLogueado.idUsuario;

            this.ateService.registrarAprobacion(tck).subscribe(data => {
                this.loadingVisible = false;
                if (data.codigo == "OK") {
                    notify({
                        message: "Grabación Correcta!" ,
                        position: {
                        my: "center middle",
                        at: "center middle"
                      }, width: 300, 
                     }, "success", 1500);  

                     this.formAprobacion.instance.option('readOnly', true);
                } else {
                    this.botonGrabarDisabled = false;
                    this.botonRechazarDisabled = false; 
                    this.botonGenerarDisabled = false;
                    notify({
                        message: data.mensaje ,
                        position: {
                            my: "center middle",
                            at: "center middle"
                        }, width: 300, 
                    }, "error", 5000);
                }    
            });

        } else if (this.accionSeleccionada == "RECHAZAR") {
            this.popupConfirmarVisible = false;
            this.loadingVisible = true;
            this.botonGrabarDisabled = true;
            this.botonRechazarDisabled = true; 
            this.botonGenerarDisabled = true;

            let tck = this.formAprobacion.instance.option("formData");
            tck.IdTicket = this.idTicket; 
            tck.IdTicketAtencion = this.idTicketAtencion;
            tck.IdOrigenTicket = 3;
            tck.IdUsuarioCreacion = this.authService.usuarioLogueado.idUsuario;

            this.ateService.registrarAprobacion(tck).subscribe(data => {
                this.loadingVisible = false;
                this.formAprobacion.instance.option('readOnly', true);
                if (data.codigo == "OK") {
                    notify({
                        message: "Grabación Correcta!" ,
                        position: {
                        my: "center middle",
                        at: "center middle"
                      }, width: 300, 
                     }, "success", 1500);  
                } else {
                    this.botonGrabarDisabled = false;
                    this.botonRechazarDisabled = false; 
                    this.botonGenerarDisabled = false;
                    notify({
                        message: data.mensaje ,
                        position: {
                            my: "center middle",
                            at: "center middle"
                        }, width: 300, 
                    }, "error", 5000);
                }    
            });
        }
    }
}