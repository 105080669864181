import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { DxFormComponent, DxFileUploaderComponent, DxDataGridComponent } from 'devextreme-angular';
import { HttpClient, HttpParams } from "@angular/common/http";
import CustomStore from 'devextreme/data/custom_store';
import * as AspNetData from "devextreme-aspnet-data-nojquery";
import notify from 'devextreme/ui/notify';
import { Router} from '@angular/router';
import config from 'devextreme/core/config';
import { environment } from '../../../../environments/environment';
import { AuthService } from '../../../shared/services/auth.service';
import { ClienteService } from 'src/app/shared/services/cliente.service';
import { Cliente } from 'src/app/models/Cliente';
import { Zona } from 'src/app/models/Zona';
import { SubZona } from 'src/app/models/SubZona';
import { Local } from 'src/app/models/Local';
import { ParametricasService } from 'src/app/shared/services/parametricas.service';
import ArrayStore from 'devextreme/data/array_store';

config({
    floatingActionButtonConfig: {
        icon: 'rowfield',
        position: {
            of: 'tvLocales',
            my: 'right bottom',
            at: 'right bottom',
            offset: '-16 -16'
        }
    }
  });

@Component({
    selector: 'adm-cli-local',
    templateUrl: 'adm-cli-local.component.html',
    styleUrls: ['adm-cli-local.component.css']
})

export class AdmCliLocalComponent {
    currentItem: any; 

    cliente = new Cliente();
    local = new Local(); 
    zona = new Zona(); 
    subZona = new SubZona();

    visibleFormCliente = true;
    visibleFormLocal = false;
    visibleFormZona = false;
    visibleFormSubZona = false;
    visibleGridFalencias = false;
    visibleGridActivos = false;

    dsFalencias: any;
    dsActivos: any;
    ubigeos: any;
    zonasGeograficas: any;
    tiposLocal: any;
    tipoSubZona: any;

    selectedRowIndexActivo = -1;
    nombreItemSeleccionado = "";
    popupZonasVisible = false;
    createChildren: any;
    falenciasDataMap: object = {};
    urlDetalleFalencias = "";
    dsZonas: any;

    @ViewChild(DxDataGridComponent, { static: false }) gridFalencias: DxDataGridComponent;
    @ViewChild(DxDataGridComponent, { static: false }) gridActivos: DxDataGridComponent;
    @ViewChild('formCliente', { static: false }) formCliente: DxFormComponent;
    @ViewChild('formLocal', { static: false }) formLocal: DxFormComponent;
    @ViewChild('formZona', { static: false }) formZona: DxFormComponent;
    @ViewChild('formSubZona', { static: false }) formSubZona: DxFormComponent;

    constructor(private httpClient: HttpClient, private clienteService: ClienteService,
                                                private router: Router, private paramService: ParametricasService,
                                                private authService: AuthService) {

        this.urlDetalleFalencias = environment.APIAdministracion + "/activosCliente/listarFalenciasByIdActivo";                                            
        let datosCliente = JSON.parse(localStorage.getItem('clienteSeleccionado'));
        this.cliente.IdCliente = datosCliente.idCliente;
        this.cliente.DescripcionTipoCliente = datosCliente.tipoCliente.descripcionTipo;
        this.cliente.DescripcionTipoDocumento = datosCliente.tipoDocumento.descripcionTipo;
        this.cliente.NumeroDocumento = datosCliente.numeroDocumento;
        this.cliente.DescripcionCliente = datosCliente.descripcionCliente;
        this.cliente.Direccion = datosCliente.direccion;
        this.cliente.DescripcionUbigeo = datosCliente.ubigeo.descripcionUbigeo;
        this.nombreItemSeleccionado = "Datos del Cliente";

        this.paramService.listarUbigeos().toPromise().then(data => {
            this.ubigeos = new ArrayStore({
               data: data,
               key: "idUbigeo"
           }); 
        });
        
        paramService.listarZonasGeograficas(false).toPromise().then(data => {
            this.zonasGeograficas = new ArrayStore({
                                        data: data,
                                        key: "idZonaGeografica"
                                    }); 
        });    

        paramService.listarTiposSubZona(false).toPromise().then(data => {
            this.tipoSubZona = new ArrayStore({
                                        data: data,
                                        key: "idTipo"
                                    }); 
        });             

        paramService.listarTiposLocal(false).toPromise().then(data => {
            this.tiposLocal = new ArrayStore({
                                        data: data,
                                        key: "idTipo"
                                    }); 
        }); 

        this.createChildren = (parent) => {
            let parentId = parent ? parent.itemData.id : datosCliente.idCliente;
            let pTipo = parent ? parent.itemData.tipo : "raiz";
            return clienteService.listarJerarquiaLZByIdCliente(parentId, pTipo).toPromise();
        }                      
    }
    screen(width) {
        return ( width < 700 ) ? 'sm' : 'lg';
    }    
    sendRequest(url: string, method: string, data: any = {}): any {

        let result;
    
        switch(method) {
            case "LOAD":
                result = this.httpClient.get(url);
                break;
            case "REMOVE":
                url = url + "?pIdTicket=" + data.pIdTicket; 
                result = this.httpClient.delete(url);
                break;
        }
    
        return result
            .toPromise()
            .then((data: any) => {
                if (method === "REMOVE") { 
                  if (data.codigo != 'OK') {
                      notify({
                        message: data.mensaje,
                        position: {
                            my: "center center",
                            at: "center center"
                        }, width: 500, 
                      }, "error", 2000);
                  }
                } 
                return data;
            })
            .catch(e => {
                throw e && e.error && e.error.Message;
            });
    }   
    selectedChangedGrillaActivos(evt) {
        this.selectedRowIndexActivo = evt.component.getRowIndexByKey(evt.selectedRowKeys[0]);
    } 
    addRow() {
/*         if (this.visibleGridLocales) {
            this.gridLocales.instance.addRow();
        } else if (this.visibleGridZonas) {
            this.popupZonasVisible = true;
        } else if (this.visibleGridActivos) {
            this.gridActivos.instance.addRow();
        } else if (this.visibleGridFalenciasActivo) {
            this.gridFalenciasActivo.instance.addRow();
        } */
    }
    selectItem(evt) {
        this.currentItem = evt.itemData;
        if (this.currentItem.tipo == "cliente") {

            this.nombreItemSeleccionado = "Datos del Cliente";
            this.visibleFormLocal = false;
            this.visibleFormZona = false;
            this.visibleFormSubZona = false;
            this.visibleGridActivos = false;
            this.visibleGridFalencias = false;
            this.visibleFormCliente = true;     

        } else if (this.currentItem.tipo == "local") {
            this.local = new Local() 
            this.nombreItemSeleccionado = "Datos del Local: " + evt.node.text;

            this.paramService.leerLocalById(evt.node.key).toPromise().then((data: any) => {
                this.local.IdLocal = data.idLocal; 
                this.local.DescripcionLocal = data.descripcionLocal; 
                this.local.Direccion = data.direccion; 
                this.local.Referencia = data.referencia; 
                this.local.IdUbigeo = data.idUbigeo; 
                this.local.IdZonaGeografica = data.idZonaGeografica; 
                this.local.ContactoLocal = data.contactoLocal; 
                this.local.RegistroActivo = data.registroActivo; 
                this.local.IdTipoLocal = data.idTipoLocal;

                this.formLocal.instance.option('formData', this.local);
            });
            
            this.visibleFormCliente = false;     
            this.visibleFormZona = false;
            this.visibleFormSubZona = false;
            this.visibleGridActivos = false;
            this.visibleGridFalencias = false;
            this.visibleFormLocal = true;

        } else if (this.currentItem.tipo == "zona") {
            this.zona = new Zona() ;
            this.nombreItemSeleccionado = "Datos de la Zona: " + evt.node.text;

            this.paramService.leerZonaById(evt.node.key).toPromise().then((data: any) => {
                this.zona.IdZona = data.idZona; 
                this.zona.DescripcionZona = data.descripcionZona; 
                this.zona.RegistroActivo = data.registroActivo; 

                this.formZona.instance.option('formData', this.zona);
             
            });   
            this.visibleFormCliente = false;     
            this.visibleFormLocal = false;
            this.visibleFormSubZona = false;
            this.visibleGridActivos = false;
            this.visibleGridFalencias = false;
            this.visibleFormZona = true;   

        } else if (this.currentItem.tipo == "subZona") {
            this.subZona = new SubZona();
            this.nombreItemSeleccionado = "Datos de la Sub Zona: " + evt.node.text;
            this.paramService.leerSubZonaById(evt.node.key).toPromise().then((data: any) => {

                this.subZona.IdSubZona = data.idSubZona; 
                this.subZona.DescripcionSubZona = data.descripcionSubZona; 
                this.subZona.RegistroActivo = data.registroActivo; 
                this.subZona.IdTipoSubZona = data.idTipoSubZona;

                this.formSubZona.instance.option('formData', this.subZona);
            });

            this.visibleFormCliente = false;     
            this.visibleFormLocal = false;
            this.visibleFormZona = false;
            this.visibleGridActivos = false;
            this.visibleGridFalencias = false;
            this.visibleFormSubZona = true;
        } else if (this.currentItem.tipo == "falencias") {
            this.nombreItemSeleccionado = "Falencias: " + evt.node.parent.text;
            let url = environment.APIAdministracion + "/falencias/listarFalenciasBySubZona?pIdSubZona=" + evt.node.parent.key; 
            this.dsFalencias = new CustomStore({
                            key: "idFalencia",
                            load: () => this.sendRequest(url, "LOAD", {})
                          });
            this.visibleFormCliente = false;     
            this.visibleFormLocal = false;
            this.visibleFormZona = false;
            this.visibleFormSubZona = false;
            this.visibleGridActivos = false;
            this.visibleGridFalencias = true;
        } else if (this.currentItem.tipo == "activos") {
            this.nombreItemSeleccionado = "Activos: " + evt.node.parent.text;
            let url = environment.APIAdministracion + "/activosCliente/listarActivosByParams?pIdLlave=" + evt.node.parent.key;  
            this.dsActivos = new CustomStore({
                            key: "idActivoCliente",
                            load: () => this.sendRequest(url, "LOAD", {}),
                            remove: (key) => this.sendRequest(environment.APIAdministracion +  "activosCliente/eliminarActivo", "REMOVE", {pIdActivoCliente: key})
                          });                         

            this.visibleFormCliente = false;     
            this.visibleFormLocal = false;
            this.visibleFormZona = false;
            this.visibleFormSubZona = false;
            this.visibleGridFalencias = false;
            this.visibleGridActivos = true;

        } 
    }
    updateSelection(event) {
/*         const nodeClass = 'dx-treeview-node';
        const selectedClass = 'dx-state-selected';
        const leafNodeClass = 'dx-treeview-node-is-leaf';
        const element: HTMLElement = event.element;
    
        const rootNodes = element.querySelectorAll(`.${nodeClass}:not(.${leafNodeClass})`);
        Array.from(rootNodes).forEach(node => {
          node.classList.remove(selectedClass);
        });
    
        let selectedNode = element.querySelector(`.${nodeClass}.${selectedClass}`);
        while (selectedNode && selectedNode.parentElement) {
          if (selectedNode.classList.contains(nodeClass)) {
              selectedNode.classList.add(selectedClass);
          }
    
          selectedNode = selectedNode.parentElement;
        } */
    }    
    regresar() {
        localStorage.setItem('clienteSeleccionado', null);
        this.router.navigate(["conf-clientes"]);
    }
    obtenerFalencias(pIdActivoCliente: number) : any{
        return this.falenciasDataMap[pIdActivoCliente] = this.falenciasDataMap[pIdActivoCliente] || {
            store: AspNetData.createStore({
                key: "idFalencia",
                loadParams: { pIdActivoCliente: pIdActivoCliente },
                loadUrl: this.urlDetalleFalencias
            })
          };
    }
}