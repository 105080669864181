import { ImagenTicket } from '../models/ImagenTicket';

export class Ticket {
    IdTicket: number;
    NumeroTicketCliente: string;
    IdCliente: number;
    IdUsuarioSolicitante: number;
    IdOrigenTicket: number;
    FechaRegistro: string ; 
    Comentarios: string;
    ComentariosRechazo: string;
    IdEstadoProceso: number; 
    IdLocal: number;
    IdEspecialidad: number;
    IdZona: number;
    IdSubZona: number;
    IdPrioridad: number;
    ImagenesTicket: ImagenTicket[];
    DescripcionPrioridad: string;
    DescripcionLocal: string;
    DescripcionZona: string;
    DescripcionSubZona: string;
    DescripcionEspecialidad: string;
    FechaInicio: Date; 
    FechaTermino: Date; 
    FechaInicioReal: Date; 
    FechaTerminoReal: Date; 
    IdUsuarioCreacion: number;
    IdProveedorAtencion: number;
    IdTicketAtencion:number;
    ComentariosEjecucion: string;
    ComentariosAprobacion: string;
    AtencionObservada: boolean;
    TicketAprobado: boolean;
    TicketEjecutado: boolean;
    IdFalenciaSupervision:number;
    ActivoSeleccionado: boolean;
    IdSupervision:number;
  }