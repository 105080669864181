import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { catchError } from 'rxjs/operators';
import { of } from 'zen-observable';
import notify from 'devextreme/ui/notify';
import { Atencion } from 'src/app/models/Atencion';
import { Proveedor } from 'src/app/models/Proveedor';
import { Ticket } from 'src/app/models/Ticket';
import { environment } from 'src/environments/environment';

@Injectable()
export class AtencionService {
  
  private httpOptions: any;

  constructor(private httpClient: HttpClient) {
    this.httpOptions = {
      headers: new HttpHeaders({'Content-Type': 'application/json'})
    };
  }

  registrarAtencion (atencion: Atencion): any {

    let url: string = environment.APITicketCore + "/atenciones/registrarAtencion";
    return this.httpClient.post(url, atencion, this.httpOptions)
                   .pipe(catchError(err => 
                                      { 
                                        console.log("ERROR NO CONTROLADO: " + err.message);
                                        notify({
                                          message: "Se presento un error al conectarse con el servidor! ERROR: " + err.message,
                                          position: {
                                              my: "center middle",
                                              at: "center middle"
                                          }, width: 500, 
                                        }, "error", 5000);
                                        return of(null); 
                                      }
                                  ));  
  }
  registrarCotizacion (cotizacion: Proveedor): any {

    let url: string =  environment.APITicketCore + "/atenciones/registrarCotizacion";
    return this.httpClient.post(url, cotizacion, this.httpOptions)
                   .pipe(catchError(err => 
                                      { 
                                        console.log("ERROR NO CONTROLADO: " + err.message);
                                        notify({
                                          message: "Se presento un error al conectarse con el servidor! ERROR: " + err.message,
                                          position: {
                                              my: "center middle",
                                              at: "center middle"
                                          }, width: 500, 
                                        }, "error", 5000);
                                        return of(null); 
                                      }
                                  ));  
  }
  aprobarCotizacion (cotizacion: Proveedor) : any{

    let url: string =  environment.APITicketCore + "/atenciones/aprobarCotizacion";
    return this.httpClient.post(url, cotizacion, this.httpOptions)
                   .pipe(catchError(err => 
                                      { 
                                        console.log("ERROR NO CONTROLADO: " + err.message);
                                        notify({
                                          message: "Se presento un error al conectarse con el servidor! ERROR: " + err.message,
                                          position: {
                                              my: "center middle",
                                              at: "center middle"
                                          }, width: 500, 
                                        }, "error", 5000);
                                        return of(null); 
                                      }
                                  ));  
  }  
  programarEjecucion (ticket: Ticket) : any{

    let url: string =  environment.APITicketCore + "/atenciones/programarEjecucion";
    return this.httpClient.post(url, ticket, this.httpOptions)
                   .pipe(catchError(err => 
                                      { 
                                        console.log("ERROR NO CONTROLADO: " + err.message);
                                        notify({
                                          message: "Se presento un error al conectarse con el servidor! ERROR: " + err.message,
                                          position: {
                                              my: "center middle",
                                              at: "center middle"
                                          }, width: 500, 
                                        }, "error", 5000);
                                        return of(null); 
                                      }
                                  ));  
  }    
  cerrarProgramacion (atencion: Atencion) : any{

    let url: string =  environment.APITicketCore + "/atenciones/cerrarProgramacionEjecucion";
    return this.httpClient.post(url, atencion, this.httpOptions)
                   .pipe(catchError(err => 
                                      { 
                                        console.log("ERROR NO CONTROLADO: " + err.message);
                                        notify({
                                          message: "Se presento un error al conectarse con el servidor! ERROR: " + err.message,
                                          position: {
                                              my: "center middle",
                                              at: "center middle"
                                          }, width: 500, 
                                        }, "error", 5000);
                                        return of(null); 
                                      }
                                  ));  
  }
  registrarEjecucion (ticket: Ticket): any {

    let url: string =  environment.APITicketCore + "/atenciones/registrarEjecucion";
    return this.httpClient.post(url, ticket, this.httpOptions)
                   .pipe(catchError(err => 
                                      { 
                                        console.log("ERROR NO CONTROLADO: " + err.message);
                                        notify({
                                          message: "Se presento un error al conectarse con el servidor! ERROR: " + err.message,
                                          position: {
                                              my: "center middle",
                                              at: "center middle"
                                          }, width: 500, 
                                        }, "error", 5000);
                                        return of(null); 
                                      }
                                  ));  
  }      
  registrarAprobacion (ticket: Ticket) : any{

    let url: string =  environment.APITicketCore + "/atenciones/registrarAprobacion";
    return this.httpClient.post(url, ticket, this.httpOptions)
                   .pipe(catchError(err => 
                                      { 
                                        console.log("ERROR NO CONTROLADO: " + err.message);
                                        notify({
                                          message: "Se presento un error al conectarse con el servidor! ERROR: " + err.message,
                                          position: {
                                              my: "center middle",
                                              at: "center middle"
                                          }, width: 500, 
                                        }, "error", 5000);
                                        return of(null); 
                                      }
                                  ));  
  }  
  enviarCotizacionRegistrada (cotizacion: Proveedor): any {

    let url: string =  environment.APITicketCore + "/atenciones/enviarCotizacionRegistrada";
    return this.httpClient.post(url, cotizacion, this.httpOptions)
                   .pipe(catchError(err => 
                                      { 
                                        console.log("ERROR NO CONTROLADO: " + err.message);
                                        notify({
                                          message: "Se presento un error al conectarse con el servidor! ERROR: " + err.message,
                                          position: {
                                              my: "center middle",
                                              at: "center middle"
                                          }, width: 500, 
                                        }, "error", 5000);
                                        return of(null); 
                                      }
                                  ));  
  }  
  eliminarCotizacionRegistrada (cotizacion: Proveedor) : any{

    let url: string =  environment.APITicketCore + "/atenciones/eliminarCotizacionRegistrada";
    return this.httpClient.post(url, cotizacion, this.httpOptions)
                   .pipe(catchError(err => 
                                      { 
                                        console.log("ERROR NO CONTROLADO: " + err.message);
                                        notify({
                                          message: "Se presento un error al conectarse con el servidor! ERROR: " + err.message,
                                          position: {
                                              my: "center middle",
                                              at: "center middle"
                                          }, width: 500, 
                                        }, "error", 5000);
                                        return of(null); 
                                      }
                                  ));  
  }   
  extenderPlazoCotizacion (atencion: Atencion) : any{

    let url: string =  environment.APITicketCore + "/atenciones/extenderPlazoCotizacion";
    return this.httpClient.post(url, atencion, this.httpOptions)
                   .pipe(catchError(err => 
                                      { 
                                        console.log("ERROR NO CONTROLADO: " + err.message);
                                        notify({
                                          message: "Se presento un error al conectarse con el servidor! ERROR: " + err.message,
                                          position: {
                                              my: "center middle",
                                              at: "center middle"
                                          }, width: 500, 
                                        }, "error", 5000);
                                        return of(null); 
                                      }
                                  ));  
  }     
  registrarActa (atencion: Atencion) : any{

    let url: string =  environment.APITicketCore + "/atenciones/registrarActaAprobacion";
    return this.httpClient.post(url, atencion, this.httpOptions)
                   .pipe(catchError(err => 
                                      { 
                                        console.log("ERROR NO CONTROLADO: " + err.message);
                                        notify({
                                          message: "Se presento un error al conectarse con el servidor! ERROR: " + err.message,
                                          position: {
                                              my: "center middle",
                                              at: "center middle"
                                          }, width: 500, 
                                        }, "error", 5000);
                                        return of(null); 
                                      }
                                  ));  
  }   
  cerrarEjecucion (atencion: Atencion) : any{

    let url: string =  environment.APITicketCore + "/atenciones/cerrarEjecucionAtencion";
    return this.httpClient.post(url, atencion, this.httpOptions)
                   .pipe(catchError(err => 
                                      { 
                                        console.log("ERROR NO CONTROLADO: " + err.message);
                                        notify({
                                          message: "Se presento un error al conectarse con el servidor! ERROR: " + err.message,
                                          position: {
                                              my: "center middle",
                                              at: "center middle"
                                          }, width: 500, 
                                        }, "error", 5000);
                                        return of(null); 
                                      }
                                  ));  
  }  
  cerrarAprobacion (atencion: Atencion): any {

    let url: string =  environment.APITicketCore + "/atenciones/cerrarAprobacionAtencion";
    return this.httpClient.post(url, atencion, this.httpOptions)
                   .pipe(catchError(err => 
                                      { 
                                        console.log("ERROR NO CONTROLADO: " + err.message);
                                        notify({
                                          message: "Se presento un error al conectarse con el servidor! ERROR: " + err.message,
                                          position: {
                                              my: "center middle",
                                              at: "center middle"
                                          }, width: 500, 
                                        }, "error", 5000);
                                        return of(null); 
                                      }
                                  ));  
  }  
  registrarCancelarAtencion (atencion: Atencion) : any{

    let url: string =  environment.APITicketCore + "/atenciones/cancelarAtencion";
    return this.httpClient.post(url, atencion, this.httpOptions)
                   .pipe(catchError(err => 
                                      { 
                                        console.log("ERROR NO CONTROLADO: " + err.message);
                                        notify({
                                          message: "Se presento un error al conectarse con el servidor! ERROR: " + err.message,
                                          position: {
                                              my: "center middle",
                                              at: "center middle"
                                          }, width: 500, 
                                        }, "error", 5000);
                                        return of(null); 
                                      }
                                  ));  
  }   
}