import { Component, ViewChild } from '@angular/core';
import { DxFileUploaderComponent, DxDataGridComponent, DxTreeViewComponent, DxFormComponent } from 'devextreme-angular';
import { HttpClient} from "@angular/common/http";
import CustomStore from 'devextreme/data/custom_store';
import notify from 'devextreme/ui/notify';
import { Router, ActivatedRoute} from '@angular/router';
import config from 'devextreme/core/config';
import { environment } from '../../../../environments/environment';
import { AuthService } from '../../../shared/services/auth.service';
import { SupervisionService } from 'src/app/shared/services/supervision.service';
import { Local } from 'src/app/models/Local';
import { DashBoardsService, ChartEvaluacion } from '../../../shared/services/dashboard.service';
import { Especialidad } from 'src/app/models/Especialidad';
import { Zona } from 'src/app/models/Zona';
import { SubZona } from 'src/app/models/SubZona';
import * as AspNetData from "devextreme-aspnet-data-nojquery";
import { ImagenTicket } from 'src/app/models/ImagenTicket';
import $ from "jquery";
import { Ticket } from 'src/app/models/Ticket';

config({
    floatingActionButtonConfig: {
        icon: 'rowfield',
        position: {
            of: 'tvLocal',
            my: 'right bottom',
            at: 'right bottom',
            offset: '-16 -16'
        }
    }
  });

@Component({
    selector: 'adm-evaluacion-supervision',
    templateUrl: 'adm-evaluacion.component.html',
    styleUrls: ['adm-evaluacion.component.css']
})

export class AdmEvaluacionComponent {
    comentariosPattern: any = /^[^\/]+$/;
    local = new Local(); 
    especialidad = new Especialidad();
    zona = new Zona();
    subZona = new SubZona();
    activo= new SubZona();

    visibleDatosLocales = true;
    visibleDatosZona = false;
    visibleDatosSubZona = false;
    visibleDatosActSeleccionado = false;
    visibleDatosSoluciones = false;
    visibleDatosEspecialidad = false;
    visibleGridFalencias = false;
    visibleDatosActivos = false;
    visibleGraficos = true;
    visibleGridAtributos = false;

    dsFalencias: any;
    dsSoluciones: any;
    dsImagenes: any;
    dsImagenesFalencia: any;
    dsAtributos: any;
    createChildren: any;
    fotosLocales: any;

    popupImagenesVisible = false;
    popupRegistroVisible = false;
    mostrarFileUploader = false;
    visibleImgLocal = true; 
    visibleImgActivo = false; 
    currentItem: any; 
   
    solucionesSZDataMap: object = {};
    solucionesSZActDataMap: object = {};

    selectedRowIndex = -1;
    idFalenciaSeleccionada = -1;
    dataFalenciaSeleccionada: any;
    tituloImagenes = "";
    tituloRegistro = ""
    seleccionoActivo: any;
    imagenInicial = 0;
    urlUpLoad: string;
    fileSeleccionados: any[] = [];

    dsChartFalencias: ChartEvaluacion[];
    dsChartPuntaje: ChartEvaluacion[];
    iconName="chart";
    iconNameAtributos ="cart";
    idCliente = 0;
    idLocalSupervision = 0;

    //VARIABLES DE LA BANDEJA
    pSoloObservados: any;
    pIdZG: any; 
    pIdRonda: number;

    @ViewChild('gridFalencias', { static: false }) gridFalencias: DxDataGridComponent;
    @ViewChild('uploaderImagen', { static: false }) uploaderImagen: DxFileUploaderComponent;
    @ViewChild('formLocal', { static: false }) formLocal: DxFormComponent;
    @ViewChild('formActivoSeleccionado', { static: false }) formActivoSeleccionado: DxFormComponent;

    constructor(private httpClient: HttpClient, private supervisionService: SupervisionService,
                                                private router: Router, private authService: AuthService,
                                                private service: DashBoardsService, private route: ActivatedRoute) {

        this.idCliente = this.authService.usuarioLogueado.idClienteSeleccionado;
        this.ocultarMostrarGraficos = this.ocultarMostrarGraficos.bind(this);
        this.ocultarMostrarAtributos = this.ocultarMostrarAtributos.bind(this);
       
        this.urlUpLoad = environment.APICargaArchivo + environment.urlUpLoad;
                                                   
        //RECUPERO LOS VALORES ENVIADOS COMO PARAMTROS
        this.pSoloObservados = this.route.snapshot.paramMap.get('SO');
        this.pIdRonda = +this.route.snapshot.paramMap.get('Rda');
        this.pIdZG = this.route.snapshot.paramMap.get('ZnaGeo');
        this.idLocalSupervision = +this.route.snapshot.paramMap.get('LSup');

        this.supervisionService.leerLocalSupervisionById(this.idLocalSupervision).subscribe(data => {
                                    this.local.IdLocalSupervision = this.idLocalSupervision;
                                    this.local.IdLocal = data.data.idLocal;
                                    this.local.DescripcionLocal = data.data.descripcionLocal;
                                    this.local.PorcentajeText = (data.data.puntaje * 100).toFixed(2) + "%";
                                    this.local.Responsable = data.data.descripcionSupervisor;
                                    this.local.ContactoLocal = data.data.contactoLocal;
                                    this.local.Direccion = data.data.direccion;
                                    this.local.DescripcionUbigeo = data.data.descripcionUbigeo;
                                    this.local.FechaInicioText = data.data.fechaInicioText;
                                    this.local.FechaSyncDownText = data.data.fechaSyncDownText; 
                                    this.local.Comentarios = data.data.comentarios;
                                    this.local.NombreImagen = data.data.nombreImagen;
                                    this.local.PathURLImagen = data.data.pathURLImagen;
                                });
        
        //VARIABLES PARA LOS GRAFICOS ESTADISTICOS
        this.supervisionService.listarChartFalenciasLSup(this.idLocalSupervision, this.idCliente).subscribe(data => {
            this.dsChartFalencias = data.items;
            this.dsChartPuntaje = data.items
        }); 

        //CREACION DE LOS NODOS DEL ARBOL
        this.createChildren = (parent) => {
                let parentId = parent ? parent.itemData.id : this.idLocalSupervision;
                let pTipo = parent ? parent.itemData.tipo : "raiz";
                return supervisionService.listarJerarquiaEvaluacionByLocal(parentId, pTipo, this.pSoloObservados).toPromise();
        }        
    }
    ocultarMostrarGraficos(evt){
        if (this.visibleGraficos) {
            this.visibleGraficos = false; 
            this.visibleGridFalencias = true;
            this.iconName= "chart";
        }
        else  {
            this.visibleGraficos = true;
            this.visibleGridFalencias = false;
            this.iconName= "columnfield";
        }
    }
    ocultarMostrarAtributos(evt) {
        if (this.visibleGridAtributos) {
            this.visibleGridAtributos = false; 
            this.visibleGridFalencias = true;
            this.iconNameAtributos= "cart";
        }
        else  {
            this.visibleGridAtributos = true;
            this.visibleGridFalencias = false;
            this.iconNameAtributos= "columnfield";
        }        
    }
    sendRequest(url: string, method: string, data: any = {}): any {

        let result;
    
        switch(method) {
            case "LOAD":
                result = this.httpClient.get(url);
                break;
            case "REMOVE":
                break;
        }
    
        return result
            .toPromise()
            .then((data: any) => {
                if (method === "REMOVE") { 
                  if (data.codigo != 'OK') {
                      notify({
                        message: data.mensaje,
                        position: {
                            my: "center center",
                            at: "center center"
                        }, width: 500, 
                      }, "error", 2000);
                  }
                } 
                return data;
            })
            .catch(e => {
                throw e && e.error && e.error.Message;
            });
    }   
    screen(width) {
        return ( width < 700 ) ? 'sm' : 'lg';
    }  
    selectItem(evt) {

        this.currentItem = evt.itemData;

        if (this.currentItem.tipo == "local" || this.currentItem.tipo == "falencias") {
            this.visibleDatosLocales = true;
            this.visibleDatosEspecialidad = false;
            this.visibleDatosZona = false;
            this.visibleDatosSubZona = false;
            this.visibleDatosActivos = false;
            this.visibleDatosActSeleccionado = false;
            this.visibleGridFalencias = false;
            this.visibleGridAtributos = false;             
            this.visibleImgLocal = true; 
            this.visibleImgActivo = false; 
            this.visibleGraficos = true; 
            this.idFalenciaSeleccionada = 0;
            this.supervisionService.listarChartFalenciasLSup(this.idLocalSupervision, this.idCliente).subscribe(data => {
                this.dsChartFalencias = data.items;
                this.dsChartPuntaje = data.items
            });            
        } else if (this.currentItem.tipo == "especialidad") {
            this.visibleDatosEspecialidad = true;
            this.visibleDatosLocales = false;
            this.visibleDatosZona = false;      
            this.visibleDatosSubZona = false;      
            this.visibleDatosActivos = false;
            this.visibleDatosActSeleccionado = false;
            this.visibleGridFalencias = false;
            this.visibleGridAtributos = false; 
            this.visibleImgLocal = false; 
            this.visibleImgActivo = false; 
            this.especialidad.DescripcionEspecialidad = this.currentItem.descripcion;
            this.especialidad.PuntajeText = (this.currentItem.puntaje * 100).toFixed(2) + "%";
            this.visibleGraficos = true; 
            this.idFalenciaSeleccionada = 0;
            this.supervisionService.listarChartLSupEspecialidad(this.idLocalSupervision, this.idCliente, evt.node.key).subscribe(data => {
                this.dsChartFalencias = data.items;
                this.dsChartPuntaje = data.items
            });            
        } else if (this.currentItem.tipo == "zona") {
            this.visibleGraficos = true; 
            this.visibleDatosZona = true;
            this.visibleDatosSubZona = false;
            this.visibleDatosLocales = false;
            this.visibleDatosEspecialidad = false;
            this.visibleDatosActivos = false;
            this.visibleDatosActSeleccionado = false;
            this.visibleGridFalencias = false;
            this.visibleGridAtributos = false; 
            this.visibleImgLocal = false; 
            this.visibleImgActivo = false; 
            this.zona.DescripcionZona = this.currentItem.text;
            this.zona.PuntajeText = (this.currentItem.puntaje * 100).toFixed(2) + "%";
            this.idFalenciaSeleccionada = 0;
            this.supervisionService.listarChartZonaEspecialidad(this.idLocalSupervision, this.idCliente, evt.node.key).subscribe(data => {
                this.dsChartFalencias = data.items;
                this.dsChartPuntaje = data.items
            });  
        } else if (this.currentItem.tipo == "subZona") {
            this.visibleDatosZona = false;
            this.visibleDatosSubZona = true;
            this.visibleDatosLocales = false;
            this.visibleDatosEspecialidad = false;
            this.visibleDatosActivos = false;
            this.visibleDatosActSeleccionado = false;
            this.visibleGridFalencias = true;
            this.visibleGridAtributos = false; 
            this.visibleImgLocal = false; 
            this.visibleImgActivo = false; 
            this.visibleGraficos = false;    
            this.subZona.DescripcionSubZona = this.currentItem.text;
            this.subZona.PuntajeText = (this.currentItem.puntaje * 100).toFixed(2) + "%";            
            let url = environment.APISupervision + "/evaluacion/listarFalenciasBySubZonaSup"
                                                 + "?pIdFiltro=" + evt.node.key
                                                 + "&pSoloObservados=" + this.pSoloObservados; 
            this.dsFalencias = new CustomStore({
                            key: "idFalenciaSubZonaSup",
                            load: () => this.sendRequest(url, "LOAD", {})
                          });

            this.gridFalencias.instance.clearSelection();
            this.dsImagenes = [];
            this.seleccionoActivo = false;   
            this.idFalenciaSeleccionada = 0;
            this.supervisionService.listarChartSubZonaEspecialidad(this.idLocalSupervision, this.idCliente, evt.node.key).subscribe(data => {
                this.dsChartFalencias = data.items;
                this.dsChartPuntaje = data.items
            });             
        } else if (this.currentItem.tipo == "activos") {
            
            this.visibleGraficos = false;  //POR DEFINIR
            this.visibleDatosActivos = false; //POR DEFINIR

            this.visibleDatosActSeleccionado = false;
            this.visibleDatosSubZona = false;
            this.visibleDatosZona = false;
            this.visibleImgLocal = false; 
            this.visibleImgActivo = false; 
            this.visibleDatosLocales = false;
            this.visibleDatosEspecialidad = false;
            this.visibleGridFalencias = false;
            this.visibleGridAtributos = false; 
            this.activo.DescripcionSubZona = evt.node.parent.text;
            this.activo.PuntajeText = (this.currentItem.puntaje * 100).toFixed(2) + "%";
            this.idFalenciaSeleccionada = 0;
        } else if (this.currentItem.tipo == "activo") {
            this.visibleGridFalencias = true;
            this.visibleImgActivo = true; 
            this.visibleDatosActSeleccionado = true;

            this.visibleGridAtributos = false; 
            this.visibleDatosActivos = false;
            this.visibleGraficos = false; 
            this.visibleDatosLocales = false;            
            this.visibleDatosZona = false;
            this.visibleDatosSubZona = false;
            this.visibleDatosEspecialidad = false;
            this.visibleImgLocal = false; 
            this.activo.DescripcionSubZona = evt.node.text;
            this.activo.PuntajeText = (this.currentItem.puntaje * 100).toFixed(2) + "%";
            this.activo.NombreImagen = this.currentItem.nombreImagen;
            this.activo.PathURLImagen = this.currentItem.pathURLImagen;

            //OBTENGO LAS FALENCIAS DEL ACTIVO
            let url = environment.APISupervision + "/evaluacion/listarFalByActSZEspSup"
                                                 + "?pIdFiltro=" + evt.node.key
                                                 + "&pSoloObservados=" + this.pSoloObservados;  
            this.dsFalencias = new CustomStore({
                            key: "idFalenciaSubZonaSup",
                            load: () => this.sendRequest(url, "LOAD", {})
                          });

            //OBTENGO LOS ATRIBUTOS DEL ACTIVO 
            let urlAtributos = environment.APIAdministracion + "/activoscliente/listarAtributosByIdActivo"
                                                 + "?pIdFiltro=" + evt.node.key;  
            this.dsAtributos = new CustomStore({
                            key: "idAtributoActivo",
                            load: () => this.sendRequest(urlAtributos, "LOAD", {})
                          });

            //LIMPIO LOS VALORES              
            this.gridFalencias.instance.clearSelection();
            this.dsImagenes = []; 
            this.seleccionoActivo = true;
            this.idFalenciaSeleccionada = 0;
        }
    }
    obtenerSoluciones(pIdFalenciaSupervision: number): any {
        if (this.seleccionoActivo) {
            return this.solucionesSZActDataMap[pIdFalenciaSupervision] = this.solucionesSZActDataMap[pIdFalenciaSupervision] || {
                store: AspNetData.createStore({
                    key: "idSolucion",
                    loadParams: { pIdFalenciaSupervision: pIdFalenciaSupervision, pSoloObservados: this.pSoloObservados },
                    loadUrl:  environment.APISupervision + "/evaluacion/listarSolByIdFalSZActivo"
                })
            };
        } else {
            return this.solucionesSZDataMap[pIdFalenciaSupervision] = this.solucionesSZDataMap[pIdFalenciaSupervision] || {
                store: AspNetData.createStore({
                    key: "idSolucion",
                    loadParams: { pIdFalenciaSupervision: pIdFalenciaSupervision, pSoloObservados: this.pSoloObservados},
                    loadUrl:  environment.APISupervision + "/evaluacion/listarSolucionesByIdFalSZ"
                })
            };            
        }
    }
    selectedChanged(e) {
        this.selectedRowIndex = e.component.getRowIndexByKey(e.selectedRowKeys[0]);
        this.idFalenciaSeleccionada = e.selectedRowKeys[0];
        this.dataFalenciaSeleccionada = e.selectedRowsData[0];
    }
    verImagenesClick() {

        if (this.idFalenciaSeleccionada <= 0) {
            notify({
                message: "Debe seleccionar una falencia para poder visualizar la evidencia!" ,
                position: {
                my: "center center",
                at: "center center"
                }, width: 300, 
                }, "error", 1500); 
        } else {
            this.imagenInicial = 0;
            this.tituloImagenes = "Falencia: " + this.dataFalenciaSeleccionada.descripcionFalencia;
            this.cargarImagenesFalencia();
            this.popupImagenesVisible = true;
        }
    } 
    cargarImagenesFalencia() {
        if (this.idFalenciaSeleccionada) {
            if (this.seleccionoActivo == false) {
                this.supervisionService.listarImagenesFSZ(this.idFalenciaSeleccionada).toPromise().then(data => {
                    let imagenesTotal: ImagenTicket[] = [] ;
                    data.forEach(function (value) {
                        imagenesTotal.push({'PathURLImagen': environment.EvidenciaMINTTO + "/" + value.pathURLImagen, 'Nombre' : value.nombre, 'Tipo' : '' });
                    });   
                    this.dsImagenes = imagenesTotal;
                });  
            } else {
                this.supervisionService.listarImagenesFActSZ(this.idFalenciaSeleccionada).toPromise().then(data => {
                    let imagenesTotal: ImagenTicket[] = [] ;
                    data.forEach(function (value) {
                        imagenesTotal.push({'PathURLImagen': environment.EvidenciaMINTTO + "/" + value.pathURLImagen, 'Nombre' : value.nombre, 'Tipo' : '' });
                    });   
                    this.dsImagenes = imagenesTotal;
                }); 
            }
        } 
    }
    modificarImagenesClick() {
        if (this.idFalenciaSeleccionada <= 0) {
            notify({
                message: "Debe seleccionar una falencia para poder visualizar la evidencia!" ,
                position: {
                my: "center center",
                at: "center center"
                }, width: 300, 
                }, "error", 1500); 
        } else {
            this.cargarImagenesFalencia();

            if (this.dsImagenes.length >= 3) {
            this.mostrarFileUploader = false;
            } else {
            this.mostrarFileUploader = true;
            }

            this.uploaderImagen.instance.reset();
            this.tituloRegistro = "Falencia: " + this.dataFalenciaSeleccionada.descripcionFalencia;
            this.popupRegistroVisible = true;
        }
    }
    actualizarImagenClick(evt) {
        if (this.fileSeleccionados.length <= 0 && this.dsImagenes.length <= 0) {
            notify({
                message: "Debe registrar por lo menos un archivo de evidencia",
                position: {
                    my: "center center",
                    at: "center center"
                }, width: 500, 
                }, "error", 2000);  
            return;
        }

        let imagenesTotal: ImagenTicket[] = [] ;
        this.dsImagenes.forEach(function (value) {
            imagenesTotal.push({'PathURLImagen': value.Nombre, 'Nombre' : value.Nombre, 'Tipo' : '' });
        }); 

        this.fileSeleccionados.forEach(function (value) {
            imagenesTotal.push({'PathURLImagen': value.name, 'Nombre' : value.name, 'Tipo' : '' });
        }); 

        if (imagenesTotal.length > 3) {
            notify({
                message: "Solo puede subir tres imagenes como evidencia debe eliminar alguna de las registradas!",
                position: {
                    my: "center center",
                    at: "center center"
                }, width: 500, 
            }, "error", 2000);  
            return;
        } 

        let falencia = new Ticket();
        falencia.ImagenesTicket = imagenesTotal;  
        let idUsuario = this.authService.usuarioLogueado.idUsuario;
        falencia.IdUsuarioCreacion = idUsuario;
        falencia.IdFalenciaSupervision = this.idFalenciaSeleccionada;
        falencia.ActivoSeleccionado = this.seleccionoActivo;

        this.supervisionService.registrarImagenesFalencia(falencia).subscribe(data => {
                        if (data.codigo == "OK") {
                            //SUBIR LOS ARCHIVOS AL SERVIDOR
                            if (this.fileSeleccionados.length > 0) {
                                this.subirArchivos(data.rutaEvidencia);
                            } else {
                                notify({
                                    message: "Grabación Correcta!" ,
                                    position: {
                                               my: "center middle",
                                               at: "center middle"
                                              }, width: 300, 
                                    }, "success", 1500);  
                                this.cerrarPopupRegistroClick();                                            
                            } 
                        } else {
                            notify({
                                    message: data.mensaje ,
                                    position: {
                                        my: "center middle",
                                        at: "center middle"
                                    }, width: 300, 
                                }, "error", 5000);
                        }    
                });
    }
    cerrarPopupRegistroClick() {
        this.popupRegistroVisible = false;
    }
    subirArchivos (ruta: string) {
        let headers = {ruta: ruta};
        this.uploaderImagen.instance.option("uploadHeaders", headers);  
        (<any> this.uploaderImagen.instance)._uploadFiles();  
    }
    cargaFinalizada () {
        //ENVIAR NOTIFICACION TICKET AGREGADO
        notify({
            message: "Grabación Correcta!" ,
            position: {
            my: "center center",
            at: "center center"
            }, width: 300, 
            }, "success", 1500); 

        if (this.seleccionoActivo == false) {
            this.supervisionService.listarImagenesFSZ(this.idFalenciaSeleccionada).toPromise().then(data => {
                let imagenesTotal: ImagenTicket[] = [] ;
                data.forEach(function (value) {
                    imagenesTotal.push({'PathURLImagen': environment.EvidenciaMINTTO + "/" + value.pathURLImagen, 'Nombre' : value.nombre, 'Tipo' : '' });
                });   
                this.dsImagenes = imagenesTotal;
            });  
        } else {
            this.supervisionService.listarImagenesFActSZ(this.idFalenciaSeleccionada).toPromise().then(data => {
                let imagenesTotal: ImagenTicket[] = [] ;
                data.forEach(function (value) {
                    imagenesTotal.push({'PathURLImagen': environment.EvidenciaMINTTO + "/" + value.pathURLImagen, 'Nombre' : value.nombre, 'Tipo' : '' });
                });   
                this.dsImagenes = imagenesTotal;
            }); 
        }            
        this.cerrarPopupRegistroClick();
    }
    eliminoImagen() {
        this.mostrarFileUploader = true;
    }
    asignarValoresUpLoadFile (e) {
        $(".dx-fileuploader-upload-button").css({"visibility":"hidden"});
    }
    regresar() {
        this.router.navigate(["eva-sup-vigente-bandeja", { SO: this.pSoloObservados, 
            Rda: this.pIdRonda, 
            ZnaGeo: this.pIdZG }]);
    }
    verImagenesLocalActivo(){
        this.imagenInicial = 0;
        let imagenesTotal: ImagenTicket[] = [] ;

        if (this.visibleDatosLocales) {
            this.local = this.formLocal.instance.option("formData");
            this.tituloImagenes = "Fachada: " + this.local.DescripcionLocal;
            imagenesTotal.push({'PathURLImagen': environment.EvidenciaMINTTO +  '/' + this.local.PathURLImagen, 'Nombre' : this.local.NombreImagen, 'Tipo' : '' });
            
        } else {
            this.activo = this.formActivoSeleccionado.instance.option("formData");
            this.tituloImagenes = "Activo: " + this.activo.DescripcionSubZona;
            imagenesTotal.push({'PathURLImagen': environment.EvidenciaMINTTO +  '/' + this.activo.PathURLImagen, 'Nombre' : this.activo.NombreImagen, 'Tipo' : '' });
        }

        this.dsImagenes = imagenesTotal;
        this.popupImagenesVisible = true;        
    }
    customizeLabelText = (info: any) => {
        return info.valueText + "%";
    }    
}