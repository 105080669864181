import { Component, ViewChild } from '@angular/core';
import { HttpClient, HttpParams } from "@angular/common/http";
import CustomStore from 'devextreme/data/custom_store';
import config from 'devextreme/core/config';
import * as AspNetData from "devextreme-aspnet-data-nojquery";
import notify from 'devextreme/ui/notify';
import { DxDataGridComponent, DxTabPanelComponent, DxFormComponent } from 'devextreme-angular';
import { AuthService } from 'src/app/shared/services';
import { environment } from '../../../../environments/environment';
import { TicketService } from '../../../shared/services/ticket.service';
import { ImagenTicket } from '../../../models/ImagenTicket';
import { Ticket } from 'src/app/models/Ticket';
import { Atencion } from 'src/app/models/Atencion';
import { ParametricasService } from 'src/app/shared/services/parametricas.service';
import ArrayStore from 'devextreme/data/array_store';
import { Proveedor } from 'src/app/models/Proveedor';
import { ProveedorService } from 'src/app/shared/services/proveedor.service';
import { AtencionService } from 'src/app/shared/services/atencion.service';
import { AdmTicketComponent } from '../adms/adm-ticket.component';

config({
  floatingActionButtonConfig: {
      icon: 'rowfield',
      position: {
          of: '#tabsGeneral',
          my: 'right bottom',
          at: 'right bottom',
          offset: '-16 -16'
      }
  }
}); 

@Component({
  templateUrl: 'atender-tickets-bandeja.component.html',
  styleUrls: ['atender-tickets-bandeja.component.css']
})

export class AtenderTicketsBandejaComponent {
  @ViewChild('formAtencion', { static: false }) formAtencion: DxFormComponent;  
  @ViewChild('formRechazo', { static: false }) formRechazo: DxFormComponent;  
  @ViewChild(DxTabPanelComponent, { static: false }) tabsGeneral: DxTabPanelComponent;
  @ViewChild(DxDataGridComponent, { static: false }) gridTicketsLibres: DxDataGridComponent;
  @ViewChild('gridProveedoresSeleccionados', { static: false }) gridProveedoresSeleccionados: DxDataGridComponent;
  @ViewChild(AdmTicketComponent, { static: false }) admTicket: AdmTicketComponent;

  dsTicket: any;
  dsTicketSeleccionados:any;
  dsPrvSeleccionados: Proveedor[] = [];
  dsImagenesTck: ImagenTicket[];
  ticket = new Ticket();
  atencion = new Atencion();
  tiposAsignacion: any;
  usuariosAsignacion: any;
  proveedores: any;
  selectedRowIndexLibres = -1;
  selectedRowIndexSup = -1;
  popupVisible = false;
  popupImagenesVisible = false;
  popupRechazarVisible = false;
  popupAtencionVisible = false;  
  tituloRegistro = "";
  tituloVerImagenes = "";
  imagenInicial = 0;
  tituloRechazarTicket = "";
  dataTicketSelected:any;
  descripcionFalencia="";
  dsSoluciones:any;
  tituloDetalleFalencia="";
  urlDetalleTicket="";
  idTicketSelected= -1;
  popupDetFalenciaVisible = false;
  botonGrabarAtencionDisabled = false;
  botonRechazarDisabled = false;
  loadingVisible = false;

  isTicketEditable = false;

  constructor(private httpClient: HttpClient, private authService: AuthService, 
                                              private tckService: TicketService, private paramSevice: ParametricasService, 
                                              private prvService: ProveedorService, private ateService: AtencionService) {
    let idCliente = this.authService.usuarioLogueado.idClienteSeleccionado;
    this.verImagenesClick = this.verImagenesClick.bind(this);
    this.rechazarTicketClick = this.rechazarTicketClick.bind(this);
    this.onChangeTipoAsignacion = this.onChangeTipoAsignacion.bind(this);
    this.verDetalleFalencia = this.verDetalleFalencia.bind(this);
    this.urlDetalleTicket = environment.APITicketCore + "/maestros/listaSolByFalenciaSup";

    this.repoblar();

    paramSevice.listarTipoAsignacionTicket(false).toPromise().then(data => {
                            this.tiposAsignacion = new ArrayStore({
                                data: data,
                                key: "idTipo"
                            }); 
                        });

    authService.listarUsrAsigTicket(idCliente).toPromise().then(data => {
                        if (data.codigo == "OK") {
                           this.usuariosAsignacion =  new ArrayStore({
                                                  data: data.items,
                                                  key: "idUsuario"
                                              }); 
                        } else {
                          notify({
                            message: "Se presento un error al realizar la consulta en el servidor! ERROR: " + data.mensaje,
                            position: {
                                my: "center bottom",
                                at: "center bottom"
                            }, width: 500, 
                          }, "error", 5000);                          
                        }
                  });    
                  
    prvService.listarProveedoresHabiles().toPromise().then(data => {
                       this.proveedores =  new ArrayStore({
                                           data: data,
                                           key: "idProveedor"
                                        }); 
                  });
  }

  repoblar() {
    let idCliente = this.authService.usuarioLogueado.idClienteSeleccionado;
    let loadParams = { idCliente: idCliente,  idUsuario: 0} //USUARIO = 0, PARA OBTENER TODOS LOS TICKETS DE TODOS LOS USUARIOS DEL CLIENTE
    this.dsTicket = new CustomStore({
                    key: "idTicket",
                    load: () => this.sendRequest(environment.APITicketCore + "/consultas/listaTicketsPendientes", "LOAD", loadParams)
                  });
  }

  sendRequest(url: string, method: string, data: any = {}): any {

    let result;

    switch(method) {
        case "LOAD":
            url = url + "?idCliente=" + data.idCliente + "&idUsuario=" + data.idUsuario; 
            result = this.httpClient.get(url);
            break;
    }

    return result
        .toPromise()
        .then((data: any) => {
            return data;
        })
        .catch(e => {
            throw e && e.error && e.error.Message;
        });
  }
  actualizarRechazo() {
    if (this.formRechazo.instance.validate().isValid) {
       this.botonRechazarDisabled = true; 
       this.loadingVisible = true;
       this.ticket = this.formRechazo.instance.option("formData");
       this.ticket.IdUsuarioSolicitante = this.authService.usuarioLogueado.idUsuario;
       this.tckService.registrarRechazo(this.ticket).toPromise().then(data => {
                              if (data.codigo == "OK") {
                                  notify({
                                      message: "Grabación Correcta!" ,
                                      position: {
                                      my: "center center",
                                      at: "center center"
                                    }, width: 300, }, "success", 1500);  
                                    this.repoblar();
                                    this.ocultarPopupClick();  
                                    this.botonRechazarDisabled = false; 
                                    this.loadingVisible = false;                                                                              
                              } else {
                                  this.botonRechazarDisabled = false; 
                                  this.loadingVisible = false; 
                                  notify({
                                      message: data.mensaje ,
                                      position: {
                                          my: "center center",
                                          at: "center center"
                                      }, width: 300, 
                                  }, "error", 5000);
                              }    
                          });
    } 
  }
  rechazarTicketClick(evt) {
    this.formRechazo.instance.resetValues();
    let idTicket = evt.row.data.idTicket;
    this.ticket.IdTicket = idTicket;
    this.tituloRechazarTicket = "Rechazar Ticket: " + idTicket;
    
    //COLOCO EL FOCO EN EL PRIMER CAMPO
    let that = this;
    setTimeout(function() {
        that.formRechazo.instance.getEditor("ComentariosRechazo").focus();
    }, 500); 
    
    this.popupRechazarVisible = true;

  }
  crearAtencionClick() {

    this.dsTicketSeleccionados = this.gridTicketsLibres.instance.getSelectedRowsData();
    if (this.dsTicketSeleccionados.length > 0) {
      this.formAtencion.instance.resetValues();
    
      this.dsPrvSeleccionados = Array<Proveedor>();
      this.popupAtencionVisible = true;
      this.atencion.CodigoTipoAsignacion = 'INTE';
      this.atencion.FechaInicio = new Date();
      this.atencion.FechaTermino = new Date();
      if (this.dsTicketSeleccionados.length == 1)
      {
        if (this.dsTicketSeleccionados[0].comentarios != null) {
          if (this.dsTicketSeleccionados[0].comentarios.length > 500) {
            this.atencion.Comentarios = this.dsTicketSeleccionados[0].comentarios.substring(0,500);
          }
          else{
            this.atencion.Comentarios = this.dsTicketSeleccionados[0].comentarios;
          }

          if (this.dsTicketSeleccionados[0].comentarios.length > 100){
            this.atencion.DescripcionAtencion = this.dsTicketSeleccionados[0].comentarios.substring(0,100);
          }
          else{
            this.atencion.DescripcionAtencion = this.dsTicketSeleccionados[0].comentarios;
          }
        }
      }
      
      this.formAtencion.instance.option("formData", this.atencion);

      //COLOCO EL FOCO EN EL PRIMER CAMPO
      let that = this;
      setTimeout(function() {
          that.formAtencion.instance.getEditor("DescripcionAtencion").focus();
      }, 500);       

    } else {
        notify({
          message: "Debe seleccionar algun registro para proceder con la asignación!",
          position: {
              my: "center middle",
              at: "center middle"
          }, width: 500, 
        }, "warning", 3000);        
    } 
  }
  registrarAtencion() {
    if (this.formAtencion.instance.validate().isValid) {

      this.atencion = this.formAtencion.instance.option("formData");
      if (this.atencion.CodigoTipoAsignacion == "INTE" &&  (!this.atencion.IdUsuarioAsignado || this.atencion.IdUsuarioAsignado == null) ) {
          notify({
            message: "Debe seleccionar el Usuario que ejecutara la atención!" ,
            position: {
            my: "center middle",
            at: "center middle"
          }, width: 300, }, "success", 1500);       
          
          return;
      }
      if (this.atencion.CodigoTipoAsignacion == "INTE") {
          let idUsuario = this.atencion.IdUsuarioAsignado;
          let idProveedor = 0;
          (<any> this.usuariosAsignacion)._array.forEach(function (value) {
             if (value.idUsuario == idUsuario) {
              idProveedor = value.idProveedor;
            } 
          });
          this.atencion.IdProveedorAsignado = idProveedor;          
      }

      if (this.atencion.PlazoEntregaCotizacion<=0) {
          notify({
            message: "Se debe registrar un plazo mayo a cero!" ,
            position: {
            my: "center middle",
            at: "center middle"
          }, width: 300, }, "success", 1500);       
          
          return;        
      }

      this.botonGrabarAtencionDisabled = true;
      this.loadingVisible = true;
      this.atencion.IdUsuarioCreacion = this.authService.usuarioLogueado.idUsuario;
      this.atencion.IdCliente = this.authService.usuarioLogueado.idClienteSeleccionado;
      this.dsTicketSeleccionados.forEach(function (value) {
          value.idTicketAtencion = 0;
      });
      this.atencion.Tickets = this.dsTicketSeleccionados;
      this.atencion.Proveedores = this.dsPrvSeleccionados;

      this.ateService.registrarAtencion(this.atencion).subscribe(data => {
                             if (data.codigo == "OK") {
                                 notify({
                                     message: "Grabación Correcta!" ,
                                     position: {
                                     my: "center middle",
                                     at: "center middle"
                                   }, width: 300, }, "success", 1500);  
                                   this.repoblar();
                                   this.ocultarPopupAtencionClick();  
                                   this.botonGrabarAtencionDisabled = false; 
                                   this.loadingVisible = false;                                         
                             } else {
                                 this.botonGrabarAtencionDisabled = false;  
                                 this.loadingVisible = false;
                                 notify({
                                     message: data.mensaje ,
                                     position: {
                                         my: "center middle",
                                         at: "center middle"
                                     }, width: 300, 
                                 }, "error", 5000);
                             }    
                         });
   }
  }
  onChangeTipoAsignacion(evt) {
    if (evt.value == "INTE") {
      this.formAtencion.instance.getEditor('IdUsuarioAsignado').option("disabled", false);
      this.formAtencion.instance.getEditor('PlazoEntregaCotizacion').option("disabled", true);
      this.formAtencion.instance.getEditor('FechaInicio').option("disabled", false);
      this.formAtencion.instance.getEditor('FechaTermino').option("disabled", false);
      this.gridProveedoresSeleccionados.instance.option('disabled', true);
    } else {
      this.formAtencion.instance.getEditor('IdUsuarioAsignado').option("disabled", true);
      this.formAtencion.instance.getEditor('PlazoEntregaCotizacion').option("disabled", false);
      this.formAtencion.instance.getEditor('FechaInicio').option("disabled", true);
      this.formAtencion.instance.getEditor('FechaTermino').option("disabled", true);
      this.gridProveedoresSeleccionados.instance.option('disabled', false);
    }
  }
  obtenerFalencias(pIdTicket: number) {
    //console.log(pIdTicket);
  }
  selectedChangedLibres(e) {
    this.selectedRowIndexLibres = e.component.getRowIndexByKey(e.selectedRowKeys[0]);
    this.dataTicketSelected = e.selectedRowsData[0];
    this.idTicketSelected = e.selectedRowKeys[0];
    this.isTicketEditable = e.selectedRowKeys.length == 1 && this.authService.usuarioLogueado.idRolSeleccionado == 3;
  }
  verImagenesClick(evt) {
    this.imagenInicial = 0;
    if (this.tabsGeneral.selectedIndex == 0) {  //TAB TICKETS LIBRES
      let idTicket = evt.row.data.idTicket;
      this.tituloVerImagenes = "Imagenes Ticket: " + idTicket;
      this.tckService.leerTicketById(idTicket).toPromise().then(data => {
          let imagenes: ImagenTicket[] = [];
          data.data.imagenesTicket.forEach(element => {
                let imagen = new ImagenTicket();
                imagen.PathURLImagen = environment.EvidenciaMINTTO + "/" + element.pathURLImagen;
                imagen.Nombre = element.nombre;
                imagenes.push(imagen)
             });
            this.dsImagenesTck = imagenes;
      });
    } else {

    }
    this.popupImagenesVisible = true;    
  }
  ocultarPopupClick() {
    this.popupRechazarVisible = false;
  }
  ocultarPopupAtencionClick() {
    this.popupAtencionVisible = false;
  }
  isFalenciaIconVisible(e) {
    return e.row.data.codigoOrigenTicket == "SUPE";
  }
  verDetalleFalencia() {
    
    if (this.idTicketSelected > 0 && this.dataTicketSelected.idFalenciaSupervision != null) {
      let idFalencia = this.dataTicketSelected.idFalenciaSupervision;
      let esActivo = this.dataTicketSelected.falenciaActivo;
      this.descripcionFalencia = this.dataTicketSelected.comentarios;

      this.dsSoluciones = AspNetData.createStore({
                                  key: "idSolucionFalencia",
                                  loadParams: { pIdFalencia: idFalencia, pEsActivo: esActivo },
                                  loadUrl: this.urlDetalleTicket
                          })

      this.tituloDetalleFalencia = "Detalle de la Falencia Ticket: " + this.idTicketSelected;
      this.popupDetFalenciaVisible = true                        
    } else {
        notify({
          message: "Seleccione el registro primero!",
          position: {
              my: "center center",
              at: "center center"
          }, width: 500, 
        }, "error", 2000);
    } 
  }

  editRow() {
    this.popupVisible = true;
    this.tituloRegistro = "Modificar Ticket: " + this.idTicketSelected;
    let permitirModificar = false;
    this.admTicket.inicializarControlesGestor(this.idTicketSelected, permitirModificar);
  }

  ocultarPopup() {
    this.popupVisible = false;
  }
}