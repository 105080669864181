import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { catchError } from 'rxjs/operators';
import { of } from 'zen-observable';
import notify from 'devextreme/ui/notify';
import { environment } from '../../../environments/environment';
import { Cliente } from 'src/app/models/Cliente';

export class JerarquiLZ {
    idZona: string;
    nombre: string;
    expanded?: boolean;
    idLocal?: string;
    icon?: string;
    tipo:string;
} 

@Injectable()
export class ClienteService {
  
  private httpOptions: any;

  constructor(private httpClient: HttpClient) {
    this.httpOptions = {
      headers: new HttpHeaders({'Content-Type': 'application/json'})
    };
  }

  leerClienteById(pIdCliente: number): any {
     let urlPrioridades: string = environment.APIAdministracion + "/clientes/" + pIdCliente;
     return this.httpClient.get(urlPrioridades)
                   .pipe(catchError(err => 
                                      { 
                                        console.log(err);
                                        notify({
                                          message: "Se presento un error al conectarse con el servidor! ERROR: " + err.message,
                                          position: {
                                              my: "center bottom",
                                              at: "center bottom"
                                          }, width: 500, 
                                        }, "error", 5000);                                        
                                        return of(null); 
                                      }
                                  )); 
  }
  registrarCliente (cliente: Cliente): any {

    let url: string = environment.APIAdministracion + "/clientes/registrarCliente";
    return this.httpClient.post(url, cliente, this.httpOptions)
                          .pipe(catchError(err => 
                                      { 
                                        console.log("ERROR NO CONTROLADO: " + err.message);
                                        notify({
                                          message: "Se presento un error al conectarse con el servidor! ERROR: " + err.message,
                                          position: {
                                              my: "center bottom",
                                              at: "center bottom"
                                          }, width: 500, 
                                        }, "error", 5000);
                                        return of(null); 
                                      }
                                  ));  
  }
  listarJerarquiaLZByIdCliente(pIdFiltro: number, pTipo: string): any {
    let url: string = environment.APIAdministracion + "/clientes/obtenerArbolJerarquia?pIdFiltro=" + pIdFiltro + "&pTipo=" + pTipo;
    return this.httpClient.get(url)
                  .pipe(catchError(err => 
                                     { 
                                       console.log(err);
                                       notify({
                                         message: "Se presento un error al conectarse con el servidor! ERROR: " + err.message,
                                         position: {
                                             my: "center bottom",
                                             at: "center bottom"
                                         }, width: 500, 
                                       }, "error", 5000);                                        
                                       return of(null); 
                                     }
                                 ));
  }
}