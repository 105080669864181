import { Component, ViewChild } from '@angular/core';
import { HttpClient, HttpParams } from "@angular/common/http";
import CustomStore from 'devextreme/data/custom_store';
import config from 'devextreme/core/config';
import notify from 'devextreme/ui/notify';
import { DxDataGridComponent, DxFormComponent } from 'devextreme-angular';
import { AuthService } from 'src/app/shared/services';
import { TicketService } from '../../../shared/services/ticket.service';
import { ImagenTicket } from '../../../models/ImagenTicket';
import * as AspNetData from "devextreme-aspnet-data-nojquery";
import { environment } from 'src/environments/environment';
import { Ticket } from 'src/app/models/Ticket';
import { AtencionService } from 'src/app/shared/services/atencion.service';
import { Atencion } from 'src/app/models/Atencion';
import { Router, ActivatedRoute} from '@angular/router';

config({
  floatingActionButtonConfig: {
      icon: 'rowfield',
      position: {
          of: '#gridAtenciones',
          my: 'right bottom',
          at: 'right bottom',
          offset: '-16 -16'
      }
  }
}); 

@Component({
  selector: 'aprob-aten-bandeja',
  templateUrl: 'aprob-asig-bandeja.component.html',
  styleUrls: ['aprob-asig-bandeja.component.css']
})

export class AprobarAsignacionBandejaComponent {
  @ViewChild(DxDataGridComponent, { static: false }) gridAtenciones: DxDataGridComponent;
  @ViewChild('formRegistrarAprobacion', { static: true }) formRegistrarAprobacion: DxFormComponent;
  ticketsDataMap: object = {};
  dsAtencion: any;
  dsImagenesTck: ImagenTicket[];
  ticket = new Ticket();
  selectedRowIndex = -1;
  idAtencionSelected = -1
  idTicketSelected = -1;
  idTicketAtencionSelected = -1;
  popupImagenesVisible = false;
  tituloVerImagenes = "";
  urlDetalleTicket = "";
  imagenInicial = 0;
  dataTicketSelected: any;
  popupRAVisible = false;
  tituloRA = "";
  IndAproObs = 0;
  dsActas:any;
  popupActasVisible = false;
  tituloVerActas = "";
  popupConfirmarVisible = false;
  urlSrvArchivos = "";
  botonAprobacionDisabled = false;
  botonCerrarAprobacionDisabled = false;
  loadingVisible = false;

  constructor(private httpClient: HttpClient, private authService: AuthService, private tckService: TicketService,
                                                                                private ateService: AtencionService, private router: Router, 
                                                                                private route: ActivatedRoute) {

    let idTicketAtencion : any = this.route.snapshot.paramMap.get('idTicketAtencion');
    if (idTicketAtencion != null) {
        this.repoblar();
    }

    this.urlDetalleTicket = environment.APITicketCore + "/consultas/listaTicketByAtencionxAprobar";
    this.listarActas = this.listarActas.bind(this);
    this.urlSrvArchivos = environment.EvidenciaMINTTO + "/";
  }
  repoblar () {
    let idUsuario = this.authService.usuarioLogueado.idUsuario;
    let idCliente = this.authService.usuarioLogueado.idClienteSeleccionado;
    let loadParams = { idCliente: idCliente,  idUsuario: idUsuario}
    this.dsAtencion = new CustomStore({
                    key: "idAtencion",
                    load: () => this.sendRequest(environment.APITicketCore + "/consultas/listaAtencionesAprobar", "LOAD", loadParams),
                    remove: (key) => this.sendRequest("/ticket/eliminarTicketById", "REMOVE", {pIdTicket: key})
                  });
  }
  sendRequest(url: string, method: string, data: any = {}): any {

    let result;

    switch(method) {
        case "LOAD":
            url = url + "?idCliente=" + data.idCliente + "&idUsuario=" + data.idUsuario; 
            result = this.httpClient.get(url);
            break;
        case "LOADACTAS":
              url = url + "?pIdAtencion=" + data.pIdAtencion; 
              console.log(url);
              result = this.httpClient.get(url);
              break;                
        case "REMOVE":
            url = url + "?pIdTicket=" + data.pIdTicket; 
            result = this.httpClient.delete(url);
            break;
    }

    return result
        .toPromise()
        .then((data: any) => {
            if (method === "REMOVE") { 
              if (data.codigo != 'OK') {
                  notify({
                    message: data.mensaje,
                    position: {
                        my: "center center",
                        at: "center center"
                    }, width: 500, 
                  }, "error", 2000);
              }
            } 
            return data;
        })
        .catch(e => {
            throw e && e.error && e.error.Message;
        });
  }
  cerrarAprobacion() {
    this.popupConfirmarVisible = true;
  }
  ocultarPopupConfirmar() {
    this.popupConfirmarVisible = false;
  }
  confirmarOK() {
    this.botonCerrarAprobacionDisabled = true;
    this.loadingVisible = true;

    let atencion = new Atencion();
    atencion.IdUsuarioCreacion = this.authService.usuarioLogueado.idUsuario;
    atencion.IdAtencion = this.idAtencionSelected;
    this.ateService.cerrarAprobacion(atencion).subscribe(data => {
      if (data.codigo == "OK") {
          this.botonCerrarAprobacionDisabled = false;
          this.loadingVisible = false;
          notify({
            message: "Proceso Correcto!" ,
            position: {
            my: "center middle",
            at: "center middle"
            }, width: 300, 
          }, "success", 1500);  
          this.repoblar();
          this.ocultarPopupConfirmar();                                            
      } else {
          this.botonCerrarAprobacionDisabled = false;
          this.loadingVisible = false;        
          notify({
              message: data.mensaje ,
              position: {
                  my: "center middle",
                  at: "center middle"
              }, width: 300, 
          }, "error", 5000);
      }    
    }); 
  } 
  listarActas() {
    if (this.idAtencionSelected <=0) {
      notify({
        message: "Debe seleccionar un registro para continuar",
        position: {
            my: "center center",
            at: "center center"
        }, width: 500, 
      }, "error", 2000);          
    } else {
      let loadParams = { pIdAtencion: this.idAtencionSelected}; 
      this.dsActas = new CustomStore({
        key: "idActaAtencion",
        load: () => this.sendRequest(environment.APITicketCore + "/consultas/listarActasAprobacion", "LOADACTAS", loadParams)
      });
      this.popupActasVisible = true;
      this.tituloVerActas = "Lista de Actas registradas Atención: " + this.idAtencionSelected;
    }
  }
  obtenerTickets(idAtencion: number) : any {
    return this.ticketsDataMap[idAtencion] = this.ticketsDataMap[idAtencion] || {
      store: AspNetData.createStore({
          key: "idTicket",
          loadParams: { pIdAtencion: idAtencion },
          loadUrl: this.urlDetalleTicket
      })
    };
  }
  selectedChanged(e) {
    this.selectedRowIndex = e.component.getRowIndexByKey(e.selectedRowKeys[0]);
    this.idAtencionSelected = e.selectedRowKeys[0];
  }
  clickTicket(evt) {
    this.idTicketSelected = evt.data.idTicket;
    this.idTicketAtencionSelected = evt.data.idTicketAtencion;
    this.dataTicketSelected = evt.data;
  }
  expandedClick(){
    this.idTicketSelected = -1;
    this.idTicketAtencionSelected = -1;
  }
  verImagenesTicket() {
    this.imagenInicial = 0;
    let idTicket = this.idTicketSelected;
    this.tituloVerImagenes = "Imagenes Ticket: " + this.idTicketSelected;
    this.tckService.leerTicketById(idTicket).subscribe(data => {
          let imagenes: ImagenTicket[] = [];
          data.data.imagenesTicket.forEach(element => {
              let imagen = new ImagenTicket();
              imagen.PathURLImagen = environment.EvidenciaMINTTO + "/" + element.pathURLImagen;
              imagen.Nombre = element.nombre;
              imagenes.push(imagen)
          });
          this.dsImagenesTck = imagenes;
          if (this.dsImagenesTck.length > 0) {
            this.popupImagenesVisible = true; 
          } else {
            notify({
              message: "No existen Imagenes para visualizar!",
              position: {
                  my: "center center",
                  at: "center center"
              }, width: 500, 
            }, "error", 2000); 
          }          
    });
  }
  aprobarAsignacion() {

/*     if (this.dataTicketSelected.ticketAprobado) {
      notify({
          message: "Ticket ya aprobado!" ,
          position: {
          my: "center middle",
          at: "center middle"
        }, width: 300, 
      }, "success", 1500);        
    } else if (!this.dataTicketSelected.ticketEjecutado) {
        notify({
            message: "Aún no se registra la ejecución del Ticket!" ,
            position: {
            my: "center middle",
            at: "center middle"
          }, width: 300, 
      }, "success", 1500);       
    } else {
      this.ingresarAprobacionRechazo(1);  
    } */

    this.router.navigate(["det-aprobacion-ate-ticket", { idTicketAtencion: this.idTicketAtencionSelected, 
                                                         tckAprobado: this.dataTicketSelected.ticketAprobado, 
                                                         tckObservado: this.dataTicketSelected.atencionObservada}]);

  }
/*   rechazarAprobacion() {
    if (this.dataTicketSelected.ticketAprobado) {
      notify({
          message: "Ticket ya aprobado!" ,
          position: {
          my: "center middle",
          at: "center middle"
        }, width: 300, 
      }, "success", 1500);        
    } else if (!this.dataTicketSelected.ticketEjecutado) {
        notify({
            message: "Aún no se registra la ejecución del Ticket!" ,
            position: {
            my: "center middle",
            at: "center middle"
          }, width: 300, 
      }, "success", 1500);       
    } else {
      this.ingresarAprobacionRechazo(2);  
    }
  }  */
/*   ingresarAprobacionRechazo(tipo: number) {
    this.IndAproObs = tipo;
    this.popupRAVisible = true;
    if (tipo===1)
      this.tituloRA = "Aprobar Ticket: " + this.dataTicketSelected.idTicket;
    else 
    this.tituloRA = "Observar Ticket: " + this.dataTicketSelected.idTicket;

    this.formRegistrarAprobacion.instance.resetValues();

    this.ticket = new Ticket();

    this.ticket.IdTicket = this.dataTicketSelected.idTicket;
    this.ticket.IdProveedorAtencion = this.dataTicketSelected.idProveedorAtencion;
    this.ticket.IdTicketAtencion = this.dataTicketSelected.idTicketAtencion; 

    this.ticket.NumeroTicketCliente = this.dataTicketSelected.numeroTicketCliente; 
    this.ticket.DescripcionPrioridad = this.dataTicketSelected.descripcionPrioridad; 
    this.ticket.DescripcionLocal = this.dataTicketSelected.descripcionLocal; 
    this.ticket.DescripcionZona = this.dataTicketSelected.descripcionZona; 
    this.ticket.DescripcionSubZona = this.dataTicketSelected.descripcionSubZona; 
    this.ticket.DescripcionEspecialidad = this.dataTicketSelected.descripcionEspecialidad; 
    this.ticket.Comentarios = this.dataTicketSelected.comentarios; 
    this.ticket.FechaInicio = this.dataTicketSelected.fechaInicio; 
    this.ticket.FechaTermino = this.dataTicketSelected.fechaTermino; 
    this.ticket.FechaInicioReal = this.dataTicketSelected.fechaInicioReal; 
    this.ticket.FechaTerminoReal = this.dataTicketSelected.fechaTerminoReal; 
    this.ticket.ComentariosEjecucion = this.dataTicketSelected.comentariosEjecucion; 
    this.ticket.ComentariosAprobacion = this.dataTicketSelected.comentariosAprobacion; 

    this.formRegistrarAprobacion.instance.option("formData", this.ticket);

    //COLOCO EL FOCO EN EL PRIMER CAMPO
    let that = this;
    setTimeout(function() {
        that.formRegistrarAprobacion.instance.getEditor("ComentariosAprobacion").focus();
    }, 500); 
  }  */  
/*   registrarAprobacionClick() {
    if (this.formRegistrarAprobacion.instance.validate().isValid) {

      this.botonAprobacionDisabled = true;
      this.loadingVisible = true;

      //INFORMACION A ACTUALIZAR
      this.ticket = this.formRegistrarAprobacion.instance.option("formData");
      this.ticket.IdUsuarioCreacion = this.authService.usuarioLogueado.idUsuario;
      this.ticket.IdOrigenTicket = this.IndAproObs;
      this.ateService.registrarAprobacion(this.ticket).subscribe(data => {
                                        if (data.codigo == "OK") {
                                            this.botonAprobacionDisabled = false;
                                            this.loadingVisible = false;
                                            notify({
                                                message: "Grabación Correcta!" ,
                                                position: {
                                                my: "center middle",
                                                at: "center middle"
                                              }, width: 300, 
                                             }, "success", 1500);  
                                            this.repoblar();
                                            this.ocultarPopupRAClick();                                            
                                            this.idTicketSelected = -1;
                                        } else {
                                            this.botonAprobacionDisabled = false;
                                            this.loadingVisible = false;
                                            notify({
                                                message: data.mensaje ,
                                                position: {
                                                    my: "center middle",
                                                    at: "center middle"
                                                }, width: 300, 
                                            }, "error", 5000);
                                        }    
                      });
  }
  }
  ocultarPopupRAClick() {
    this.popupRAVisible = false;
  }  */
}