import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { catchError } from 'rxjs/operators';
import { of } from 'zen-observable';
import notify from 'devextreme/ui/notify';
import { environment } from 'src/environments/environment';

@Injectable()
export class ProveedorService {
  
  private httpOptions: any;

  constructor(private httpClient: HttpClient) {
    this.httpOptions = {
      headers: new HttpHeaders({'Content-Type': 'application/json'})
    };
  }

  listarProveedoresHabiles(): any {
    let url: string = environment.APIAdministracion + "/proveedores/listarProveedoresHabiles";
    return this.httpClient.get(url)
                          .pipe(catchError(err => 
                                      { 
                                        console.log(err);
                                        notify({
                                          message: "Se presento un error al conectarse con el servidor! ERROR: " + err.message,
                                          position: {
                                              my: "center bottom",
                                              at: "center bottom"
                                          }, width: 500, 
                                        }, "error", 5000);                                        
                                        return of(null); 
                                      }
                                  )); 
  }
 /* 
  listarLocalesByClienteUsuario(pIdUsuario: number, pIdCliente: number) {
    let urlLocales: string = environment.APIAdministracion + "locales/listarLocales" 
                                        + "?pIdCliente=" + pIdCliente + "&pIdUsuario=" + pIdUsuario;
    return this.httpClient.get(urlLocales)
                          .pipe(catchError(err => 
                                            { 
                                                console.log(err);
                                                notify({
                                                  message: "Se presento un error al conectarse con el servidor! ERROR: " + err.message,
                                                  position: {
                                                      my: "center bottom",
                                                      at: "center bottom"
                                                  }, width: 500, 
                                                }, "error", 5000);                                                
                                                return of(null); 
                                            }
                                        )); 
  }  
  listarZonasByLocal(pIdLocal: number) {
    let urlZonas: string = environment.APIAdministracion + "zonas/listarZonas"
                                    + "?pIdLocal=" + pIdLocal;
    return this.httpClient.get(urlZonas)
                          .pipe(catchError(err => 
                                            { 
                                                console.log(err);
                                                notify({
                                                  message: "Se presento un error al conectarse con el servidor! ERROR: " + err.message,
                                                  position: {
                                                      my: "center bottom",
                                                      at: "center bottom"
                                                  }, width: 500, 
                                                }, "error", 5000);                                                
                                                return of(null); 
                                            }
                                        ));     
  }  
  listarEspecialidades(pIdCliente) {
    let urlEspecialidades: string = environment.APIAdministracion +  "especialidades/listarEspecialidades"
                                    + "?pIdCliente=" + pIdCliente;
    return this.httpClient.get(urlEspecialidades)
                          .pipe(catchError(err => 
                                            { 
                                                console.log(err);
                                                notify({
                                                  message: "Se presento un error al conectarse con el servidor! ERROR: " + err.message,
                                                  position: {
                                                      my: "center bottom",
                                                      at: "center bottom"
                                                  }, width: 500, 
                                                }, "error", 5000);                                                
                                                return of(null); 
                                            }
                                        ));       

  }
  registrarTicket (ticket: Ticket) {

    let urlAgregarTicket: string = environment.APITicketCore + "ticket/registrarTicket";
    return this.httpClient.post(urlAgregarTicket, ticket, this.httpOptions)
                   .pipe(catchError(err => 
                                      { 
                                        console.log("ERROR NO CONTROLADO: " + err.message);
                                        notify({
                                          message: "Se presento un error al conectarse con el servidor! ERROR: " + err.message,
                                          position: {
                                              my: "center bottom",
                                              at: "center bottom"
                                          }, width: 500, 
                                        }, "error", 5000);
                                        return of(null); 
                                      }
                                  ));  
  } */
}