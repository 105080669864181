export class Proveedor {
    IdProveedorAtencion: number; 
    IdProveedor: number; 
    IdAtencion: number;
    IdTipoProveedor: number;
    NombreComercial: string;
    DescripcionProveedor: string;
    IdTipoDocumento: number;
    NumeroDocumento: string; 
    DescripcionMoneda: string; 
    PrecioTotal: number; 
    PlazoEntrega: number;
    PathURLCotizacion: string;
    IdMoneda: number;
    ComentariosProveedor: string;
    ComentariosAprobador: string;
    NombreArchivoCotizacion: string;
    IdUsuarioCreacion: number;
    ProveedorGanador: boolean;
  }