export class Local {
    IdLocal:number;
    IdCliente: number;
    IdLocalSupervision: number;
    IdSupervision: number;
    DescripcionLocal:string;
    Direccion: string;
    Referencia:string;
    IdUbigeo: number;
    DescripcionUbigeo;
    IdZonaGeografica:number;
    DescripcionZonaGeografica: string;
    ContactoLocal:string;
    IdUsuarioCreacion:number;
    RegistroActivo:boolean;
    Porcentaje: number;
    PorcentajeText: string;
    Responsable: string;
    FechaInicio:Date;
    FechaSyncDown: Date;
    FechaInicioText:string;
    FechaSyncDownText: string;
    Comentarios: string;
    NombreImagen: string;
    PathURLImagen: string;
    IdTipoLocal: number;
}