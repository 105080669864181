import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule} from "@angular/common/http";

import { SideNavOuterToolbarModule, SideNavInnerToolbarModule, SingleCardModule } from './layouts';
import { FooterModule, LoginFormModule } from './shared/components';
import { AuthService, ScreenService, AppInfoService } from './shared/services';
import { AppRoutingModule } from './app-routing.module';
import { DxPopupModule, DxButtonModule, 
         DxTooltipModule, DxTemplateModule, DxGalleryModule } from 'devextreme-angular';

import { AppComponent } from './app.component';
import { TicketService } from './shared/services/ticket.service';
import { ClienteService } from './shared/services/cliente.service';
import { SupervisionService } from './shared/services/supervision.service';
import { ParametricasService } from './shared/services/parametricas.service';
import { DashBoardsService } from './shared/services/dashboard.service';
import { ProveedorService } from './shared/services/proveedor.service';
import { AtencionService } from './shared/services/atencion.service';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    SideNavOuterToolbarModule,
    SideNavInnerToolbarModule,
    SingleCardModule,
    FooterModule,
    LoginFormModule, 
    AppRoutingModule, DxTooltipModule, DxTemplateModule, 
    DxPopupModule, DxButtonModule, DxGalleryModule 
  ],
  providers: [AuthService, ScreenService, AppInfoService, TicketService, ClienteService, SupervisionService, DashBoardsService, 
              ParametricasService, ProveedorService, AtencionService],
  bootstrap: [AppComponent]
})
export class AppModule { }
