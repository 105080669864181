export class FiltroGeneral {
    Periodo: string;
    IdZG: number;
    IdLocal:number;
    IdZona:number;
    IdSubZona:number;
    IdEspecialidad:number;
    IdTipoActivo:number;
    IdOrigenTck:number;
    IdPrioridad:number;
    IdUsuarioSolicitante:number;
    SoloObservados:Boolean;
    SinSupervision:Boolean;
    IdSupervision:number;
    FechaInicio: Date;
    FechaTermino: Date;
    RutaArchivo:string;
  }