import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot } from '@angular/router';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { catchError } from 'rxjs/operators';
import { of } from 'zen-observable';
import { Usuario } from 'src/app/models/Usuario'
import notify from 'devextreme/ui/notify';
import { environment } from 'src/environments/environment';

@Injectable()
export class AuthService {
  
  private loggedIn = false;
  private httpOptions: any;

  constructor(private router: Router, private httpClient: HttpClient) {
    this.httpOptions = {
      headers: new HttpHeaders({'Content-Type': 'application/json'})
    };
  }

  logIn(pLogin: string, pClave: string): any {

    let url: string = environment.APISeguridad + "/autenticacion/validarUsuarioByCredenciales";
    let postData = {login: pLogin, clave: pClave};
    return this.httpClient.post(url, postData, this.httpOptions)
                   .pipe(catchError(err => 
                                      { 
                                        console.log("ERROR NO CONTROLADO: " + err.message);
                                        notify({
                                          message: "Se presento un error al conectarse con el servidor! ERROR: " + err.message,
                                          position: {
                                              my: "center bottom",
                                              at: "center bottom"
                                          }, width: 500, 
                                        }, "error", 5000);
                                        return of(null); 
                                      }
                                  ));
  }
  logOut() {
    this.loggedIn = false;
    localStorage.setItem('currentUser', null);
    localStorage.setItem('clienteSeleccionado', null);
    localStorage.setItem('localSeleccionado', null);
    localStorage.setItem('historial', null);
    this.router.navigate(['/login-form']);
  }  
  listarOpcionesUsuario(pIdUsuario: number, pIdRol: number) : any {

    let postData = {IdUsuario: pIdUsuario, IdRol: pIdRol};
    let url: string = environment.APISeguridad + "/autorizacion/obtenerOpcionesByUsuario";
    return this.httpClient.post(url, postData, this.httpOptions)
                   .pipe(catchError(err => 
                                      { 
                                        console.log(err);
                                        return of(null); 
                                      }
                                  ));
  }
  listarUsrAsigTicket(pIdCliente: number): any {

    let url: string = environment.APISeguridad + "/autenticacion/obtenerUsrsAsigTicket?pIdCliente=" + pIdCliente;
    return this.httpClient.get(url)
                   .pipe(catchError(err => 
                                      { 
                                        notify({
                                          message: "Se presento un error al conectarse con el servidor! ERROR: " + err.message,
                                          position: {
                                              my: "center bottom",
                                              at: "center bottom"
                                          }, width: 500, 
                                        }, "error", 5000);
                                        return of(null);
                                      }
                                  ));
  }  
  registrarSessionUsuario (usuario: Usuario) : any{
    this.loggedIn = true;
    localStorage.setItem('currentUser', JSON.stringify(usuario));
  }
  registrarSessionTemporal (usuario: Usuario): any {
    localStorage.setItem('currentUser', JSON.stringify(usuario));
  }
  get isLoggedIn() {
    return this.loggedIn;
  }
  get usuarioLogueado() {
    let rpta = JSON.parse(localStorage.getItem('currentUser'));
    return rpta;
  }
}

@Injectable()
export class AuthGuardService implements CanActivate {  
    constructor(private router: Router, private authService: AuthService) {}

    canActivate(route: ActivatedRouteSnapshot): boolean {
        const isLoggedIn = this.authService.isLoggedIn;
        const isLoginForm = route.routeConfig.path === 'login-form';
        if (isLoggedIn && isLoginForm) {
            this.router.navigate(['/']);
            return false;
        }

        if (!isLoggedIn && !isLoginForm) {
            this.router.navigate(['/login-form']);
        }

        return isLoggedIn || isLoginForm;
    }
}
