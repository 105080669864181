import { Component, ViewChild } from '@angular/core';
import { HttpClient, HttpParams } from "@angular/common/http";
import CustomStore from 'devextreme/data/custom_store';
import config from 'devextreme/core/config';
import notify from 'devextreme/ui/notify';
import { DxDataGridComponent } from 'devextreme-angular';
import { AuthService } from 'src/app/shared/services';
import { environment } from '../../../../environments/environment';
import { Router} from '@angular/router';
import { ImagenTicket } from '../../../models/ImagenTicket';
import { AdmClienteComponent } from '../../administracion/adms/adm-cliente.component';

config({
  floatingActionButtonConfig: {
      icon: 'rowfield',
      position: {
          of: 'gridClientes',
          my: 'right bottom',
          at: 'right bottom',
          offset: '-16 -16'
      }
  }
}); 

@Component({
  templateUrl: 'clientes-bandeja.component.html',
  styleUrls: ['clientes-bandeja.component.css']
})

export class ClientesBandejaComponent {
  @ViewChild(DxDataGridComponent, { static: false }) gridClientes: DxDataGridComponent;
  @ViewChild(AdmClienteComponent, { static: false }) admCliente: AdmClienteComponent;

  dsImagenesTck: any;
  dsClientes: any;
  dsImgLogo: ImagenTicket[];
  selectedRowIndex = -1;
  idClienteSelected = 0;
  popupRegistrarVisible = false;
  popupLogoVisible = false;
  popupRegLocalesVisible = false;
  tituloRegistro = "";
  tituloVerImagenes = "";
  popupImagenesVisible = false;
  imagenInicial = 0;
  dataClienteSelected: any;

  constructor(private httpClient: HttpClient, private authService: AuthService, private router: Router) {
    this.repoblarClientes();
  }

  repoblarClientes () {
    this.dsClientes = new CustomStore({
                    key: "idCliente",
                    load: () => this.sendRequest(environment.APIAdministracion + "/clientes", "LOAD", {})
                  });
  }
  sendRequest(url: string, method: string, data: any = {}): any {

    let result;

    switch(method) {
        case "LOAD":
            result = this.httpClient.get(url);
            break;
        case "REMOVE":
            url = url + "?pIdCliente=" + data.pIdCliente; 
            result = this.httpClient.delete(url);
            break;
    }

    return result
        .toPromise()
        .then((data: any) => {
            if (method === "REMOVE") { 
              if (data.codigo != 'OK') {
                  notify({
                    message: data.mensaje,
                    position: {
                        my: "center center",
                        at: "center center"
                    }, width: 500, 
                  }, "error", 2000);
              }
            } 
            return data;
        })
        .catch(e => {
            throw e && e.error && e.error.Message;
        });
  }
  editRow() {
    this.popupRegistrarVisible = true;
    this.tituloRegistro = "Modificar Cliente";
    this.admCliente.inicializarControles(this.idClienteSelected, true);
  }
  addRow() {
      this.popupRegistrarVisible = true;
      this.tituloRegistro = "Datos Generales Nuevo Cliente";
      this.admCliente.inicializarControles(0, true);
  }
  confLocalesyZonasClick() {
    localStorage.setItem('clienteSeleccionado', JSON.stringify(this.dataClienteSelected));
    this.router.navigate(["ges-local-zonas-clientes"]);
  } 
  selectedChanged(e) {
    this.selectedRowIndex = e.component.getRowIndexByKey(e.selectedRowKeys[0]);
    this.idClienteSelected = e.selectedRowKeys[0];
    this.dataClienteSelected = e.selectedRowsData[0];
  }
  verLogoClick() {
    this.imagenInicial = 0;
    this.tituloVerImagenes = "Logo Cliente: " + this.dataClienteSelected.descripcionCliente;
    let imagenes: ImagenTicket[] = [];
    let imagen = new ImagenTicket();
    imagen.PathURLImagen = environment.EvidenciaMINTTO + "/" + this.dataClienteSelected.pathURLLogo;
    imagen.Nombre = this.dataClienteSelected.nombreLogo;
    imagenes.push(imagen)
    this.dsImagenesTck = imagenes;
    this.popupImagenesVisible = true;
  }
  ocultarPopup() {
    this.popupRegistrarVisible = false;
  }
}