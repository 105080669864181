export class Supervision {
    IdSupervision: number; 
    DescripcionSupervision: string;
    TipoSupervision: string;
    PeriodoSupervision: string;
    IdCliente: number;
    FechaInicio: Date;
    FechaFin: Date;
    SupervisarInfraestructura: boolean; 
    SupervisarActivos: boolean;
    SoloRealizarInventario: boolean;
    IdEstadoProceso: number; 
    IdUsuarioCreacion: number;
    FechaCreacion: Date;
  }