
export class Cliente {
    IdCliente: number; 
    IdTipoCliente: number;
    ApellidoPaterno: string;
    ApellidoMaterno: string;
    Nombres: string;
    RazonSocial: string; 
    NombreComercial: string; 
    DescripcionCliente: string;
    IdTipoDocumento: number;
    NumeroDocumento: string; 
    Direccion: string;
    IdUbigeo: number;
    IdPeriodicidad: number;
    PathURLLogo: string;
    SupervisarInfraestructura: boolean;
    SupervisarActivos: boolean;
    SoloRealizarInventario: boolean;
    RegistroActivo: boolean;
    IdUsuarioCreacion: number;
    NombreLogo: string;
    DescripcionTipoCliente: string;
    DescripcionTipoDocumento: string;
    DescripcionUbigeo: string;

  }