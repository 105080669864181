import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { DxFormComponent, DxFileUploaderComponent, DxDataGridComponent } from 'devextreme-angular';
import ArrayStore from 'devextreme/data/array_store';
import notify from 'devextreme/ui/notify';

import { AuthService } from '../../../shared/services/auth.service';
import { environment } from 'src/environments/environment';
import { Cliente } from 'src/app/models/Cliente';
import { ParametricasService } from 'src/app/shared/services/parametricas.service';
import { ClienteService } from 'src/app/shared/services/cliente.service';

import $ from "jquery";

@Component({
    selector: 'adm-cliente',
    styleUrls: ['./adm-cliente.component.css'],
    templateUrl: './adm-cliente.component.html'
})

export class AdmClienteComponent {
    fileSeleccionados: any[] = [];
    cliente = new Cliente();
    tiposCliente: any; periodicidades: any; tiposDocumento: any;
    ubigeos: any;
    urlUpLoad: string;
    idPrioridadInicial: number;
    idLocalInicial: number;
    botonGrabarDisabled = true;
    mostrarFileUploader = true;
    idPeriodicidadInicial = -1;
    idTCInicial = -1;
    razonSocialVisible = false;
    paternoVisible = false;
    maternoVisible = false;
    nombresVisible = false;

    @Output() cerrarPopup = new EventEmitter<any>();
    @Output() repoblarClientes = new EventEmitter<any>();
    @ViewChild('formCliente', { static: false }) formCliente: DxFormComponent;
    @ViewChild(DxFileUploaderComponent, { static: false }) fileUploader: DxFileUploaderComponent;

    constructor(private paramService: ParametricasService, private authService: AuthService, private cliService: ClienteService) {
        this.urlUpLoad = environment.APICargaArchivo + environment.urlUpLoad;
        let idUsuario = this.authService.usuarioLogueado.idUsuario;
        let idCliente = this.authService.usuarioLogueado.idClienteSeleccionado;
        this.cambioTipoCliente = this.cambioTipoCliente.bind(this);

        this.paramService.listarPeriodicidades().toPromise().then(data => {
                                        this.periodicidades = new ArrayStore({
                                            data: data,
                                            key: "idPeriodicidad"
                                        }); 
                                        this.idPeriodicidadInicial = data[0].idPeriodicidad;
                                    });  
        this.paramService.listarTipoCliente(false).toPromise().then(data => {
                                        this.tiposCliente = new ArrayStore({
                                           data: data,
                                           key: "idTipo"
                                       }); 
                                       data.forEach(element => {
                                           if (element.codigoTipo === "JURI") {
                                               this.idTCInicial = element.idTipo;
                                           }
                                       });                                       
                                   }); 
        this.paramService.listarTipoDocumentoCliente(false).toPromise().then(data => {
                                    this.tiposDocumento = new ArrayStore({
                                       data: data,
                                       key: "idTipo"
                                   }); 
                               }); 
        this.paramService.listarUbigeos().toPromise().then(data => {
                                this.ubigeos = new ArrayStore({
                                   data: data,
                                   key: "idUbigeo"
                               }); 
                           });                                                               
    }
    public inicializarControles(pIdCliente: number, permitirEditar: boolean) {
        if (pIdCliente == 0) {
            this.formCliente.instance.resetValues();
            this.fileUploader.instance.reset();
            this.cliente.IdTipoCliente = this.idTCInicial; 
            this.cliente.IdPeriodicidad = this.idPeriodicidadInicial;
            this.cliente.IdCliente = 0;
            this.formCliente.instance.option("formData", this.cliente);
        } else {
            //OBTENER EL CLIENTE SELECCIONADO Y ASIGNAR VALORES
             this.cliService.leerClienteById(pIdCliente).subscribe(data => {
                    this.formCliente.instance.resetValues();
                    this.fileUploader.instance.reset();
                    
                    this.cliente.IdCliente = pIdCliente;
                    this.cliente.IdTipoCliente = data.idTipoCliente;
                    this.cliente.IdTipoDocumento = data.idTipoDocumento;
                    this.cliente.NumeroDocumento = data.numeroDocumento;
                    this.cliente.RazonSocial = data.razonSocial;
                    this.cliente.NombreComercial = data.nombreComercial;
                    this.cliente.ApellidoPaterno = data.apellidoPaterno;
                    this.cliente.ApellidoMaterno = data.ApellidoMaterno;
                    this.cliente.Nombres = data.nombres;
                    this.cliente.Direccion = data.direccion; 
                    this.cliente.IdUbigeo = data.idUbigeo; 
                    this.cliente.IdPeriodicidad = data.idPeriodicidad; 
                    this.cliente.PathURLLogo = data.pathURLLogo;
                    this.cliente.SupervisarInfraestructura = data.supervisarInfraestructura;
                    this.cliente.SupervisarActivos = data.supervisarActivos;
                    this.cliente.SoloRealizarInventario = data.soloRealizarInventario;
                    this.cliente.RegistroActivo = data.registroActivo;
                    this.cliente.NombreLogo = data.nombreLogo;

                    this.formCliente.instance.option("formData", this.cliente);
            });  
        }
        if (permitirEditar) {
            //COLOCO EL FOCO EN EL PRIMER CAMPO
            let that = this;
            setTimeout(function() {
                that.formCliente.instance.getEditor("IdTipoCliente").focus();
            }, 500); 
            this.formCliente.instance.option("disabled", false);
            this.botonGrabarDisabled = false;
            this.mostrarFileUploader = true;
        } else {
            this.formCliente.instance.option("disabled", true);
            this.botonGrabarDisabled = true;
            this.mostrarFileUploader = false;
        } 
    }
    cambioTipoCliente(evt) {
        if (evt.selectedItem) {
            if (evt.selectedItem.codigoTipo === "NATU") {
                this.razonSocialVisible = false;
                this.paternoVisible = true;
                this.maternoVisible = true;
                this.nombresVisible = true;
            } else {
                this.razonSocialVisible = true;
                this.paternoVisible = false;
                this.maternoVisible = false;
                this.nombresVisible = false;
            }
        }
        
        //COLOCO EL FOCO EN EL PRIMER CAMPO
        let that = this;
        setTimeout(function() {
           that.formCliente.instance.getEditor("IdTipoCliente").focus();
        }, 500); 

        evt.preventDefault;
    }
    actualizarClick (args){
        this.cliente = this.formCliente.instance.option("formData");
        if (this.formCliente.instance.validate().isValid) {
            if (this.fileSeleccionados.length <= 0 && (this.cliente.PathURLLogo == "" || this.cliente.PathURLLogo == null ) ) {
                notify({
                    message: "Debe registrar el logo del cliente",
                    position: {
                        my: "center center",
                        at: "center center"
                    }, width: 500, 
                }, "error", 2000);  
                return;
            }
            
            let idUsuario = this.authService.usuarioLogueado.idUsuario;
            this.cliente.IdUsuarioCreacion = idUsuario;
            if (this.fileSeleccionados.length > 0)
                this.cliente.NombreLogo = this.fileSeleccionados[0].name;
            else 
                this.cliente.NombreLogo = null;
                
            this.cliService.registrarCliente(this.cliente).subscribe(data => {
                                    if (data.codigo == "OK") {
                                        //SUBIR LOS ARCHIVOS AL SERVIDOR
                                        if (this.fileSeleccionados.length > 0) {
                                            this.subirArchivos(data.rutaEvidencia);
                                        } else  {
                                            notify({
                                                message: "Grabación Correcta!" ,
                                                position: {
                                                my: "center center",
                                                at: "center center"
                                                }, width: 300, 
                                                }, "success", 1500);  
                                            this.repoblarClientes.emit();
                                            this.cerrarPopupClick();                                            
                                        } 

                                    } else {
                                        notify({
                                            message: data.mensaje ,
                                            position: {
                                                my: "center center",
                                                at: "center center"
                                            }, width: 300, 
                                        }, "error", 5000);
                                    }    
                                });
        } 
    }
    cargaFinalizada () {
        //ENVIAR NOTIFICACION TICKET AGREGADO
        notify({
            message: "Grabación Correcta!" ,
            position: {
            my: "center center",
            at: "center center"
            }, width: 300, 
            }, "success", 1500);  
        this.repoblarClientes.emit();
        this.cerrarPopupClick();
    }
    subirArchivos (ruta: string) {
        let headers = {ruta: ruta};
        this.fileUploader.instance.option("uploadHeaders", headers);  
        (<any> this.fileUploader.instance)._uploadFiles();   
    }
    asignarValoresUpLoadFile (e) {
        $(".dx-fileuploader-upload-button").css({"visibility":"hidden"});
    }
    cerrarPopupClick() {
        this.cerrarPopup.emit();
    }
}