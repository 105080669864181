import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { catchError } from 'rxjs/operators';
import { of } from 'zen-observable';
import notify from 'devextreme/ui/notify';
import { Ticket } from '../../models/Ticket';
import { environment } from 'src/environments/environment';
import { FiltroGeneral } from 'src/app/models/filtroGeneral';
import { NumberValueAccessor } from '@angular/forms';

@Injectable()
export class TicketService {
  
  private httpOptions: any;

  constructor(private httpClient: HttpClient) {
    this.httpOptions = {
      headers: new HttpHeaders({'Content-Type': 'application/json'})
    };
  }

  leerTicketAtencionById(pIdTicketAtencion: number): any {
    let urlLeerTicketAtencion: string = environment.APITicketCore + "/ticket/leerTicketAtencionById?pIdTicketAtencion=" + pIdTicketAtencion;
    return this.httpClient.get(urlLeerTicketAtencion)
                  .pipe(catchError(err => 
                                     { 
                                       console.log(err);
                                       notify({
                                        message: "Se presento un error al conectarse con el servidor! ERROR: " + err.message,
                                        position: {
                                            my: "center bottom",
                                            at: "center bottom"
                                        }, width: 500, 
                                      }, "error", 5000);                                       
                                       return of(null); 
                                     }
                                 )); 
  }
  leerTicketById(pIdTicket: number) : any{
    let urlLeerTicket: string = environment.APITicketCore + "/ticket/leerTicketById?pIdTicket=" + pIdTicket;
    return this.httpClient.get(urlLeerTicket)
                  .pipe(catchError(err => 
                                     { 
                                       console.log(err);
                                       notify({
                                        message: "Se presento un error al conectarse con el servidor! ERROR: " + err.message,
                                        position: {
                                            my: "center bottom",
                                            at: "center bottom"
                                        }, width: 500, 
                                      }, "error", 5000);                                       
                                       return of(null); 
                                     }
                                 )); 
  }
  listarPrioridades(pAddTodos: boolean) : any{
    let urlPrioridades: string = environment.APIAdministracion + "/parametricas/listarPrioridades?pAddTodos=" + pAddTodos;
    return this.httpClient.get(urlPrioridades)
                   .pipe(catchError(err => 
                                      { 
                                        console.log(err);
                                        notify({
                                          message: "Se presento un error al conectarse con el servidor! ERROR: " + err.message,
                                          position: {
                                              my: "center bottom",
                                              at: "center bottom"
                                          }, width: 500, 
                                        }, "error", 5000);                                        
                                        return of(null); 
                                      }
                                  )); 
  }
  listarLocalesByClienteUsuario(pIdUsuario: number, pIdCliente: number, pAddTodos: boolean): any {
    let urlLocales: string = environment.APIAdministracion + "/locales/listarLocales" 
                                                           + "?pIdCliente=" + pIdCliente 
                                                           + "&pIdUsuario=" + pIdUsuario 
                                                           + "&pAddTodos=" + pAddTodos;
    return this.httpClient.get(urlLocales)
                          .pipe(catchError(err => 
                                            { 
                                                console.log(err);
                                                notify({
                                                  message: "Se presento un error al conectarse con el servidor! ERROR: " + err.message,
                                                  position: {
                                                      my: "center bottom",
                                                      at: "center bottom"
                                                  }, width: 500, 
                                                }, "error", 5000);                                                
                                                return of(null); 
                                            }
                                        )); 
  }  
  listarLocByCliUsuarioZG(pIdUsuario: number, pIdCliente: number, pAddTodos: boolean, pZG: number): any {
    let url: string = environment.APIAdministracion + "/locales/listarLocalesByZG" 
                                                           + "?pIdCliente=" + pIdCliente 
                                                           + "&pIdUsuario=" + pIdUsuario 
                                                           + "&pAddTodos=" + pAddTodos
                                                           + "&pIdZG=" + pZG;
    return this.httpClient.get(url)
                          .pipe(catchError(err => 
                                            { 
                                                console.log(err);
                                                notify({
                                                  message: "Se presento un error al conectarse con el servidor! ERROR: " + err.message,
                                                  position: {
                                                      my: "center bottom",
                                                      at: "center bottom"
                                                  }, width: 500, 
                                                }, "error", 5000);                                                
                                                return of(null); 
                                            }
                                        )); 
  }    
  listarZonasByLocal(pIdLocal: number, pAddTodos: boolean) : any{
    let urlZonas: string = environment.APIAdministracion + "/zonas/listarZonas" 
                                                         + "?pIdLocal=" + pIdLocal
                                                         + "&pAddTodos=" + pAddTodos;
    return this.httpClient.get(urlZonas)
                          .pipe(catchError(err => 
                                            { 
                                                console.log(err);
                                                notify({
                                                  message: "Se presento un error al conectarse con el servidor! ERROR: " + err.message,
                                                  position: {
                                                      my: "center bottom",
                                                      at: "center bottom"
                                                  }, width: 500, 
                                                }, "error", 5000);                                                
                                                return of(null); 
                                            }
                                        ));     
  }  
  listarSubZonasByZona(pIdZona: number, pAddTodos: boolean): any {
    let urlSubZonas: string = environment.APIAdministracion + "/subZonas/listarSubZonasByZona" 
                                                            + "?pIdZona=" + pIdZona
                                                            + "&pAddTodos=" + pAddTodos;
    return this.httpClient.get(urlSubZonas)
                          .pipe(catchError(err => 
                                            { 
                                                console.log(err);
                                                notify({
                                                  message: "Se presento un error al conectarse con el servidor! ERROR: " + err.message,
                                                  position: {
                                                      my: "center bottom",
                                                      at: "center bottom"
                                                  }, width: 500, 
                                                }, "error", 5000);                                                
                                                return of(null); 
                                            }
                                        ));     
  }    
  listarEspecialidades(pIdCliente, pAddTodos: boolean) : any{
    let urlEspecialidades: string = environment.APIAdministracion + "/especialidades/listarEspecialidades" 
                                                                  + "?pIdCliente=" + pIdCliente
                                                                  + "&pAddTodos=" + pAddTodos;
    return this.httpClient.get(urlEspecialidades)
                          .pipe(catchError(err => 
                                            { 
                                                console.log(err);
                                                notify({
                                                  message: "Se presento un error al conectarse con el servidor! ERROR: " + err.message,
                                                  position: {
                                                      my: "center bottom",
                                                      at: "center bottom"
                                                  }, width: 500, 
                                                }, "error", 5000);                                                
                                                return of(null); 
                                            }
                                        ));       

  }
  registrarTicket (ticket: Ticket) : any{

    let urlAgregarTicket: string = environment.APITicketCore + "/ticket/registrarTicket";
    //console.log(JSON.stringify(ticket));
    return this.httpClient.post(urlAgregarTicket, ticket, this.httpOptions)
                   .pipe(catchError(err => 
                                      { 
                                        console.log("ERROR NO CONTROLADO: " + err.message);
                                        notify({
                                          message: "Se presento un error al conectarse con el servidor! ERROR: " + err.message,
                                          position: {
                                              my: "center middle",
                                              at: "center middle"
                                          }, width: 500, 
                                        }, "error", 5000);
                                        return of(null); 
                                      }
                                  ));  
  }

  generarActaAprobacion (ticket: Ticket) : any{
    let urlGenerarActa: string = environment.APITicketCore + "/atenciones/generarActaAtencionTicket";
    //console.log(JSON.stringify(ticket));
    return this.httpClient.post(urlGenerarActa, ticket, this.httpOptions)
                   .pipe(catchError(err => 
                                      { 
                                        console.log("ERROR NO CONTROLADO: " + err.message);
                                        notify({
                                          message: "Se presento un error al conectarse con el servidor! ERROR: " + err.message,
                                          position: {
                                              my: "center middle",
                                              at: "center middle"
                                          }, width: 500, 
                                        }, "error", 5000);
                                        return of(null); 
                                      }
                                  ));  
  }

  registrarRechazo (ticket: Ticket) : any{

    let urlAgregarTicket: string = environment.APITicketCore + "/ticket/registrarRechazo";
    return this.httpClient.post(urlAgregarTicket, ticket, this.httpOptions)
                   .pipe(catchError(err => 
                                      { 
                                        console.log("ERROR NO CONTROLADO: " + err.message);
                                        notify({
                                          message: "Se presento un error al conectarse con el servidor! ERROR: " + err.message,
                                          position: {
                                              my: "center bottom",
                                              at: "center bottom"
                                          }, width: 500, 
                                        }, "error", 5000);
                                        return of(null); 
                                      }
                                  ));  
  }  
  listatImagenesByIdFalencia(pIdFalencia: number, esActivo: boolean) : any{
    let url: string = environment.APITicketCore + "/maestros/listarImagenesByIdFalencia" 
                                                                  + "?pIdFalencia=" + pIdFalencia
                                                                  + "&pEsActivo=" + esActivo;
    return this.httpClient.get(url)
                          .pipe(catchError(err => 
                                            { 
                                                console.log(err);
                                                notify({
                                                  message: "Se presento un error al conectarse con el servidor! ERROR: " + err.message,
                                                  position: {
                                                      my: "center bottom",
                                                      at: "center bottom"
                                                  }, width: 500, 
                                                }, "error", 5000);                                                
                                                return of(null); 
                                            }
                                        ));       

  }  
  generarTicketsBysFalencias (falencias: any): any {
    let urlAgregarTicket: string = environment.APITicketCore + "/ticket/generarTicketsByFalencias";
    return this.httpClient.post(urlAgregarTicket, falencias, this.httpOptions)
                   .pipe(catchError(err => 
                                      { 
                                        console.log("ERROR NO CONTROLADO: " + err.message);
                                        notify({
                                          message: "Se presento un error al conectarse con el servidor! ERROR: " + err.message,
                                          position: {
                                              my: "center bottom",
                                              at: "center bottom"
                                          }, width: 500, 
                                        }, "error", 5000);
                                        return of(null); 
                                      }
                                  ));  
  }  
  recuperarArchivoEvidencia (rutaArchivo: string) : any{

    let filtro = new FiltroGeneral();
    filtro.RutaArchivo = rutaArchivo;

    let url: string = environment.APICargaArchivo + "/upload/recuperarArchivo";
    return this.httpClient.post(url, filtro, this.httpOptions)
                          .pipe(catchError(err => 
                                              { 
                                                console.log("ERROR NO CONTROLADO: " + err.message);
                                                notify({
                                                  message: "Se presento un error al conectarse con el servidor! ERROR: " + err.message,
                                                  position: {
                                                      my: "center bottom",
                                                      at: "center bottom"
                                                  }, width: 500, 
                                                }, "error", 5000);
                                                return of(null); 
                                              }
                                          ));  
  }   
  recuperarArchivosByTicket (idTicket: number): any {

    let url: string = environment.APITicketCore + "/reportes/imagenesTicketsById?pIdTicket=" + idTicket;
    return this.httpClient.get(url)
                          .pipe(catchError(err => 
                                              { 
                                                console.log("ERROR NO CONTROLADO: " + err.message);
                                                notify({
                                                  message: "Se presento un error al conectarse con el servidor! ERROR: " + err.message,
                                                  position: {
                                                      my: "center bottom",
                                                      at: "center bottom"
                                                  }, width: 500, 
                                                }, "error", 5000);
                                                return of(null); 
                                              }
                                          ));  
  } 
  recuperarEviTicketByParms (pIdCliente: number, pIdLocal: number, pIdZona: number, pIdSubZona: number,
                             pIdEspecialidad: number, pIdOrigen: number, pFechaInicio: string, pFechaFin: string) : any{

    let url: string = environment.APITicketCore + "/reportes/imagenesHistorialTickets";
    url = url + "?pIdCliente=" + pIdCliente 
              + "&pIdLocal=" + pIdLocal 
              + "&pIdZona=" + pIdZona 
              + "&pIdSubZona=" + pIdSubZona 
              + "&pIdEspecialidad=" + pIdEspecialidad
              + "&pIdOrigen=" + pIdOrigen 
              + "&pInicio=" + pFechaInicio
              + "&pFin=" + pFechaFin ; 

    return this.httpClient.get(url)
                          .pipe(catchError(err => 
                                              { 
                                                console.log("ERROR NO CONTROLADO: " + err.message);
                                                notify({
                                                  message: "Se presento un error al conectarse con el servidor! ERROR: " + err.message,
                                                  position: {
                                                      my: "center bottom",
                                                      at: "center bottom"
                                                  }, width: 500, 
                                                }, "error", 5000);
                                                return of(null); 
                                              }
                                          ));  
  } 

}