import { Component, ViewChild } from '@angular/core';
import { HttpClient, HttpParams } from "@angular/common/http";
import CustomStore from 'devextreme/data/custom_store';
import { Router, ActivatedRoute} from '@angular/router';
import config from 'devextreme/core/config';
import notify from 'devextreme/ui/notify';
import { DxDataGridComponent, DxFormComponent } from 'devextreme-angular';
import { AuthService } from 'src/app/shared/services';
import { environment } from '../../../../environments/environment';
import { SupervisionService } from 'src/app/shared/services/supervision.service';
import { ParametricasService } from 'src/app/shared/services/parametricas.service';
import { FiltroGeneral } from 'src/app/models/filtroGeneral';
import ArrayStore from 'devextreme/data/array_store';

config({
  floatingActionButtonConfig: {
      icon: 'rowfield',
      position: {
          of: '#gridEvaluaciones',
          my: 'right bottom',
          at: 'right bottom',
          offset: '-16 -16'
      }
  }
}); 

@Component({
  templateUrl: 'evaluacion-bandeja.component.html',
  styleUrls: ['evaluacion-bandeja.component.css']
})

export class EvaluacionBandejaComponent {
  @ViewChild(DxDataGridComponent, { static: false }) gridEvaluaciones: DxDataGridComponent;
  @ViewChild(DxFormComponent, { static: false }) formFiltro: DxFormComponent;

  filtroEvaluacion: FiltroGeneral;
  zonasGeograficas: any;
  rondas: any;
  dsEvaluaciones: any;
  selectedRowIndex = -1;
  sinEvaluacion = false;
  idLocalSupervisadoSelected = 0;

  popupVisible = false;
  tituloRegistro = "";
  dataLocalSelected: any;

  constructor(private httpClient: HttpClient, private authService: AuthService, 
                      private supService: SupervisionService, private router: Router, private paramService: ParametricasService,
                      private route: ActivatedRoute) {
    
    this.cambioSinSupervision = this.cambioSinSupervision.bind(this);
    let idUsuario = this.authService.usuarioLogueado.idUsuario;
    let pSoloObservados = this.route.snapshot.paramMap.get('SO');
    let pIdRonda = +this.route.snapshot.paramMap.get('Rda');
    let pIdZG = +this.route.snapshot.paramMap.get('ZnaGeo');

    let idCliente = this.authService.usuarioLogueado.idClienteSeleccionado;
    this.filtroEvaluacion = new FiltroGeneral();
    this.filtroEvaluacion.SinSupervision = false;

    if (pSoloObservados == null) {
      this.filtroEvaluacion.SoloObservados = true;
    } else {
      if (pSoloObservados == 'true') {
         this.filtroEvaluacion.SoloObservados = true;
      } else  {
        this.filtroEvaluacion.SoloObservados = false;
      }
    }
    
    this.supService.listarRondasVigentes(idCliente).subscribe(data => {
                          this.rondas = new ArrayStore({data: data,key: "idSupervision"}); 
                          if (data.length > 0) {
                             if (pIdRonda>0) {
                              this.filtroEvaluacion.IdSupervision = pIdRonda;
                             } else {
                              this.filtroEvaluacion.IdSupervision = data[0].idSupervision;
                             }
                          }
                    });

    this.paramService.listarZonasGeograficas(false).toPromise().then(data => {
          this.zonasGeograficas = new ArrayStore({
                                      data: data,
                                      key: "idZonaGeografica"
                                  }); 
          if (pIdZG > 0) {
             this.filtroEvaluacion.IdZG = pIdZG;
          }                                  
    });

    //COLOCO EL FOCO EN EL PRIMER CAMPO
    let that = this;
    setTimeout(function() {
      that.formFiltro.instance.getEditor("IdSupervision").focus();
    }, 500); 

    if (pIdZG > 0) {
      let loadParams = { idUsuario: idUsuario, idZG: pIdZG, idSupervision: pIdRonda, sinEvaluacion: this.filtroEvaluacion.SinSupervision};
      this.dsEvaluaciones = new CustomStore({
                      key: "idLocalSupervision",
                      load: () => this.sendRequest(environment.APISupervision + "/evaluacion/listarLocalesEvaluacionByParams", "LOAD", loadParams)
                    });
    }
  }

  cambioSinSupervision(evt) {
    if (evt.value) {
      this.sinEvaluacion = true;
      this.filtroEvaluacion.SoloObservados = false;
      this.formFiltro.instance.getEditor("SoloObservados").option("disabled", true); 
    } else  {
      this.sinEvaluacion = false;
      this.filtroEvaluacion.SoloObservados = true;
      this.formFiltro.instance.getEditor("SoloObservados").option("disabled", false); 
    }
  }

  repoblarEvaluacionesClick () {
    if (this.formFiltro.instance.validate().isValid) {
      let idUsuario = this.authService.usuarioLogueado.idUsuario;
      this.filtroEvaluacion = this.formFiltro.instance.option("formData");
      let loadParams = { idUsuario: idUsuario, idZG: this.filtroEvaluacion.IdZG, idSupervision: this.filtroEvaluacion.IdSupervision, sinEvaluacion: this.filtroEvaluacion.SinSupervision};
      this.dsEvaluaciones = new CustomStore({
                      key: "idLocalSupervision",
                      load: () => this.sendRequest(environment.APISupervision + "/evaluacion/listarLocalesEvaluacionByParams", "LOAD", loadParams)
                    });
    }
  }

  sendRequest(url: string, method: string, data: any = {}): any {

    let result;
    switch(method) {
        case "LOAD":
            url = url + "?pIdUsuario=" + data.idUsuario + "&pIdZonaGeografica=" 
                      + data.idZG + "&pIdSupervision=" + data.idSupervision 
                      + "&pSinEvaluacion=" + data.sinEvaluacion; 
            result = this.httpClient.get(url);
            break;
    }

    return result
        .toPromise()
        .then((data: any) => {
              if (data.codigo != 'OK') {
                  notify({
                    message: data.mensaje,
                    position: {
                        my: "center center",
                        at: "center center"
                    }, width: 500, 
                  }, "error", 2000);
              }
            return data.items;
        })
        .catch(e => {
              notify({
                message: e.error.Message,
                position: {
                    my: "center center",
                    at: "center center"
                }, width: 500, 
              }, "error", 2000);
        });
  }

  selectedChanged(e) {
    this.selectedRowIndex = e.component.getRowIndexByKey(e.selectedRowKeys[0]);
    this.idLocalSupervisadoSelected = e.selectedRowKeys[0];
    this.dataLocalSelected = e.selectedRowsData[0];
  }

  verDetalleSupervisionClick(){
    this.filtroEvaluacion = this.formFiltro.instance.option("formData");
    this.router.navigate(["adm-evaluacion-supervision", { SO: this.filtroEvaluacion.SoloObservados, 
                                                          Rda: this.filtroEvaluacion.IdSupervision, 
                                                          ZnaGeo: this.filtroEvaluacion.IdZG, 
                                                          LSup: this.idLocalSupervisadoSelected }]);
  }
}